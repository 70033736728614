<template>
  <div id="pathologistBlock">
    <ValidationObserver ref="pathologistForm">
      <modal-concurrency-issue
        ref="concurrencyIssueModal"
        :name-of-block="'Pathologist'"
        @onContinue="$onConcurrencyIssueContinue"
      ></modal-concurrency-issue>
      <collapse
        :wrapperClass="'case-collapse'"
        :collapse="collapseName"
        icon="keyboard_arrow_down"
        :expand-collapse-block-name="'pathologistBlock'"
      >
        <template slot="md-collapse-pane-1">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-30 md-small-size-100">
              <label
                class="lims-text-danger"
                v-if="isPathologistResetToNull && $isEntityChangedShowWarning(dataEdit)"
                >{{ $t('pages/case/CaseManagement/CaseForm/SwapEntity/ValueReset/Field/pathologist') }}</label
              >
              <lims-field :model="formData" :schema="caseFormSchema" field="pathologistId" class="textarea-field">
                <pathologist-select
                  slot="field"
                  :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                  v-model="pathologistUser"
                  :groupOptions="pathologistOptions"
                  :formMode="formMode"
                />
              </lims-field>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100">
              <lims-field :model="formData" :schema="caseFormSchema" field="pathologistNote" class="textarea-field">
              </lims-field>
              <p
                class="static-text"
                v-html="formData.pathologistNote ? formData.pathologistNote.replaceAll(';', '<br>') : ''"
              ></p>
            </div>
            <div
              v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)"
              class="md-layout lims-form-row"
            >
              <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
                <lims-form-cancel></lims-form-cancel>
                <md-button
                  @click="onSave()"
                  :disabled="isProcessing"
                  class="md-button md-primary lims-form-button md-theme-default"
                >
                  {{ $t('global/button/button.save') }}
                </md-button>
              </div>
            </div>
          </div>
        </template>
      </collapse>
    </ValidationObserver>
  </div>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import { caseFormService } from '@/services';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { APP_EVENTS, CASE_FORM_BLOCK, CASE_STATUS, FORM_MODES, PATHOLOGIST_TYPE_ID } from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import PathologistSelect from '@/pages/Case/CaseManagement/Components/PathologistSelect.vue';
import CaseBlockMixins, { FIELDS_WAS_RESET_TO_NULL } from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { debounce } from 'lodash';
import { mappingGroupPathologists, createNewPathologist, isAvailablePathologist } from '../Case.helpers';
import { computeSpecimenTypeId } from '@/store/modules/case-data';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  components: {
    ModalConcurrencyIssue,
    Collapse,
    PathologistSelect,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        pathologistId: '',
        pathologistNote: '',
        pathologistType: '',
      },
      pathologistOptions: [],
      defaultPathologist: null,
      pathologistUser: null,
      rowVersion: null,
      blockId: CASE_FORM_BLOCK.BLOCK_PATHOLOGIST,
      isFirstLoad: true,
      isChangePathologist: false,
      pathologistIdSaved: '',
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('caseData', ['clinicComposeData', 'caseData']),
    ...mapGetters('caseForm', ['isPathologistResetToNull']),
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM,
      APP_EVENTS.EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK,
      APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE,
    ]),
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, null, null, null, {
        isPreLabStatus: this.isPreLabStatus,
        isReviewCaseScreen: this.isReviewCaseScreen,
      });
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/Pathologist/blockTitle';
      return [this.$translate(name)];
    },
    isPreLabStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
  },
  watch: {
    clinicComposeData: {
      deep: true,
      handler: debounce(async function (val) {
        if (
          val.clinicId &&
          val.clinicianId &&
          typeof val.specimenTypeId != 'undefined' &&
          typeof val.laboratoryId != 'undefined' &&
          val.specimenTypeId != ''
        ) {
          const { clinicId, clinicianId, specimenTypeId, laboratoryId } = val;
          const { pathologistOptions, defaultPathologist } = await this.loadPathologistUsers({
            clinicId,
            clinicianId,
            specimenTypeId,
            laboratoryId,
            caseId: this.isAdminView ? this.dataEdit.caseId : null,
          });

          this.pathologistOptions = pathologistOptions;
          // ALWAYS SET NULL OR USE DEFAULT IF THOSE VALUES CHANGED
          if (this.pathologistUser) {
            let pathologistUsersList = [];
            this.pathologistOptions.forEach((element) => {
              pathologistUsersList = pathologistUsersList.concat(element.pathologistUsers);
            });
            const isExisted = pathologistUsersList.find((p) => p.pathologistId == this.pathologistUser.pathologistId);
            if (!isExisted) {
              if (
                this.pathologistUser.pathologistType != PATHOLOGIST_TYPE_ID.Pull ||
                (this.pathologistUser.pathologistType === PATHOLOGIST_TYPE_ID.Pull &&
                  this.pathologistUser.pathologistId !== null)
              ) {
                this.pathologistUser = defaultPathologist;
              }
            }
          } else {
            // use default if is add mode or (edit mode and not first load)

            if (!this.dataEdit || (this.dataEdit && !this.isFirstLoad)) {
              this.pathologistUser = defaultPathologist;
            }
          }

          if (!this.pathologistUser) {
            this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST);
          }
        } else {
          this.pathologistOptions = [];
          this.pathologistUser = null;
          this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST);
        }
        // [Swap Entity checked]
      }, 500),
    },
    pathologistUser: {
      deep: true,
      handler: function (val) {
        if (val) {
          if (
            this.formData.pathologistId != val.pathologistId ||
            this.formData.pathologistType != val.pathologistType
          ) {
            this.formData.pathologistId = val.pathologistId || null;
            this.formData.pathologistType = val.pathologistType || PATHOLOGIST_TYPE_ID.None;
            this.formData.pathologistName = val.pathologistName || '';
            this.getPathologistNote();
          }
        }
        //CheckChangePathologist;
        const pathologistIdInform = this.pathologistUser?.pathologistId || null;
        if (this.pathologistIdSaved != pathologistIdInform) {
          this.isChangePathologist = true;
        } else {
          this.isChangePathologist = false;
          this.$resetBlockChanged();
        }
        this.setIsChangePathologist(this.isChangePathologist);
        this.appendCaseData({
          caseData: this.pathologistData(),
          blockId: CASE_FORM_BLOCK.BLOCK_PATHOLOGIST,
        });          
        this.$emit('input', {
          ...val,
        });
      },
    },
    formData: {
      deep: true,
      handler: function (val) {
        const dataForm = this.pathologistData();
        this.appendCaseData({
          caseData: dataForm,
          blockId: CASE_FORM_BLOCK.BLOCK_PATHOLOGIST,
        });         
        this.$emit('input', {
          ...val,
        });
      },
    },

    [APP_EVENTS.EVT_ON_SAVE_ALL_CASE_FORM]: {
      deep: true,
      handler: function (data) {
        if (data) {
          this.pathologistIdSaved = data.pathologistId;
          this.isChangePathologist = false;
          this.setIsChangePathologist(this.isChangePathologist);
        }
      },
    },
    [APP_EVENTS.EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK]: {
      deep: true,
      handler: function (data) {
        if (data) {
          this.pathologistIdSaved = data.pathologistId;
          if (this.pathologistIdSaved != this.pathologistUser?.pathologistId) {
            this.isChangePathologist = true;
          } else {
            this.isChangePathologist = false;
          }
          this.setIsChangePathologist(this.isChangePathologist);
        }
      },
    },

    [APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.fetchData(this.caseData);
        }
      },
    },
  },
  created() {
    this.fetchData(this.dataEdit);
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    ...mapActions('caseForm', ['markFieldToBeSetNull', 'setIsChangePathologist']),
    async fetchData(dataEdit) {
      if (dataEdit) {
        const { clinicId, clinicianId, laboratoryId } = dataEdit;
        const specimenTypeId = computeSpecimenTypeId(dataEdit);

        const { pathologistOptions, defaultPathologist } = await this.loadPathologistUsers({
          clinicId,
          clinicianId,
          specimenTypeId,
          laboratoryId,
          caseId: this.isAdminView ? this.dataEdit.caseId : null,
        });

        this.pathologistOptions = pathologistOptions;
        // find currentPathologist: null, pull, others
        let currentPathologist = createNewPathologist({
          ...this.dataEdit,
        });

        const isPathologistNull = !currentPathologist;
        const isPullPathologist = currentPathologist && currentPathologist.pathologistType === PATHOLOGIST_TYPE_ID.Pull;
        const isNotValidPathologist =
          currentPathologist &&
          !isPullPathologist &&
          !isAvailablePathologist(currentPathologist.pathologistId, pathologistOptions);
        if (isPathologistNull && isNotValidPathologist) {
          currentPathologist = defaultPathologist;
        }

        // IMPORTANT: DON'T CHANGE THE BINDING FLOW,
        // BECAUSE THE PATHOLOGIST USER WILL BE USED TO DETECT CHANGE
        this.formData = {
          ...dataEdit,
          ...currentPathologist,
        };
        // use current dataEdit's pathologistUser if existed in list options
        this.pathologistUser = currentPathologist;
        this.pathologistIdSaved = dataEdit.pathologistId;
        this.isFirstLoad = false;
      }
      this.$resetBlockChanged();
    },
    async loadPathologistUsers({ clinicId, clinicianId, specimenTypeId, laboratoryId, caseId }) {
      const { error, data } = await caseFormService.getPathologistUsers({
        clinicId,
        clinicianId,
        specimenTypeId,
        laboratoryId,
        caseId,
      });
      if (error) {
        this.$alertError(error);
      }
      const isEditMode = this.formMode === FORM_MODES.EDIT;
      return mappingGroupPathologists(data, isEditMode);
    },
    async loadPathologistNote({ clinicId, clinicianId, pathologistUserId }) {
      const { data } = await caseFormService.getPathologistNote({
        clinicId,
        clinicianId,
        pathologistUserId,
      });
      if (data.payload === '') {
        return '';
      }
      return data;
    },
    async getPathologistNote() {
      if (this.formMode === this.EDIT_MODE || this.formMode === this.VIEW_MODE) {
        const pathologistNoteData = await this.loadPathologistNote({
          clinicId: this.formData.clinicId,
          clinicianId: this.formData.clinicianId,
          pathologistUserId: this.pathologistUser.pathologistId,
        });
        this.formData.pathologistNote = pathologistNoteData;
      } else {
        const pathologistNoteData = await this.loadPathologistNote({
          clinicId: this.caseData.clinicId,
          clinicianId: this.caseData.clinicianId,
          pathologistUserId: this.formData.pathologistId,
        });
        this.formData.pathologistNote = pathologistNoteData;
      }
    },
    pathologistData() {
      const pathologistUser = this.pathologistUser
        ? this.pathologistUser
        : { pathologistId: null, pathologistType: null };
      const data = {
        ...pathologistUser,
        pathologistNote: this.formData.pathologistNote,
      };
      return data;
    },

    async onSave(overWrite = false) {
      this.$refs.pathologistForm.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
            const dataForm = this.pathologistData();
            const res = await caseFormService.updatePathologist(this.dataEdit.caseId, {
              ...dataForm,
              rowVersion: overWrite ? null : rowVersion,
            });
            this.isProcessing = false;
            if (res.error) {
              const errList = res.error.split(',');
              const findDraftedAndNoVirtualSlidesErr = errList.filter(
                (item) => item === 'CaseDrafted' || item === 'CaseNoVirtualSlides',
              );
              if (findDraftedAndNoVirtualSlidesErr) {
                if (findDraftedAndNoVirtualSlidesErr.length == 2) {
                  return this.$alertError(
                    this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseDraftedAndCaseNoVirtualSlides'),
                  );
                }
                if (
                  findDraftedAndNoVirtualSlidesErr.length == 1 &&
                  findDraftedAndNoVirtualSlidesErr[0] === 'CaseDrafted'
                ) {
                  return this.$alertError(this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseDrafted'));
                }
                if (
                  findDraftedAndNoVirtualSlidesErr.length == 1 &&
                  findDraftedAndNoVirtualSlidesErr[0] === 'CaseNoVirtualSlides'
                ) {
                  return this.$alertError(
                    this.$t('pages/case/CaseManagement/Pathologist/pullCase.CaseNoVirtualSlides'),
                  );
                }
              }
              return this.$alertError(res.err + ' error');
            }
            this.$onAfterSaveHandler({
              res,
              dataEdit: {
                ...this.dataEdit,
                ...dataForm,
              },
            });

            this.pathologistIdSaved = this.pathologistUser.pathologistId;
            this.isChangePathologist = false;
            this.setIsChangePathologist(this.isChangePathologist);
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
