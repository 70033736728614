<template>
  <lims-block :cardText="false" class="list-page case-list">
    <modal-loading-generic ref="modalLoadingGeneric" slot="blockTitle"></modal-loading-generic>
    <modal-delete-case ref="deleteCase" slot="blockTitle" @onDelete="onDeleteCase"></modal-delete-case>
    <modal-restore-case ref="restoreCaseModal" slot="blockTitle" @onRestore="onRestoreCase"></modal-restore-case>
    <modal-resend-case
      ref="resendCase"
      slot="blockTitle"
      :source="source"
      :case-reference="resendCaseReference"
      @onResend="onResendCase"
    ></modal-resend-case>
    <modal-warning-no-unread-reported-cases
      slot="blockTitle"
      ref="modalWarningNoUnreadReportedCases"
    ></modal-warning-no-unread-reported-cases>
    <div slot="blockContent">
      <div class="list-menu">
        <ul v-if="!isAdminView && !isLabTechnicianView && !isPathologistView && !isCaseOfInterest">
          <li @click="scrollMeTo('ReportedCases', true)">{{ $t('pages.case.link.ReportedCases') }}</li>
          <li @click="scrollMeTo('UnReportedCases', true)">{{ $t('pages.case.link.UnReportedCases') }}</li>
          <li @click="scrollMeTo('PendingCases', true)">{{ $t('pages.case.link.PendingCases') }}</li>
        </ul>
        <ul v-if="isPathologistView && !isCaseOfInterest">
          <li @click="scrollMeTo('PendingCases', true)">{{ $t('pages.case.link.PendingCases') }}</li>
          <li @click="scrollMeTo('PullCases', true)" v-if="pathPullSystem">{{ $t('pages.case.link.PullCases') }}</li>
          <li @click="scrollMeTo('ReadyForReport', true)">{{ $t('pages.case.link.ReadyForReport') }}</li>
          <li @click="scrollMeTo('PendingFurtherWork', true)">{{ $t('pages.case.link.PendingFurtherWork') }}</li>
          <li @click="scrollMeTo('DoubleReporting', true)">{{ $t('pages.case.link.DoubleReporting') }}</li>
        </ul>
      </div>
      <div class="md-layout lims-form-row btnRight">
        <md-button v-if="$isAuthorized(['Case_SimpleBookingForm_Create'])" @click="redirectToSimpleBooking">{{
          $t('pages/case/CaseManagement/button.SimpleBooking')
        }}</md-button>
        <md-button v-if="$isAuthorized(['Case_CaseForm_Create'])" @click="onAddCase">{{
          $t('pages/case/CaseManagement/button.addCase')
        }}</md-button>
      </div>
      <div class="toolbar-row">
        <div class="field-search">
          <normal-search
            v-model="query.filters"
            :defaultValues="defaultValues"
            :fields="fields"
            @onResetFilters="onResetFilters"
          >
            <lims-row-per-page
              slot="rowsPerPage"
              :pageSize="query.pagination.pageSize"
              @pageSizeChanged="$pageSizeChanged"
            />
          </normal-search>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <legend-seach :isCaseOfInterest="isCaseOfInterest"></legend-seach>
      </div>
      <div
        v-if="isClinicAssociateView && isAllowedExportUnreadCases"
        class="md-layout lims-form-row exportUnreadCasesBtn"
      >
        <md-button
          :disabled="numberOfUnreadReportedCases === 0"
          @click="onExportUnreadCases"
          v-html="
            $t('pages/Case/CaseManagement/List/button.exportUnreadCasesLabel', {
              numberOfUnreadReportedCases: numberOfUnreadReportedCases,
            })
          "
        >
        </md-button>
      </div>
      <case-list-admin
        v-if="isAdminView"
        @action="onRowAction"
        :caseList="items"
        :isCaseOfInterest="isCaseOfInterest"
        :totalItem="totalItem"
      >
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
          :multiple="true"
          :index="0"
        ></lims-pagination>
      </case-list-admin>
      <case-list-lab-technician
        v-if="isLabTechnicianView"
        @action="onRowAction"
        :caseList="items"
        :isCaseOfInterest="isCaseOfInterest"
        :totalItem="totalItem"
      >
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
          :multiple="true"
          :index="0"
        ></lims-pagination>
      </case-list-lab-technician>
      <case-list-pathologist
        v-if="isCaseOfInterest && isPathologistView"
        @action="onRowAction"
        :caseList="items"
        :total-item="totalItem"
      >
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
          :multiple="true"
          :index="0"
        ></lims-pagination>
      </case-list-pathologist>
      <div v-if="isPathologistView && !isCaseOfInterest">
        <div ref="PendingCases">
          <pending-case-path-list
            @action="onRowAction"
            :caseList="pendingCasePathList"
            :total-item="totalItemPendingCasePath"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemPendingCasePath"
              :pagination="query.pagination"
              :multiple="true"
              :index="0"
            ></lims-pagination>
          </pending-case-path-list>
        </div>
        <div ref="PullCases" v-if="pathPullSystem">
          <pull-cases-list @action="onRowAction" :caseList="pullCaseList" :total-item="totalItemPullCase">
            <lims-pagination
              slot="pagination"
              :total-item="totalItemPullCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="1"
            ></lims-pagination>
          </pull-cases-list>
        </div>
        <div ref="ReadyForReport">
          <ready-for-report-list
            @action="onRowAction"
            :totalItem="totalItemReadyForReportCase"
            :caseList="ReadyForReportList"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemReadyForReportCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="2"
            ></lims-pagination>
          </ready-for-report-list>
        </div>
        <div ref="PendingFurtherWork">
          <pending-further-work-list
            @action="onRowAction"
            :caseList="PendingFurtherWorkList"
            :totalItem="totalItemPendingFurtherWorkCase"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemPendingFurtherWorkCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="3"
            ></lims-pagination>
          </pending-further-work-list>
        </div>
        <div ref="DoubleReporting">
          <double-reporting
            v-if="DoubleReportCaseList.length > 0"
            @action="onRowAction"
            :caseList="DoubleReportCaseList"
            :total-item="totalItemDoubleReportCase"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemDoubleReportCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="4"
            ></lims-pagination>
          </double-reporting>
        </div>
        <div v-if="query.filters.IsIncludedReportedCase" ref="ReportCaseSearchResult">
          <div ref="ReportedCases">
            <report-case-search-result
              @action="onRowAction"
              :totalItem="totalItemReportCaseSearchPath"
              :caseList="ReportCaseSearchResultList"
            >
              <lims-pagination
                slot="pagination"
                :total-item="totalItemReportCaseSearchPath"
                :pagination="query.pagination"
                :multiple="true"
                :index="5"
              ></lims-pagination>
            </report-case-search-result>
          </div>
        </div>
      </div>
      <case-list-clinic-users
        v-if="!isAdminView && !isLabTechnicianView && !isPathologistView && isCaseOfInterest"
        :caseList="items"
        :total-item="totalItem"
      >
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
          :multiple="true"
          :index="0"
        ></lims-pagination>
      </case-list-clinic-users>
      <div v-if="!isAdminView && !isLabTechnicianView && !isPathologistView && !isCaseOfInterest">
        <div ref="ReportedCases">
          <reported-cases-list @action="onRowAction" :caseList="reportedCaseList" :totalItem="totalItemReportedCase">
            <lims-pagination
              slot="pagination"
              :total-item="totalItemReportedCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="0"
            ></lims-pagination>
          </reported-cases-list>
        </div>
        <div ref="UnReportedCases">
          <unreported-cases-list
            @action="onRowAction"
            :caseList="unReportedCaseList"
            :totalItem="totalItemUnReportedCase"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemUnReportedCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="1"
            ></lims-pagination>
          </unreported-cases-list>
        </div>
        <div ref="PendingCases">
          <pending-case-lab-list @action="onRowAction" :caseList="pendingCaseList" :total-item="totalItemPendingCase">
            <lims-pagination
              slot="pagination"
              :total-item="totalItemPendingCase"
              :pagination="query.pagination"
              :multiple="true"
              :index="2"
            ></lims-pagination>
          </pending-case-lab-list>
        </div>
        <div
          v-if="query.filters.IsIncludedReportedCase && ReportCaseSearchResultList.length > 0"
          ref="ReportCaseSearchResult"
        >
          <report-case-search-result
            @action="onRowAction"
            :totalItem="totalItemReportCaseSearchPath"
            :caseList="ReportCaseSearchResultList"
          >
            <lims-pagination
              slot="pagination"
              :total-item="totalItemReportCaseSearchPath"
              :pagination="query.pagination"
              :multiple="true"
              :index="3"
            ></lims-pagination>
          </report-case-search-result>
        </div>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { APP_EVENTS, APP_ROUTES, FORM_ACTIONS, newAppEvent, NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import { mapGetters, mapActions } from 'vuex';
import CaseListAdmin from '@/pages/Case/CaseManagement/List/CaseListAdmin.vue';
import CaseListLabTechnician from '@/pages/Case/CaseManagement/List/CaseListLabTechnician.vue';
import CaseListPathologist from '@/pages/Case/CaseManagement/List/CaseListPathologist.vue';
import CaseListClinicUsers from '@/pages/Case/CaseManagement/List/CaseListClinicUsers.vue';
import PendingCaseLabList from '@/pages/Case/CaseManagement/List/PendingCaseLabList.vue';
import UnreportedCasesList from '@/pages/Case/CaseManagement/List/UnreportedCasesList.vue';
import PendingCasePathList from '@/pages/Case/CaseManagement/List/PendingCasePathList.vue';
import PullCasesList from '@/pages/Case/CaseManagement/List/PullCasesList.vue';
import ReadyForReportList from '@/pages/Case/CaseManagement/List/ReadyForReportList.vue';
import PendingFurtherWorkList from '@/pages/Case/CaseManagement/List/PendingFurtherWorkList.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import NormalSearch from '@/pages/Case/CaseManagement/List/NormalSearch';
import LegendSeach from './List/LegendSeach.vue';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { caseQuery } from '@/query';
import { caseFormService, caseListService } from '@/services';
import ModalDeleteCase from '@/components/Lims/modals/ModalDeleteCase';
import ModalResendCase from '@/components/Lims/modals/ModalResendCase';
import ModalLoadingGeneric from '@/components/Lims/modals/ModalLoadingGeneric.vue';
import ReportedCasesList from '@/pages/Case/CaseManagement/List/ReportedCasesList';
import DoubleReporting from '@/pages/Case/CaseManagement/List/DoubleReporting.vue';
import ReportCaseSearchResult from '@/pages/Case/CaseManagement/List/ReportCaseSearchResult.vue';
import ModalWarningNoUnreadReportedCases from '@/components/Lims/modals/ModalWarningNoUnreadReportedCases.vue';
import ModalRestoreCase from '@/components/Lims/modals/ModalRestoreCase.vue';
import ClinicAssociateCaseListMixins from './ClinicAssociateCaseList.mixins';
import MyAccountService from '@/services/myAccount.service';
import { LIST_NAMES } from '@/store/modules/item-list';

export default {
  props: {
    isCaseOfInterest: {
      type: Boolean,
      require: false,
    },
  },
  components: {
    ReportedCasesList,
    CaseListAdmin,
    CaseListLabTechnician,
    CaseListPathologist,
    CaseListClinicUsers,
    PendingCaseLabList,
    UnreportedCasesList,
    PendingCasePathList,
    PullCasesList,
    ReadyForReportList,
    PendingFurtherWorkList,
    DoubleReporting,
    ReportCaseSearchResult,
    LimsRowPerPage,
    NormalSearch,
    LegendSeach,
    LimsPagination,
    ModalDeleteCase,
    ModalWarningNoUnreadReportedCases,
    ModalResendCase,
    ModalRestoreCase,
    ModalLoadingGeneric,
  },
  mixins: [ConfigMixins, ListWithFilterMixins, ClinicAssociateCaseListMixins],
  async created() {
    this.initState();
    this.userType = this.$store.getters['auth/userType'];
    if (this.userType === this.USER_TYPES().ClinicAssociate) {
      const exportTemplateOptions = await this.getExportTemplateOption();

      if (exportTemplateOptions.enableTalkingPoint || exportTemplateOptions.enableText) {
        this.exportTemplateOptions = exportTemplateOptions;
        await this.getNumberOfUnreadReportedCases();
      }
    }
    if (this.userType === this.USER_TYPES().Pathologist) {
      const { data } = await MyAccountService.getMyProfile(this.userId);
      if (data) {
        this.pathPullSystem = data.personalInfo?.pullSystem;
      }
    }
  },
  mounted() {
    if (this.redirectName) {
      setTimeout(() => {
        this.scrollMeTo(this.redirectName);
      }, 1);
    }
  },
  data() {
    return {
      items: [],
      totalItem: null,
      reportedCaseList: [],
      totalItemReportedCase: null,
      unReportedCaseList: [],
      totalItemUnReportedCase: null,
      pendingCaseList: [],
      totalItemPendingCase: null,
      pendingCasePathList: [],
      totalItemPendingCasePath: null,
      pullCaseList: [],
      totalItemPullCase: null,
      ReadyForReportList: [],
      totalItemReadyForReportCase: null,
      PendingFurtherWorkList: [],
      totalItemPendingFurtherWorkCase: null,
      DoubleReportCaseList: [],
      totalItemDoubleReportCase: null,
      ReportCaseSearchResultList: [],
      totalItemReportCaseSearchPath: null,
      query: null,
      caseId: '',
      caseReference: '',
      source: '',
      resendCaseIds: [],
      resendCaseReference: '',
      isWrongPassword: false,
      previousFilters: [],
      previousPagination: [],
      exportTemplateOptions: { enableTalkingPoint: false, enableText: false },
      caseStatusBeforeDelete: '',
      isFirstTimeRedirect: true,
      pathPullSystem: false,
      isAllowedExportUnreadCases: false,
    };
  },
  computed: {
    ...mapGetters('app', ['redirectName', 'isModalGenericLoadingOpen']),
    ...mapGetters('auth', ['userId']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_RELOAD_CASE_LIST]),
    isIncludedReportedCase() {
      return this.query.filters.IsIncludedReportedCase || false;
    },
    fields() {
      return caseQuery.filters.fields;
    },
    defaultValues() {
      return caseQuery.filters.defaultValues;
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    isClinicAssociateView() {
      return this.userType === this.USER_TYPES().ClinicAssociate;
    },
  },
  watch: {
    isModalGenericLoadingOpen(isOpen) {
      if (isOpen) {
        return this.$refs.modalLoadingGeneric.open();
      }
      this.$refs.modalLoadingGeneric.close();
    },
    [APP_EVENTS.EVT_ON_RELOAD_CASE_LIST]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadCaseList();
        }
      },
    },
  },
  methods: {
    ...mapActions('app', ['$setRedirectName']),
    ...mapActions('itemList', ['setSearchQuery', 'resetAllState']),
    initState() {
      // views that has only one list
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(caseQuery.filters, queryParams);
      const sort = buildFilterFromQuery(caseQuery.sort, queryParams);
      const pagination = buildFilterFromQuery(caseQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async adminViewSearch(queryObject) {
      const queryParams = {
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams({
          pageSize: queryObject.pagination.pageSize,
          pageNumber: queryObject.pagination.pageNumber[0],
        }),
      };
      let res = { error: null, data: null };

      // set search query
      this.setSearchQuery({
        listName: LIST_NAMES.CASE_LIST_ADMIN,
        searchQuery: queryParams,
      });
      if (this.isCaseOfInterest) {
        res = await caseListService.getCaseInterestListAdmin(queryParams);
      } else {
        res = await caseListService.getCaseAdminList(queryParams);
      }
      const { error, data } = res;

      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }

      this.items = data.items;
      this.totalItem = data.pagination.totalItems;
    },
    async labTechnicianViewSearch(queryObject) {
      const queryParams = {
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams({
          pageSize: queryObject.pagination.pageSize,
          pageNumber: queryObject.pagination.pageNumber[0],
        }),
      };
      let res = { error: null, data: null };
      // set search query
      this.setSearchQuery({
        listName: LIST_NAMES.CASE_LIST_LABTECHNICIAN,
        searchQuery: queryParams,
      });
      if (this.isCaseOfInterest) {
        res = await caseListService.getCaseInterestListLabtechnician(queryParams);
      } else {
        res = await caseListService.getCaseListLabTechnician(queryParams);
      }
      const { error, data } = res;

      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }

      this.items = data.items;
      this.totalItem = data.pagination.totalItems;
    },
    async pathologistViewSearch(queryObject) {
      if (this.isCaseOfInterest) {
        const queryParams = {
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber[0],
          }),
        };

        const { error, data } = await caseListService.getCaseInterestListPathologist(queryParams);

        if (error) {
          this.$alertErrorServerSide(error);
          return;
        }

        this.items = data.items;
        this.totalItem = data.pagination.totalItems;
      } else {
        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[0] != this.previousPagination.pageNumber[0]
        ) {
          const PendingCasePath = await caseListService.getPendingCasePathList({
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[0],
            }),
          });
          this.pendingCasePathList = PendingCasePath.data.items;
          this.totalItemPendingCasePath = PendingCasePath.data.pagination.totalItems;
        }
        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[1] != this.previousPagination.pageNumber[1]
        ) {
          const PullCase = await caseListService.getPullCaseList({
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[1],
            }),
          });
          this.pullCaseList = PullCase.data.items;
          this.totalItemPullCase = PullCase.data.pagination.totalItems;
        }

        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[2] != this.previousPagination.pageNumber[2]
        ) {
          const readyForReportQuery = {
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[2],
            }),
          };
          // set search query
          this.setSearchQuery({
            listName: LIST_NAMES.CASE_LIST_READY_FOR_REPORT_LIST,
            searchQuery: readyForReportQuery,
          });
          const ReadyForReportCase = await caseListService.getReadyForReportList(readyForReportQuery);
          this.ReadyForReportList = ReadyForReportCase.data.items;
          this.totalItemReadyForReportCase = ReadyForReportCase.data.pagination.totalItems;
        }
        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[3] != this.previousPagination.pageNumber[3]
        ) {
          const pendingFurtherWorkQuery = {
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[3],
            }),
          };
          this.setSearchQuery({
            listName: LIST_NAMES.CASE_LIST_PENDING_FURTHER_WORK_LIST,
            searchQuery: pendingFurtherWorkQuery,
          });
          const PendingFurtherWork = await caseListService.getPendingFurtherWorkList(pendingFurtherWorkQuery);
          this.PendingFurtherWorkList = PendingFurtherWork.data.items;
          this.totalItemPendingFurtherWorkCase = PendingFurtherWork.data.pagination.totalItems;
        }
        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[4] != this.previousPagination.pageNumber[4]
        ) {
          const DoubleReportCase = await caseListService.getDoubleReportCasePathList({
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[4],
            }),
          });
          this.DoubleReportCaseList = DoubleReportCase.data.items;
          this.totalItemDoubleReportCase = DoubleReportCase.data.pagination.totalItems;
        }

        if (
          JSON.stringify(queryObject.filters) != JSON.stringify(this.previousFilters) ||
          queryObject.pagination.pageSize != this.previousPagination.pageSize ||
          queryObject.pagination.pageNumber[5] != this.previousPagination.pageNumber[5]
        ) {
          const searchAllReportCaseQuery = {
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[5],
            }),
          };
          this.setSearchQuery({
            listName: LIST_NAMES.CASE_LIST_REPORT_CASE_FULL,
            searchQuery: searchAllReportCaseQuery,
          });
          const dataSearchResult = await caseListService.getReportCaseSearchResultList(searchAllReportCaseQuery);
          this.ReportCaseSearchResultList = dataSearchResult.data.items;
          this.totalItemReportCaseSearchPath = dataSearchResult.data.pagination.totalItems;
        }
      }
    },
    async clinicUsersViewSearch(queryObject) {
      if (this.isCaseOfInterest) {
        const queryParams = {
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber[0],
          }),
        };

        const { error, data } = await caseListService.getCaseInterestListClinicUsers(queryParams);

        if (error) {
          this.$alertErrorServerSide(error);
          return;
        }

        this.items = data.items;
        this.totalItem = data.pagination.totalItems;
      } else {
        const queries = [
          {
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[0],
            }),
          },
          {
            filters: buildFilterQueryParams(queryObject.filters),
            pagination: buildFilterQueryParams({
              pageSize: queryObject.pagination.pageSize,
              pageNumber: queryObject.pagination.pageNumber[1],
            }),
          },
        ];
        // set search query
        this.setSearchQuery({
          listName: LIST_NAMES.CASE_LIST_REPORTED_CASE_LIST,
          searchQuery: queries[0],
        });
        this.setSearchQuery({
          listName: LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST,
          searchQuery: queries[1],
        });

        const reportedCase = await caseListService.getReportedCaseList(queries[0]);
        this.reportedCaseList = reportedCase.data.items;
        this.totalItemReportedCase = reportedCase.data.pagination.totalItems;

        const unReportedCase = await caseListService.getUnReportedCaseList(queries[1]);
        this.unReportedCaseList = unReportedCase.data.items;
        this.totalItemUnReportedCase = unReportedCase.data.pagination.totalItems;

        const pendingCase = await caseListService.getPendingCaseLabList({
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber[2],
          }),
        });
        this.pendingCaseList = pendingCase.data.items;
        this.totalItemPendingCase = pendingCase.data.pagination.totalItems;
        const searchAllReportCaseQuery = {
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber[3],
          }),
        };
        this.setSearchQuery({
          listName: LIST_NAMES.CASE_LIST_REPORT_CASE_FULL,
          searchQuery: searchAllReportCaseQuery,
        });
        const dataSearchResult = await caseListService.getReportCaseSearchResultList(searchAllReportCaseQuery);
        this.ReportCaseSearchResultList = dataSearchResult.data.items;
        this.totalItemReportCaseSearchPath = dataSearchResult.data.pagination.totalItems;
        if (this.userType === this.USER_TYPES().ClinicAssociate) {
          this.getNumberOfUnreadReportedCases();
        }
      }
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (
        queryObjNew.filters.search.length > 0 &&
        queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH &&
        !queryObjNew.filters.onSearch
      ) {
        return;
      }

      if (this.isAdminView) {
        await this.adminViewSearch(queryObject);
      } else if (!this.isAdminView && !this.isLabTechnicianView && !this.isPathologistView) {
        await this.clinicUsersViewSearch(queryObject);
      } else if (this.isLabTechnicianView) {
        await this.labTechnicianViewSearch(queryObject);
      } else if (this.isPathologistView) {
        await this.pathologistViewSearch(queryObject);
      }

      if (this.isFirstTimeRedirect && this.redirectName) {
        this.scrollMeTo(this.redirectName);
        this.isFirstTimeRedirect = false;
      }

      this.previousFilters = cloneDeep(queryObject.filters);
      this.previousPagination = cloneDeep(queryObject.pagination);
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },

    onResetFilters() {
      this.query.filters = cloneDeep(caseQuery.filters.defaultValues);
    },

    ...mapActions('caseForm', ['setExpandCollapseBlockName']),
    scrollMeTo(refName, clickOnList) {
      if (clickOnList) {
        this.setExpandCollapseBlockName(refName + 'Collapse');
      }
      setTimeout(() => {
        let element = this.$refs[refName];
        let position = element.offsetTop;
        window.scrollTo({ top: position + 50, behavior: 'smooth' });
      }, 200);
    },

    redirectToSimpleBooking() {
      this.$router.push(APP_ROUTES.SIMPLE_BOOKING);
    },

    onAddCase() {
      this.$router.push(APP_ROUTES.CASE_ADD);
    },

    onRowAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow(item);
      }
      if (action === FORM_ACTIONS.RESTORE) {
        this.restoreRow(item);
      }
      if (action === FORM_ACTIONS.EXPORT) {
        this.exportPdfFile(item);
      }
      if (action === FORM_ACTIONS.RESEND) {
        if (item) {
          this.resendCase(item);
        }
      }
      if (action === FORM_ACTIONS.VIEW_REPORT) {
        this.$router.push(`${APP_ROUTES.REPORT_CASE}/${item.caseId}`);
      }
    },

    editRow(item) {
      if (item && item.doubleReportResponseId) {
        this.$router.push({
          path: `${APP_ROUTES.CASE_EDIT}/${item.caseId}`,
          query: { doubleReportResponseId: item.doubleReportResponseId },
        });
      } else {
        this.$router.push(`${APP_ROUTES.CASE_EDIT}/${item.caseId}`);
      }
    },

    viewRow(item) {
      if (item && item.doubleReportResponseId) {
        this.$router.push({
          path: `${APP_ROUTES.CASE_VIEW}/${item.caseId}`,
          query: { doubleReportResponseId: item.doubleReportResponseId },
        });
      } else {
        this.$router.push(`${APP_ROUTES.CASE_VIEW}/${item.caseId}`);
      }
    },

    deleteRow(item) {
      this.caseId = item.caseId;
      this.caseReference = item.caseReference;
      this.$refs.deleteCase.open();
    },

    restoreRow(item) {
      this.caseId = item.caseId;
      this.caseReference = item.caseReference;
      this.caseStatusBeforeDelete = item.statusName;
      this.$refs.restoreCaseModal.open();
    },

    resendCase(item) {
      this.source = item.source;
      this.resendCaseIds.push(item.caseId);
      this.resendCaseReference = item.caseReference;
      this.$refs.resendCase.open();
    },

    async onDeleteCase(deleteCaseFormData) {
      const { err, data } = await caseFormService.deleteCase(
        deleteCaseFormData.reason,
        deleteCaseFormData.password,
        this.caseId,
      );
      this.$refs.deleteCase.isProcessingStatus(false);
      if (err) {
        return this.$refs.deleteCase.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.deleteCase.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/delete', {
            caseReference: this.caseReference,
          }),
        );
        this.reloadCaseList();
      }
    },

    async onResendCase(resendCaseFormData) {
      const { err, data } = await caseFormService.resendCase({
        password: resendCaseFormData.password,
        caseIds: cloneDeep(this.resendCaseIds),
        isForcedUpdate: true,
      });
      // reset empty after send
      this.resendCaseIds = [];
      this.$refs.resendCase.isProcessingStatus(false);
      if (err) {
        return this.$refs.resendCase.handleErrorMessageModal(err);
      }
      if (data) {
        this.$alertSuccess(
          this.$t('pages/Case/CaseManagement/List/message.resend', {
            caseReference: this.resendCaseReference,
            sourceName: this.source,
          }),
        );
        this.$refs.resendCase.close();
      }
    },

    async onRestoreCase(val) {
      if (val) {
        const { err, data } = await caseFormService.restoreCase(this.caseId, val);
        this.$refs.restoreCaseModal.isProcessingStatus(false);
        if (err) {
          return this.$refs.restoreCaseModal.handleErrorMessageModal(err);
        }
        if (data) {
          this.$refs.restoreCaseModal.close();
          this.$alertSuccess(
            this.$t('pages/cases/form/alert/restoreCase', {
              caseReference: this.caseReference,
              caseStatus: this.caseStatusBeforeDelete,
            }),
          );
          this.reloadCaseList();
        }
      }
    },

    exportPdfFile() {
      this.$alertSuccess(this.$t('global/messages/underDevelopment'));
    },

    ...mapActions('app/event', ['removeEvent']),

    reloadCaseList() {
      this.search(this.query);
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST));
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetAllState();
    next(true);
  },
};
</script>
<style lang="scss">
.case-list {
  .list-menu {
    ul {
      display: flex;
      justify-content: flex-end;
      li {
        list-style: none;
        margin-left: 10px;
        cursor: pointer;
        color: #0035ff;
        text-decoration: underline;
      }
    }
  }
  .field-search {
    width: 100%;
    padding: 10px 0 0 10px;
  }
  .paginator {
    .left-side p {
      padding-top: 15px;
    }
  }
}
</style>
