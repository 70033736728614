<template>
  <div id="specimenDetailsBlock">
    <ValidationObserver ref="specimenDetailForm" v-if="specimenTypeList">
      <modal-concurrency-issue
        ref="concurrencyIssueModal"
        :name-of-block="'Specimen Details'"
        @onContinue="$onConcurrencyIssueContinue"
      ></modal-concurrency-issue>
      <modal-confirm-slide-block
        ref="confirmSlideBlockModal"
        :title="$t('components/lims/modals/ModalConfirmation.title')"
        :description="confirmSlideBlockModalDescription"
        @onConfirm="onConfirmActionInSlideBlock"
        @onCancel="onCancelActionInSlideBlock"
      />
      <collapse
        :wrapperClass="'case-collapse specimen-detail-collapse'"
        :collapse="collapseName"
        icon="keyboard_arrow_down"
        :expand-collapse-block-name="'specimenDetailsItemBlock'"
      >
        <template slot="md-collapse-pane-1">
          <modal-confirm-generic
            ref="confirmModal"
            :title="$t('components/lims/modals/ModalConfirmation.title')"
            :description="$t('components/lims/modals/ModalConfirmSaveChange.text')"
            @onConfirm="onSaveChangesSpecimenTypeInForm"
            @onCancel="onCancelChangeSpecimenType"
          />
          <div class="md-layout lims-form-row" v-if="formData">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="caseFormSchema" field="noOfSpicemen">
                <md-input
                  slot="field"
                  type="number"
                  :step="1"
                  :min="1"
                  :max="40"
                  v-model="formData.noOfSpicemen"
                  @keypress="isNumber($event)"
                  class="no-of-specimen"
                  :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isDisableNoOfSpecimen || isPathView"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div v-if="formMode !== ADD_MODE" class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field
                    class="textarea-field"
                    :model="formData"
                    :schema="caseFormSchema"
                    field="countOfOriginalSlides"
                  >
                  </lims-field>
                  <div class="static-text">{{ formData.countOfOriginalSlides }}</div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100 same-specimen-type">
              <md-checkbox
                v-if="!isPathView"
                v-model="formData.isSameSpecimenType"
                class="lims-checkbox"
                :disabled="!formData.noOfSpicemen || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                @change="onChangeSameSpecimenTypeCheckbox"
                tabenable="yes"
              >
                {{ $t('pages/case/CaseManagement/components/SpecimenDetail/sameSpecimenType') }}
              </md-checkbox>
              <md-checkbox
                v-model="formData.specimenLineBreakMapping"
                class="lims-checkbox"
                :disabled="!formData.noOfSpicemen || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                @change="onChangeLineBreakMapping"
                tabenable="yes"
              >
                {{ $t('pages/case/CaseManagement/components/SpecimenDetail/lineBreakMapping') }}
              </md-checkbox>
            </div>
            <div v-if="!isPathView" class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="caseFormSchema" field="sameSpecimenTypeId">
                <multiselect
                  slot="field"
                  v-model="formData.sameSpecimenTypeId"
                  :options="specimenTypeListData(specimenTypeList, formData.sameSpecimenTypeId)"
                  :multiple="false"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  placeholder=""
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="false"
                  :group-select="false"
                  :disabled="!formData.isSameSpecimenType || viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                  tabenable="yes"
                  :tabindex="100"
                >
                </multiselect>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="specimenDetails">
                <md-textarea
                  slot="field"
                  v-model="formData.specimenDetails"
                  :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="clinicalDetails">
                <md-textarea
                  slot="field"
                  v-model="formData.clinicalDetails"
                  :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>

          <div v-if="formData.caseSpecimens">
            <label class="lims-text-danger" v-if="isSlideResetToNull && $isEntityChangedShowWarning(dataEdit)">{{
              $t('pages/case/CaseManagement/CaseForm/SwapEntity/ValueReset/Field/slide')
            }}</label>
            <div
              v-for="(caseSpecimen, n) in formData.caseSpecimens"
              :key="n"
              class="p-specimen"
              :id="'specimen-' + `${n + 1}`"
            >
              <collapse
                v-if="formData.caseSpecimens[n]"
                :wrapperClass="'specimen-collapse'"
                :collapse="collapseSpecimenName(n)"
                icon="keyboard_arrow_down"
                :expand-collapse-block-name="'specimen-' + `${n + 1}`"
              >
                <template slot="md-collapse-pane-1">
                  <div class="md-layout lims-form-row">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                      <lims-field
                        :model="formData.caseSpecimens[n]"
                        :schema="caseFormSchema.caseSpecimens"
                        :field="'specimenTypeId-specimen' + `${n}`"
                      >
                        <multiselect
                          slot="field"
                          v-model="formData.caseSpecimens[n].specimenTypeId"
                          :options="specimenTypeListData(specimenTypeList, formData.caseSpecimens[n].specimenTypeId)"
                          :multiple="false"
                          :show-labels="false"
                          group-values="items"
                          group-label="itemName"
                          placeholder=""
                          track-by="fieldItemId"
                          label="fieldItemName"
                          :hide-selected="false"
                          :group-select="false"
                          :disabled="
                            formData.isSameSpecimenType ||
                            viewMode ||
                            (dataEdit ? dataEdit.isDeleted : false) ||
                            isPathView
                          "
                          tabenable="yes"
                          :tabindex="101"
                        >
                        </multiselect>
                      </lims-field>
                    </div>
                  </div>
                  <div class="md-layout lims-form-row">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <lims-field
                        class="textarea-field"
                        :model="formData.caseSpecimens[n]"
                        :schema="caseFormSchema.caseSpecimens"
                        :field="'specimenDetailsOnPot-specimen' + `${n}`"
                      >
                        <md-textarea
                          slot="field"
                          v-model="formData.caseSpecimens[n].specimenDetails"
                          :disabled="
                            formData.specimenLineBreakMapping || viewMode || (dataEdit ? dataEdit.isDeleted : false)
                          "
                          tabenable="yes"
                        ></md-textarea>
                      </lims-field>
                    </div>
                    <div class="md-layout-item md-size-75 md-small-size-100 macroDescription-field">
                      <lims-field
                        class="textarea-field"
                        :model="formData.caseSpecimens[n]"
                        :schema="caseFormSchema.caseSpecimens"
                        field="macroDescription"
                      >
                        <md-textarea
                          slot="field"
                          v-model="formData.caseSpecimens[n].macroDescription"
                          :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                          tabenable="yes"
                        ></md-textarea>
                      </lims-field>
                    </div>
                    <div class="md-layout-item md-size-25 md-small-size-100">
                      <md-checkbox
                        v-model="formData.caseSpecimens[n].tissueRemaining"
                        class="lims-checkbox tissue-remaining"
                        tabenable="yes"
                        :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isPathView"
                      >
                        {{ $t('pages/case/CaseManagement/component/specimen/table/tissueRemaining') }}
                      </md-checkbox>
                    </div>
                  </div>
                  <div v-if="!isPathView" class="md-layout lims-form-row">
                    <div class="md-layout-item md-size-100 md-small-size-100">
                      <lims-field
                        :model="formData.caseSpecimens[n]"
                        :schema="caseFormSchema.caseSpecimens"
                        field="nbOfBlocks"
                      ></lims-field>
                      <div class="md-static-field">
                        <md-field>
                          <md-input
                            disabled
                            :value="formData.caseSpecimens[n].caseSpecimenBlocks.length"
                            type="text"
                            tabenable="yes"
                          ></md-input>
                        </md-field>
                      </div>
                      <md-button
                        @click="onConfirmAddNewBlock(n)"
                        :disabled="
                          viewMode ||
                          (dataEdit ? dataEdit.isDeleted : false) ||
                          formData.caseSpecimens[n].caseSpecimenBlocks.length >= maximumBlockNumber
                        "
                        >{{ $t('pages/case/CaseManagement/components/SpecimenDetail/button.newBlock') }}</md-button
                      >
                    </div>
                  </div>
                  <div class="md-layout lims-form-row">
                    <template v-if="!isPathView">
                      <md-table class="custom-tbl" md-card :class="isErrorReviewCase ? 'border-required' : ''">
                        <md-table-row
                          :class="
                            isErrorReviewCase &&
                            formData.caseSpecimens[n] &&
                            formData.caseSpecimens[n].caseSpecimenBlocks[0] &&
                            checkArrayEmpty(formData.caseSpecimens[n].caseSpecimenBlocks[0].blockField)
                              ? 'border-required'
                              : ''
                          "
                        >
                          <md-table-head>
                            {{ $t('pages/case/CaseManagement/components/SpecimenDetail/table.block') }}
                            <span style="color: red" v-if="isReviewCaseScreen">*</span>
                            <lims-tooltip
                              :content="$t('pages/case/CaseManagement/components/SpecimenDetail/table.blockTooltip')"
                            ></lims-tooltip>
                          </md-table-head>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/components/SpecimenDetail/table.HE')
                          }}</md-table-head>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/components/SpecimenDetail/table.Immunos')
                          }}</md-table-head>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/components/SpecimenDetail/table.SpecialStains')
                          }}</md-table-head>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/components/SpecimenDetail/table.AdditionalTechniques')
                          }}</md-table-head>
                          <md-table-head class="th-center">{{ $t('global/pages/list.actions') }}</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(block, i) in formData.caseSpecimens[n].caseSpecimenBlocks" :key="i">
                          <md-table-cell>{{ $generateBlockName(n, i) }}</md-table-cell>
                          <md-table-cell>
                            <SpecimenDetailSlides
                              v-model="formData.caseSpecimens[n].caseSpecimenBlocks[i].blockField[0]"
                              :options="HEList"
                              :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                              :tabindex="102"
                              :dataEdit="dataEdit"
                              :specimenIndex="n"
                              :blockIndex="i"
                            >
                            </SpecimenDetailSlides>
                          </md-table-cell>
                          <md-table-cell>
                            <SpecimenDetailSlides
                              v-model="formData.caseSpecimens[n].caseSpecimenBlocks[i].blockField[1]"
                              :options="ImmunosList"
                              :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                              :tabindex="102"
                              :dataEdit="dataEdit"
                              :specimenIndex="n"
                              :blockIndex="i"
                            >
                            </SpecimenDetailSlides>
                          </md-table-cell>
                          <md-table-cell>
                            <SpecimenDetailSlides
                              v-model="formData.caseSpecimens[n].caseSpecimenBlocks[i].blockField[2]"
                              :options="SpecialStainsList"
                              :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                              :tabindex="102"
                              :dataEdit="dataEdit"
                              :specimenIndex="n"
                              :blockIndex="i"
                            >
                            </SpecimenDetailSlides>
                          </md-table-cell>
                          <md-table-cell>
                            <SpecimenDetailSlides
                              v-model="formData.caseSpecimens[n].caseSpecimenBlocks[i].blockField[3]"
                              :options="AdditionalTechniquesList"
                              :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                              :tabindex="102"
                              :dataEdit="dataEdit"
                              :specimenIndex="n"
                              :blockIndex="i"
                            >
                            </SpecimenDetailSlides>
                          </md-table-cell>
                          <md-table-cell class="th-center">
                            <span
                              @click="
                                viewMode || (dataEdit ? dataEdit.isDeleted : false)
                                  ? ''
                                  : onConfirmDelBlock(
                                      formData.caseSpecimens[n],
                                      formData.caseSpecimens[n].caseSpecimenBlocks[i].block,
                                      n,
                                      i,
                                      $event,
                                    )
                              "
                            >
                              <md-icon>close</md-icon></span
                            >
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </template>
                    <template v-else>
                      <md-table class="custom-tbl" md-card>
                        <md-table-row>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/component/specimen/table/block')
                          }}</md-table-head>
                          <md-table-head>{{
                            $t('pages/case/CaseManagement/component/specimen/table/slide')
                          }}</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(block, i) in formData.caseSpecimens[n].caseSpecimenBlocks" :key="i">
                          <md-table-cell>{{ $generateBlockName(n, i) }}</md-table-cell>
                          <md-table-cell>{{ getSlideList(block.blockFieldItems) }}</md-table-cell>
                        </md-table-row>
                      </md-table>
                    </template>
                  </div>
                </template>
              </collapse>
            </div>
          </div>
          <div class="md-layout lims-form-row" v-if="isShowDiscardTissueBlock">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                v-model="formData.tissueDiscarded"
                :disabled="dataEdit.isDeleted || viewMode"
                class="lims-checkbox mg-top-40"
              >
                {{ $t('pages/case/CaseManagement/component/specimen/table/tissueDiscarded') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData" :schema="caseFormSchema" field="tissueDiscardedDate">
                <date-picker
                  :lang="{
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                  }"
                  slot="field"
                  v-model="formData.tissueDiscardedDate"
                  format="DD/MM/YYYY"
                  :disabled="!formData.tissueDiscarded || dataEdit.isDeleted || viewMode"
                ></date-picker>
              </lims-field>
            </div>
          </div>
          <div
            v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)"
            class="md-layout lims-form-row"
          >
            <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
              <lims-form-cancel></lims-form-cancel>
              <md-button
                @click="onSaveSpecimens()"
                :disabled="isProcessing"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.save') }}
              </md-button>
            </div>
          </div>
        </template>
      </collapse>
    </ValidationObserver>
  </div>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import {
  ACTION_IN_SLIDE_BLOCK,
  APP_EVENTS,
  CASE_FORM_BLOCK,
  CASE_STATUS,
  DROPDOWN_FIELD_NAME,
  DROPDOWN_SHORT_NAME,
  newAppEvent,
} from '@/core/constants';
import { caseFormService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';
import ModalConfirmGeneric from '@/components/Lims/modals/ModalConfirmGeneric';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock';
// import { sortBy } from 'lodash';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import cloneDeep from 'lodash/cloneDeep';
import CaseBlockMixins, { FIELDS_WAS_RESET_TO_NULL } from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { isInValidateBlockAndSlide, isSpecimenTypeChanged } from '@/pages/Case/CaseManagement/Edit/caseSave.mixins';
import SpecimenDetailSlides from '@/pages/Case/CaseManagement/Components/SpecimenDetailSlides';
import debounce from 'lodash/debounce';
import { computeSpecimenTypeId } from '@/store/modules/case-data';
import {
  convertFromDateTimezoneToIsoString,
  filterDropdownByHiddenFieldListSlide,
  filterDropdownListByHiddenField,
  fromISOToCurrentTimezone,
} from '@/core/helpers';
import caseSlideMixins from '../Edit/caseSlide.mixins';

const isSpecimenRemovable = (caseSpecimenBlocks, specimensLength) => {
  const isLastSpecimen = true;
  let specimenHasNoSlidesLeft = true;
  for (let i = 0; i < caseSpecimenBlocks.length; i++) {
    if (caseSpecimenBlocks[i].blockFieldItems.length > 0) {
      specimenHasNoSlidesLeft = false;
      break;
    }
  }
  const caseHasMoreThanOneSpecimen = specimensLength > 1;
  return isLastSpecimen && specimenHasNoSlidesLeft && caseHasMoreThanOneSpecimen;
};

const buildCaseSpecimenBlockField = (f) => {
  return {
    ...f,
    value: f.fieldItemId,
    label: f.fieldItemName,
    fieldId: f.fieldId,
    fieldItemId: f.fieldItemId,
  };
};
const buildCaseSpecimenBlock = (caseSpecimenBlock) => {
  const blockField = [[], [], [], []];

  const { blockFieldItems } = caseSpecimenBlock;
  const fieldItems = blockFieldItems.map((f) => buildCaseSpecimenBlockField(f));
  const HEFields = fieldItems.filter((f) => f.fieldName == 'H&E' || f.fieldName == 'HE');
  const ImmunosFields = fieldItems.filter((f) => f.fieldName == 'Immunos');
  const SpecialStainsFields = fieldItems.filter((f) => f.fieldName == 'Special Stains');
  const AdditionalTechniquesFields = fieldItems.filter((f) => f.fieldName == 'Add. Techniques');
  if (fieldItems.length > 0) {
    blockField[0] = HEFields;
    blockField[1] = ImmunosFields;
    blockField[2] = SpecialStainsFields;
    blockField[3] = AdditionalTechniquesFields;
  }
  return {
    ...caseSpecimenBlock,
    blockFieldItems: blockFieldItems,
    blockField,
  };
};
const buildCaseSpecimenGroup = (caseSpecimen) => {
  const nbOfBlocks = caseSpecimen.caseSpecimenBlocks.length;
  const caseSpecimenBlocks = caseSpecimen.caseSpecimenBlocks.map((item) => buildCaseSpecimenBlock(item));
  return {
    ...caseSpecimen,
    sort: caseSpecimen.caseSpecimenBlocks.length > 0 ? caseSpecimen.caseSpecimenBlocks[0].block || '' : '',
    nbOfBlocks,
    caseSpecimenBlocks: caseSpecimenBlocks,
  };
};
const buildCaseSpecimenModel = (caseSpecimens) => {
  return caseSpecimens.map((caseSpecimen) => {
    return {
      specimenDetails: caseSpecimen.specimenDetails ? caseSpecimen.specimenDetails : '',
      macroDescription: caseSpecimen.macroDescription,
      tissueRemaining: caseSpecimen.tissueRemaining,
      specimenTypeId: caseSpecimen.specimenTypeId ? caseSpecimen.specimenTypeId.fieldItemId : null,
      caseSpecimentId: caseSpecimen.caseSpecimentId,
      caseSpecimenBlocks: caseSpecimen.caseSpecimenBlocks.map((caseSpecimenBlock) => {
        const { block, blockField } = caseSpecimenBlock;
        const blockFieldItems = [];
        for (const key in blockField) {
          if (Object.hasOwnProperty.call(blockField, key)) {
            // eslint-disable-next-line security/detect-object-injection
            blockFieldItems.push(...blockField[key]);
          }
        }
        if (caseSpecimenBlock.caseSpecimenBlockId) {
          return {
            caseSpecimenBlockId: caseSpecimenBlock.caseSpecimenBlockId,
            block: block,
            // API Naming issue
            blockFieldItems,
          };
        }
        return {
          block: block,
          // API Naming issue
          blockFieldItems,
        };
      }),
    };
  });
};

const mappingSpecimenTypeList = (specimenTypeList) => {
  const groupOptions = [];
  const options = [];
  specimenTypeList.forEach((specimenTypeItem) => {
    const { itemName, items } = specimenTypeItem;
    groupOptions.push({
      itemName,
      items: [
        {
          fieldItemName: '',
        },
      ],
    });

    items.forEach((item) => {
      options.push(...item.items);
      groupOptions.push({
        itemName: item.itemName,
        //items: filterDropdownListByHiddenField(item.items),
        items: item.items,
      });
    });
  });
  return { groupOptions, options };
};

const newSpecimen = (specimenTypeId = null) => {
  return {
    specimenDetails: '',
    macroDescription: '',
    tissueRemaining: false,
    specimenTypeId,
    caseSpecimenBlocks: [
      {
        block: '',
        blockField: [[], [], [], []],
        blockFieldItems: [],
      },
    ],
    nbOfBlocks: 0,
  };
};

const getCurrentSameSpecimenType = (formData) => {
  const { isSameSpecimenType, sameSpecimenTypeId } = formData;
  if (isSameSpecimenType && sameSpecimenTypeId) {
    return sameSpecimenTypeId;
  }
  return null;
};

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins, CaseSpecimenMixins, caseSlideMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: true,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
      default: false,
    },
    isErrorReviewCase: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  created: function () {
    this.loadSpecimenBlockData();
    if (this.formMode === this.ADD_MODE) {
      if (this.formData.noOfSpicemen) {
        for (let i = 0; i < this.formData.noOfSpicemen; i++) {
          // eslint-disable-next-line security/detect-object-injection
          if (!this.formData.caseSpecimens[i]) {
            this.$set(this.formData.caseSpecimens, i, newSpecimen());
          }
        }
      }
    }
    this.fetchData(this.dataEdit);
  },
  components: {
    ModalConcurrencyIssue,
    ModalConfirmGeneric,
    ModalConfirmSlideBlock,
    Collapse,
    SpecimenDetailSlides,
  },
  computed: {
    ...mapGetters('caseForm', ['getCaseSpecimenDetailChangeWithCaseId']),
    ...mapGetters('caseForm', ['isSlideResetToNull', 'isChangePathologist']),
    ...mapGetters('auth', ['entityId']),
    ...mapGetters('caseData', ['caseData']),
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM,
      APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED,
      APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED,
      APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED,
      APP_EVENTS.EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED,
      APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE,
      APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN,
    ]),
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, this.isSameSpecimen, null, null, {
        isReviewCaseScreen: this.isReviewCaseScreen,
        isPreLabStatus: this.isPreLabStatus,
        isRequired: this.isRequired,
        noOfSpicemen: this.formData.noOfSpicemen,
        tissueDiscarded: this.formData.tissueDiscarded,
      });
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/SpecimenDetail/blockTitle';
      return [this.$translate(name)];
    },
    collapseSpecimenName() {
      const name = 'pages/case/CaseManagement/SpecimenDetail/Specimen/blockTitle';
      return (index) => [this.$translate(name) + ' ' + generateSpecimenName(index, this.blockNamingRuleSetting)];
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Pathologist;
    },
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Administrator;
    },
    isPreLabStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    isPathStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PATH;
    },
    isProvisionallyReportedStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    isReportedStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.REPORTED;
    },
    isDisableNoOfSpecimen() {
      return (
        this.dataEdit?.status === CASE_STATUS.PROVISIONALLY_REPORTED || this.dataEdit?.status === CASE_STATUS.REPORTED
      );
    },
    isRequired() {
      return (
        (this.isAdminView && (this.isPathStatus || this.isProvisionallyReportedStatus || this.isReportedStatus)) ||
        (this.isPathView && this.isPathStatus)
      );
    },
    isShowDiscardTissueBlock() {
      return (
        this.hasAtLeastOneSpecimenHaveTissueRemain() &&
        this.dataEdit?.status === CASE_STATUS.REPORTED &&
        this.isAdminView
      );
    },

    maximumBlockNumber() {
      return this.blockNamingRuleSetting?.skippedIO ? 48 : 52;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: async function (val) {
        let specimenTypeId = computeSpecimenTypeId(val);
        const specimenDetailModel = buildCaseSpecimenModel(val.caseSpecimens);
        this.$emit('input', {
          ...val,
          specimenDetailModel,
        });

        // add to store
        if (this.dataEdit) {
          const dataForm = this.specimenDetailData();
          const additionalData = this.additionalData();
          // related ticket 30265: Show Modal Confirmation when user add/delete slide/block of case status past Lab status
          // emit changes
          this.appendCaseData({
            caseData: {
              caseSpecimens: dataForm,
              specimenTypeId,
              ...additionalData,
            },
            blockId: CASE_FORM_BLOCK.BLOCK_SPECIMEN,
          });
        } else {
          this.appendCaseData({
            caseData: {
              specimenTypeId,
            },
            blockId: CASE_FORM_BLOCK.BLOCK_SPECIMEN,
          });
        }
      },
    },

    'formData.noOfSpicemen': {
      handler(val, previousVal) {
        if (previousVal && parseInt(previousVal) > parseInt(val)) {
          // reduce if allowed
          const lastSpecimen = this.formData.caseSpecimens[parseInt(previousVal) - 1];
          if (
            lastSpecimen &&
            lastSpecimen.caseSpecimenBlocks &&
            previousVal - val === 1 &&
            !isSpecimenRemovable(lastSpecimen.caseSpecimenBlocks, previousVal)
          ) {
            this.$alertError(
              this.$translate('pages/case/CaseManagement/SpecimenDetail/deleteLastSpecimenValidationError'),
            );

            this.$nextTick(() => {
              this.$set(this.formData, 'noOfSpicemen', previousVal);
            });
            return;
          }
          this.formData.caseSpecimens.splice(Math.max(1, parseInt(val)), previousVal - parseInt(val));
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED, {
              noOfSpicemen: parseInt(val),
              blockNamingRuleSetting: this.blockNamingRuleSetting,
            }),
          );
          return;
        }
        if (previousVal != val) {
          const specimenTypeId = getCurrentSameSpecimenType(this.formData);
          for (let i = 0; i < parseInt(val); i++) {
            // eslint-disable-next-line security/detect-object-injection
            if (!this.formData.caseSpecimens[i]) {
              this.$set(this.formData.caseSpecimens, i, newSpecimen(specimenTypeId));
            }
          }
          this.onChangeLineBreakMapping();
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED, {
              noOfSpicemen: parseInt(val),
              blockNamingRuleSetting: this.blockNamingRuleSetting,
            }),
          );
        }
      },
      deep: true,
    },
    'formData.sameSpecimenTypeId': {
      handler(val) {
        if (this.formData.isSameSpecimenType) {
          const arrSpecimenTypeItem = [];
          this.specimenTypeList.forEach((element) => arrSpecimenTypeItem.push(...element.items));
          this.formData.caseSpecimens.forEach((specimen) => {
            if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
              if (this.formMode != this.VIEW_MODE && this.dataEdit?.status != CASE_STATUS.REPORTED) {
                if (val.isHide) {
                  this.$set(this.formData, 'sameSpecimenTypeId', null);
                }
              }
              specimen.specimenTypeId = val;
            } else {
              const foundSpecimentType = val ? arrSpecimenTypeItem.find((x) => x.fieldItemId == val) : val;
              specimen.specimenTypeId = foundSpecimentType || null;
            }
          });
        }
      },
      deep: true,
    },
    'formData.specimenLineBreakMapping': {
      handler(value) {
        if (value) {
          this.onChangeLineBreakMapping();
        }
      },
      deep: true,
    },

    'formData.specimenDetails': {
      handler: debounce(function (value) {
        if (value && this.formData.specimenLineBreakMapping) {
          const listLineBreakMapping = value.split('\n');
          this.formData.caseSpecimens.forEach((specimen, index) => {
            specimen.specimenDetails = listLineBreakMapping[index];
          });
        }
      }, 100),
      deep: true,
    },

    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadData(this.dataEdit);
        }
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadData(this.dataEdit);
        }
      },
    },
    [APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED]: {
      deep: true,
      handler: async function (val) {
        const laboratoryId = this.dataEdit ? this.dataEdit.laboratoryId : null;
        if (val && val.laboratoryId != laboratoryId) {
          // reload data
          const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(
            val.laboratoryId,
            [
              DROPDOWN_SHORT_NAME.H_AND_E,
              DROPDOWN_SHORT_NAME.IMMUNOS,
              DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
              DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
            ],
            this.dataEdit?.caseId,
          );
          this.updateDataset({
            ...dropdownStainOptions,
          });
          // reload list
          this.loadSpecimenBlockData();
          const atLeastOneBlockWasResetToNull = this.filterBlockFieldInSpecimens(this.formData.caseSpecimens);
          // [Swap Entity checked]
          if (atLeastOneBlockWasResetToNull) {
            this.markFieldToBeSetNull(FIELDS_WAS_RESET_TO_NULL.SLIDE);
          }
          this.$resetBlockChanged();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED]: {
      deep: true,
      handler: async function (val) {
        this.clinicId = val?.clinicId;
      },
    },

    [APP_EVENTS.EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED]: {
      deep: true,
      handler: async function (val) {
        if (val && this.receivedWorkStreamId != val.workStreamId) {
          if (val.workStreamId) {
            this.receivedWorkStreamId = val.workStreamId;
            const data = await this.loadSpecimenTypeData(this.clinicId, val.workStreamId);
            if (data) {
              const { groupOptions, options } = data;
              this.$set(this, 'specimenTypeOptions', options);
              this.$set(this, 'specimenTypeList', groupOptions);
              this.validateSpecimenTypeOption();
            } else {
              this.$set(this, 'specimenTypeOptions', []);
              this.$set(this, 'specimenTypeList', []);
              this.$set(this.formData, 'sameSpecimenTypeId', null);
              this.formData.caseSpecimens.forEach((specimen) => {
                specimen.specimenTypeId = null;
              });
            }
          } else {
            this.receivedWorkStreamId = null;
            this.$set(this, 'specimenTypeOptions', []);
            this.$set(this, 'specimenTypeList', []);
            this.$set(this.formData, 'sameSpecimenTypeId', null);
            this.formData.caseSpecimens.forEach((specimen) => {
              specimen.specimenTypeId = null;
            });
          }
          // remove event
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_WORKSTREAM_ID_CHANGED));
        }
      },
    },
    [APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN));
          this.$nextTick(() => {
            this.loadSpecimenBlockData();
          });
        }
      },
    },
  },
  data() {
    return {
      isSameSpecimen: false,
      specimenTypeList: null,
      specimenTypeOptions: [],
      HEList: [],
      ImmunosList: [],
      SpecialStainsList: [],
      AdditionalTechniquesList: [],
      formData: {
        noOfSpicemen: 1,
        caseSpecimens: [],
        countOfOriginalSlides: 0,
      },
      originalCaseSpecimens: [],
      rowVersion: null,
      blockId: CASE_FORM_BLOCK.BLOCK_SPECIMEN,
      blockNamingRuleSetting: { enable1ANamingConvention: false, skippedIO: false },
      confirmSlideBlockModalDescription: '',
      dataAddNewBlock: null,
      dataDelBlock: null,
      clinicId: null,
      countSlidesBeforeChange: 0,
      slideIdsRemoved: [],
      isProcessing: false,
      receivedWorkStreamId: null,
    };
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('caseForm', ['setIsInformedSpecimenTypeChanged', 'markFieldToBeSetNull']),
    ...mapActions('app/event', ['addEvent', 'removeEvent']),
    async fetchData(dataEdit) {
      if (dataEdit) {
        this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(dataEdit.laboratoryId);
        const { groupOptions, options } = await this.loadSpecimenTypeData(dataEdit.clinicId, dataEdit.workStreamId);
        const caseSpecimens = dataEdit.caseSpecimens
          .map((s) => {
            let specimenType = options.find((x) => x.fieldItemId === s.specimenTypeId);
            if (this.formMode != this.VIEW_MODE && this.dataEdit?.status != CASE_STATUS.REPORTED) {
              if (specimenType && specimenType.isHide) {
                specimenType = null;
              }
            }
            return {
              ...s,
              specimenTypeId: specimenType,
            };
          })
          .map((c) => buildCaseSpecimenGroup(c));
        this.filterBlockFieldInSpecimens(caseSpecimens);
        this.originalCaseSpecimens = cloneDeep(dataEdit.caseSpecimens);
        this.loadSpecimenBlockData();

        this.formData = {
          ...dataEdit,
          tissueDiscardedDate:
            dataEdit.tissueDiscarded && dataEdit.tissueDiscardedDate
              ? fromISOToCurrentTimezone(dataEdit.tissueDiscardedDate)
              : null,
          sameSpecimenTypeId: options.find((x) => x.fieldItemId == dataEdit.sameSpecimenTypeId),
          noOfSpicemen: caseSpecimens.length,
          caseSpecimens,
        };
        this.isSameSpecimen = this.formData.isSameSpecimenType;
        this.specimenTypeOptions = options;
        this.specimenTypeList = groupOptions;
        this.receivedWorkStreamId = this.dataEdit.workStreamId;
      } else {
        this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.entityId);
        this.specimenTypeList = [];
        this.specimenTypeOptions = [];
      }

      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED, {
          noOfSpicemen: this.formData.caseSpecimens.length,
          blockNamingRuleSetting: this.blockNamingRuleSetting,
        }),
      );
      this.$resetBlockChanged();
    },
    async loadSpecimenTypeData(clinicId, workStreamId) {
      if (!clinicId || !workStreamId) {
        return null;
      } else {
        const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [
          DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM,
        ]);
        const specimenTypeList = dropdownStainOptions[DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM];

        // filter specimence type by WorkStream
        if (specimenTypeList && specimenTypeList.length > 0) {
          let specimenTypeListArr = [];
          const specimenTypeListDependOnWorkStream = specimenTypeList.reduce((i, value) => {
            const listItem = {
              ...value,
              items: value.items.filter((child) => child.itemId == workStreamId),
            };
            if (listItem.items.length > 0) {
              specimenTypeListArr.push(listItem);
            }
            return specimenTypeListArr;
          }, 0);

          return mappingSpecimenTypeList(specimenTypeListDependOnWorkStream);
        } else {
          return null;
        }
      }
    },
    filterBlockFieldInSpecimens(specimens) {
      let atLeastOneBlockWasResetToNull = false;
      const isInListValue = (item, list) => {
        return list.find((f) => f.fieldItemId === item.fieldItemId);
      };
      const isInAvailableList = (f) => {
        const isInHEList = isInListValue(f, this.HEList);
        const isInImmunosList = isInListValue(f, this.ImmunosList);
        const isInSpecialStainsList = isInListValue(f, this.SpecialStainsList);
        const isInAdditionalTechniquesList = isInListValue(f, this.AdditionalTechniquesList);
        return isInHEList || isInImmunosList || isInSpecialStainsList || isInAdditionalTechniquesList;
      };
      const filterBlockFieldInCaseSpecimenBlock = (caseSpecimenBlock) => {
        if (caseSpecimenBlock.blockFieldItems) {
          caseSpecimenBlock.blockFieldItems = caseSpecimenBlock.blockFieldItems.filter((f) => isInAvailableList(f));
          for (let i = 0; i < caseSpecimenBlock.blockField.length; i++) {
            const newBlockValueAfterFilter = caseSpecimenBlock.blockField[i].filter((f) => isInAvailableList(f));
            if (newBlockValueAfterFilter.length === 0 && caseSpecimenBlock.blockField[i].length != 0) {
              atLeastOneBlockWasResetToNull = true;
            }
            caseSpecimenBlock.blockField[i] = newBlockValueAfterFilter;
          }
        }
      };
      specimens.forEach((specimen) => {
        if (specimen.caseSpecimenBlocks) {
          specimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
            filterBlockFieldInCaseSpecimenBlock(caseSpecimenBlock);
          });
        }
      });
      return atLeastOneBlockWasResetToNull;
    },
    loadSpecimenBlockData() {
      const HEList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.H_AND_E);
      const ImmunosList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.IMMUNOS);
      const SpecialStainsList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SPECIAL_STAINS);
      const AdditionalTechniquesList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE);

      this.HEList = filterDropdownListByHiddenField(HEList);
      this.ImmunosList = filterDropdownListByHiddenField(ImmunosList);
      this.SpecialStainsList = filterDropdownListByHiddenField(SpecialStainsList);
      this.AdditionalTechniquesList = filterDropdownListByHiddenField(AdditionalTechniquesList);

      if (
        (this.formMode == this.EDIT_MODE && this.dataEdit.status == CASE_STATUS.REPORTED) ||
        this.formMode == this.VIEW_MODE
      ) {
        this.HEList = filterDropdownByHiddenFieldListSlide(
          HEList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.HE,
        );

        this.ImmunosList = filterDropdownByHiddenFieldListSlide(
          ImmunosList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.Immunos,
        );

        this.SpecialStainsList = filterDropdownByHiddenFieldListSlide(
          SpecialStainsList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.SpecialStains,
        );

        this.AdditionalTechniquesList = filterDropdownByHiddenFieldListSlide(
          AdditionalTechniquesList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.AddTechniques,
        );
      }
    },
    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkArrayEmpty(arr) {
      return Array.isArray(arr) && arr.every(this.checkArrayEmpty);
    },
    onConfirmAddNewBlock(index) {
      this.dataAddNewBlock = index;
      if (this.$isStatusShowModalConfirmSlideBlock) {
        this.$showModalConfirmSlideBlock(ACTION_IN_SLIDE_BLOCK.ADD_BLOCK);
      } else {
        this.addNewBlock(this.dataAddNewBlock);
      }
    },
    addNewBlock(index) {
      this.formData.caseSpecimens[index].caseSpecimenBlocks.push({
        block: '',
        blockField: [[], [], [], []],
        blockFieldItems: [],
      });
      this.$set(this.formData, this.formData.caseSpecimens);
    },

    async onConfirmDelBlock(specimen, blockName, specimenIndex, blockIndex, $event) {
      this.dataDelBlock = { specimen, blockName, $event };
      this.getDeletedSlideWhenRemovedBlock(specimen, blockName);
      const { valid, error } = await this.$checkSlideInPendingAlwRequest({
        specimenIndex,
        blockIndex,
        caseId: this.dataEdit.caseId,
        slideIds: this.slideIdsRemoved,
      });
      if (valid) {
        if (this.$isStatusShowModalConfirmSlideBlock) {
          this.$showModalConfirmSlideBlock(ACTION_IN_SLIDE_BLOCK.DELETE_BLOCK);
        } else {
          this.removeBlock(this.dataDelBlock);
        }
      } else {
        this.$alertError(error);
      }
    },
    getDeletedSlideWhenRemovedBlock(specimen, blockName) {
      const caseSpecimenBlocks = specimen.caseSpecimenBlocks.find((item) => item.block === blockName);
      let deletedSlides = [];
      caseSpecimenBlocks.blockField.forEach((blockField) => {
        blockField.forEach((item) => {
          deletedSlides.push(item.fieldItemId);
        });
      });
      this.slideIdsRemoved = deletedSlides;
    },
    removeBlock(dataDelBlock) {
      dataDelBlock.specimen.caseSpecimenBlocks = dataDelBlock.specimen.caseSpecimenBlocks.filter(
        (b) => b.block != dataDelBlock.blockName,
      );
      dataDelBlock.$event.preventDefault();
    },
    onChangeSameSpecimenTypeCheckbox() {
      if (this.formData.isSameSpecimenType) {
        this.formData.caseSpecimens.forEach((specimen) => {
          specimen.specimenTypeId = this.formData.sameSpecimenTypeId;
        });
        this.isSameSpecimen = true;
      } else {
        this.isSameSpecimen = false;
      }
    },
    onChangeLineBreakMapping() {
      if (this.formData.specimenDetails && this.formData.specimenLineBreakMapping) {
        const listLineBreakMapping = this.formData.specimenDetails.split('\n');
        this.formData.caseSpecimens.forEach((specimen, index) => {
          specimen.specimenDetails = listLineBreakMapping[index];
        });
      } else if (!this.formData.specimenDetails && this.formData.specimenLineBreakMapping) {
        this.formData.caseSpecimens.forEach((specimen) => {
          specimen.specimenDetails = '';
        });
      }
    },
    specimenDetailData() {
      const caseSpecimenUpdateModels = buildCaseSpecimenModel(this.formData.caseSpecimens);
      const data = {
        specimenLineBreakMapping: this.formData.specimenLineBreakMapping,
        specimenDetails: this.formData.specimenDetails,
        clinicalDetails: this.formData.clinicalDetails,
        sameSpecimenTypeId: this.formData.sameSpecimenTypeId ? this.formData.sameSpecimenTypeId.fieldItemId : '',
        isSameSpecimenType: this.formData.isSameSpecimenType,
        caseSpecimenUpdateModels: caseSpecimenUpdateModels,
      };
      return data;
    },
    hasAtLeastOneSpecimenHaveTissueRemain() {
      return this.formData.caseSpecimens.find((caseSpecimen) => caseSpecimen.tissueRemaining === true);
    },
    additionalData() {
      if (this.dataEdit.status === CASE_STATUS.REPORTED && this.hasAtLeastOneSpecimenHaveTissueRemain()) {
        return {
          tissueDiscarded: this.formData.tissueDiscarded,
          tissueDiscardedDate: this.formData.tissueDiscardedDate
            ? convertFromDateTimezoneToIsoString(this.formData.tissueDiscardedDate)
            : null,
        };
      }
      return {};
    },
    onSaveSpecimens() {
      const Err = !this.isPathView
        ? isInValidateBlockAndSlide(this, buildCaseSpecimenModel(this.formData.caseSpecimens), this.dataEdit.status)
        : false;
      this.$refs.specimenDetailForm.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          if (!Err) {
            const isChanged = isSpecimenTypeChanged(this.formData.caseSpecimens, this.originalCaseSpecimens);
            if (isChanged && this.isChangePathologist) {
              this.$refs.confirmModal.open();
              // set status
              this.setIsInformedSpecimenTypeChanged({
                caseId: this.dataEdit.caseId,
                isChanged: true,
              });
              return;
            }
            await this.onSave();
            this.isProcessing = false;
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    async onSave(overWrite = false) {
      try {
        const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
        const dataForm = this.specimenDetailData();
        const res = await caseFormService.updateSpecimenDetail(this.dataEdit.caseId, {
          ...dataForm,
          rowVersion: overWrite ? null : rowVersion,
        });
        this.$onAfterSaveHandler({
          res,
          dataEdit: this.dataEdit,
        });

        if (
          res.data &&
          res.data.rowVersion &&
          this.hasAtLeastOneSpecimenHaveTissueRemain() &&
          this.dataEdit?.status === CASE_STATUS.REPORTED
        ) {
          // save discard tissue after
          const tissueRes = await caseFormService.discardTissueCase(this.dataEdit.caseId, {
            discardTissue: this.formData.tissueDiscarded || false,
            date:
              this.formData.tissueDiscarded && this.formData.tissueDiscardedDate
                ? convertFromDateTimezoneToIsoString(this.formData.tissueDiscardedDate)
                : null,
            rowVersion: null,
          });
          this.$onAfterSaveHandler(
            {
              res: tissueRes,
              dataEdit: this.dataEdit,
            },
            null,
            false,
          );
        }
        if (this.isChangePathologist && !this.caseData.pathologistId) {
          this.$alertError(this.$t(`pages/case/CaseManagement/component/specimen/pathologistEmpty`));
        }
        this.reloadData(this.dataEdit).then(() => {
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK, {
              data: this.formData,
            }),
          );
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_ENTITY_SLIDE, {
              data: this.formData,
            }),
          );
        });
      } catch (errors) {
        this.$alertError(errors);
      } finally {
        this.isProcessing = false;
      }
    },
    // TODO : consolidate logic in case specimen block later.
    async onSaveChangesSpecimenTypeInForm() {
      this.onSave();
    },
    onCancelChangeSpecimenType() {
      this.reloadData(this.dataEdit);
    },
    async reloadData(dataEdit) {
      const { data } = await caseFormService.findOne(dataEdit.caseId);
      if (data) {
        this.fetchData(data);
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
    },
    getSlideList(data) {
      let slide = [];
      data.forEach((blockFieldItem) => {
        slide.push(blockFieldItem.fieldItemName);
      });
      return slide.join(', ');
    },
    onConfirmActionInSlideBlock(val) {
      if (val == ACTION_IN_SLIDE_BLOCK.ADD_BLOCK) {
        this.addNewBlock(this.dataAddNewBlock);
      }
      if (val == ACTION_IN_SLIDE_BLOCK.DELETE_BLOCK) {
        this.removeBlock(this.dataDelBlock);
      }
    },
    onCancelActionInSlideBlock() {
      this.slideIdsRemoved = [];
    },

    specimenTypeListData(list, ignoreValue) {
      let arr = [];
      const newList = list.reduce((i, value) => {
        if (ignoreValue) {
          let itemSeleted = value.items?.filter((child) => child.fieldItemId === ignoreValue.fieldItemId);
          if (itemSeleted[0]) {
            itemSeleted[0].isHide = false;
          }
        }
        const listItem = {
          ...value,
          items: value.items?.filter((child) => !child.isHide),
        };
        arr.push(listItem);
        return arr;
      }, []);
      return newList;
    },

    validateSpecimenTypeOption() {
      if (this.formData.isSameSpecimenType) {
        const sameSpecimenTypeId = this.formData.sameSpecimenTypeId ? this.formData.sameSpecimenTypeId.fieldItemId : '';
        const isValidOption = this.specimenTypeOptions
          .filter((item) => !item.isHide)
          .find((item) => item.fieldItemId === sameSpecimenTypeId);
        if (!isValidOption) {
          // change data
          this.formData.sameSpecimenTypeId = null;
        }
      } else {
        this.formData.caseSpecimens.map((specimen, specimenIndex) => {
          if (specimen.specimenTypeId) {
            // is number or object
            const specimenTypeId = specimen.specimenTypeId.fieldItemId
              ? specimen.specimenTypeId.fieldItemId
              : specimen.specimenTypeId;
            const isValidOption = this.specimenTypeOptions
              .filter((item) => !item.isHide)
              .find((item) => item.fieldItemId === specimenTypeId);
            if (!isValidOption) {
              // change data
              this.formData.caseSpecimens[specimenIndex].specimenTypeId = null;
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.specimen-collapse {
  padding: 0 10px 10px;
}
.specimen-detail-collapse {
  .md-collapse-content {
    padding: 10px 0 0 10px;
  }
  .no-of-specimen {
    max-width: 60px;
  }
  .p-specimen {
    border: 1px solid #ddd;
    margin: 20px 10px 20px 0;
    .md-collapse-label {
      padding: 10px 10px 10px 10px;
      &:after {
        bottom: 0;
      }
      .md-collapse-title {
        color: #000 !important;
        .md-icon {
          top: 10px;
        }
      }
    }
  }
  .custom-tbl {
    margin-right: 10px;
    .v-select {
      width: 200px;
    }
    &.md-table table {
      overflow: initial !important;
    }
    &.md-table.md-theme-default .md-table-content {
      overflow: initial;
    }
  }
}
.same-specimen-type {
  padding-top: 20px;
}
.tissue-remaining {
  float: right;
  margin-top: 25%;
  margin-right: 20%;
  @media only screen and (max-width: 959px) {
    margin-top: 0;
    float: none;
  }
}
</style>
