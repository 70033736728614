<template>
  <div class="peding-case-lab-list">
    <lims-collapse-custom-title
      :wrapperClass="'case-collapse double-reporting-collapse'"
      :collapse="['']"
      icon="keyboard_arrow_down"
      :is-collapsed="totalItem == 0 || isCollapsed"
      :expand-collapse-block-name="'UnReportedCasesCollapse'"
    >
      <template slot="collapseTitle">
        <div class="collapse-title-custom">
          <div class="left-side-collapse">
            {{ $t('pages/Case/CaseManagement/List/UnreportedCasesList.collapseTitle') }}
            <lims-tooltip
              class="title-infor"
              :content="$t('pages/Case/CaseManagement/List/UnreportedCasesList.collapseTitleTooltip')"
            ></lims-tooltip>
          </div>
          <div class="right-side-collapse">{{ totalItem }} cases</div>
        </div>
      </template>
      <template slot="md-collapse-pane-1">
        <modal-bulk-action-commom
          ref="bulkActionCommonModal"
          :bulkActionSelect="bulkAction"
          :compatibleCase="compatibleCase"
          :notCompatibleCase="notCompatibleCase"
          @onCompletedAction="clearSelectedData"
        ></modal-bulk-action-commom>
        <modal-bulk-action-problem
          ref="bulkActionProblemModal"
          :bulkActionSelect="bulkAction"
          :compatibleCase="compatibleCase"
          :notCompatibleCase="notCompatibleCase"
          @onContinue="onContinue"
        ></modal-bulk-action-problem>
        <modal-choose-version-send-email
          ref="chooseVersionSendEmailModal"
          @onSelectedVersion="onSelectedVersion"
        ></modal-choose-version-send-email>
        <modal-send-email ref="sendEmailModal"></modal-send-email>
        <div v-if="caseList && caseList.length > 0" class="toolbar-row select-action">
          <v-select
            class="action-dropdown"
            v-model="bulkAction"
            :options="bulkActionHasPermission"
            :reduce="(item) => item.value"
            :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
          >
            <template #option="{ label }">{{ $t(label) }}</template>
            <template #selected-option="{ label }">{{ $t(label) }}</template>
          </v-select>
          <md-button
            @click="onApply"
            class="apply-btn"
            :disabled="isBulkActionApplyDisabled || isProcessingBulkAction"
            >{{ $t('global/button/button.apply') }}</md-button
          >
        </div>
        <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
          <div class="table-fix-header">
            <thead>
              <md-table-row>
                <md-table-head class="checkbox-column">
                  <md-checkbox v-model="selectAll" :indeterminate="isIndeterminate"></md-checkbox>
                </md-table-head>
                <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
                  <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
                </md-table-head>
              </md-table-row>
            </thead>
            <tbody>
              <md-table-row
                v-for="(item, index) in caseList"
                :key="`case-${index}`"
                :class="checkRowHighlight(item, true)"
              >
                <md-table-cell class="checkbox-column">
                  <md-checkbox
                    v-model="selectRow"
                    @change="$onSelectItem(selectRow, item.caseId)"
                    :value="item.caseId"
                    class="lims-checkbox"
                  ></md-checkbox>
                </md-table-cell>
                <md-table-cell class="caseRef-labRef-column">
                  {{ item.caseReference }}
                  <md-button v-if="item.caseIssues.length > 0" class="md-just-icon md-warning md-simple">
                    <issue-information :case-issues="item.caseIssues"></issue-information>
                  </md-button>
                  <br />
                  {{ item.labReference }}
                </md-table-cell>
                <md-table-cell>{{ item.patientFullname }}</md-table-cell>
                <md-table-cell>{{ item.clinicName }}<br />{{ item.clinicianName }}</md-table-cell>
                <md-table-cell>{{ item.pathologistName }}</md-table-cell>
                <md-table-cell>{{ item.specimenName }}</md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.procedureDate ? formatDateTimeGlobalNotIncludeTime(item.procedureDate, true) : '' }}
                </md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.reportedDate ? formatDateTimeGlobalNotIncludeTime(item.reportedDate) : '' }}
                </md-table-cell>
                <md-table-cell class="ALW-column">
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.PARTIALLY_COMPLETED" class="status-invited"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="SO-column">
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled">adjust</md-icon>
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="status-column">
                  <md-icon v-if="item.statusId == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                    >hourglass_bottom</md-icon
                  >
                  <md-icon v-if="item.statusId == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
                </md-table-cell>
                <md-table-cell class="actions-column">
                  <div v-if="isShowSearchIcon(item) && isReportRetrieve()" style="width: 25px">
                    <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view-report', item)">
                      <md-icon>search</md-icon>
                    </md-button>
                  </div>
                  <div v-if="isShowReportIcon(item)" style="width: 25px">
                    <md-button
                      class="md-just-icon md-info md-simple"
                      @click.stop="onExportAsPdf(item)"
                      :disabled="isProcessing"
                    >
                      <img class="pdf-img" :src="require(`@/assets/images/pdf-file.png`)" />
                    </md-button>
                  </div>
                  <div v-if="isShowSendEmail(item)" style="width: 25px">
                    <md-button
                      class="md-just-icon md-info md-simple"
                      @click.stop="onVerifySendEmail(item.caseId)"
                      :disabled="isProcessingSendEmail"
                    >
                      <md-icon>forward_to_inbox</md-icon>
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </tbody>
          </div>
        </md-table>
        <slot name="pagination"></slot>
      </template>
    </lims-collapse-custom-title>
  </div>
</template>
<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';
import IssueInformation from '@/pages/Case/CaseManagement/Components/IssueInformation';
import ModalBulkActionCommom from '@/components/Lims/modals/ModalBulkActionCommom';
import ModalBulkActionProblem from '@/components/Lims/modals/ModalBulkActionProblem';
import ModalChooseVersionSendEmail from '@/components/Lims/modals/ModalChooseVersionSendEmail';
import { BULK_ACTION, CASE_COLLAPSE_NAME, CASE_LIST_TYPE_ENUM } from '@/core/constants';
import ReportMixins from '@/pages/Case/CaseManagement/Report/report.mixins';
import { MasterCheckBoxMixins } from '@/core/mixins';
import { LIST_NAMES } from '@/store/modules/item-list';
import { mapGetters } from 'vuex';

export default {
  name: 'UnreportedCasesList',
  mixins: [CaseListMixin, ReportMixins, MasterCheckBoxMixins],
  components: {
    ModalBulkActionProblem,
    ModalBulkActionCommom,
    IssueInformation,
    ModalChooseVersionSendEmail,
  },
  props: {
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
    totalItem: {
      required: false,
    },
  },
  data() {
    return {
      caseCollapseName: CASE_COLLAPSE_NAME.UN_REPORTED_CASE,
      listName: LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST,
      listTypeEnum: CASE_LIST_TYPE_ENUM.UnreportedCases,
      selectRow: [],
      bulkAction: '',
      compatibleCase: [],
      notCompatibleCase: {},
      bulkActionList: [
        {
          value: BULK_ACTION.MarkAsRead,
          label: 'entities/case/bulkActionName.MarkAsRead',
          permission: ['Case_CaseReport_Retrieve'],
        },
        {
          value: BULK_ACTION.MarkAsUnRead,
          label: 'entities/case/bulkActionName.MarkAsUnRead',
          permission: ['Case_CaseReport_Retrieve'],
        },
      ],
      colTabl: [
        {
          name: ['pages.case.caseManagement/list.caseRef', 'pages.case.caseManagement/list.labRef'],
          key: 'caseReference',
          sort: false,
          class: 'caseRef-labRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: ['pages.case.caseManagement/list.clinic', 'pages.case.caseManagement/list.clinician'],
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimenType',
          key: 'specimenName',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages.case.caseManagement/list.Procedure',
          key: 'procedureDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.reported',
          key: 'reportedDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.ALW',
          key: 'caseAlwStatus',
          sort: false,
          class: 'ALW-column',
        },
        {
          name: 'pages.case.caseManagement/list.SO',
          key: 'caseSoStatus',
          sort: false,
          class: 'SO-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
    };
  },
  watch: {
    [LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST]: {
      deep: true,
      handler: function (listState) {
        this.$toggleSelectAllCheckbox(listState);
      },
    },
  },
  computed: {
    ...mapGetters('itemList', [LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST]),
    bulkActionHasPermission() {
      let listHasPermission = [];
      this.bulkActionList.forEach((item) => {
        if (this.$isAuthorized(item.permission)) {
          listHasPermission.push(item);
        }
      });
      return listHasPermission;
    },
  },
  methods: {},
};
</script>
<style lang="scss"></style>
