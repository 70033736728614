/* eslint-disable security/detect-object-injection */
import moment from 'moment';
import { cloneDeep, isBoolean, isDate, isEmpty, isEqual, isNumber, orderBy, pick } from 'lodash';
import config from '@/services/config';
import DOMPurify from 'dompurify';

export const toLower = (text) => {
  return text.toString().toLowerCase();
};

export const pickFields = (obj, fields) => {
  return pick(obj, fields);
};

export const ucFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sortDropDown = (arr, field = 'label') => {
  return orderBy(arr, [(item) => item[field].toLowerCase()], ['asc']);
};

export const isLoggedIn = (authState) => {
  try {
    const { isLoggedIn } = authState;

    return isLoggedIn;
  } catch (error) {
    return false;
  }
};
/*
 * Transform API Errors to form errors format
 * From: [{field:'fieldName', code:'code'}, ...]
 * To: {fieldName: code, ...}
 */
export const convertToFormErrors = (errors) => {
  let errorObject = {};
  errors.map((error) => {
    errorObject[error.field] = error.code;
  });
  return errorObject;
};

export const errorMessage = (errorCode, errorField) => {
  if (errorCode === 'notEmpty') return errorField + 'can not be empty';
  if (errorCode === 'notValid') return errorField + ' is invalid';
  if (errorCode === 'unique') return errorField + ' is duplicated';
};

// export const formatActionMessageDate = (date) => {
//   return moment.utc(date).local().format('dddd MMMM DD, YYYY HH:mm:ss');
// };

// export const formatLastUpdatedDate = (date) => {
//   return moment.utc(date).local().format('DD-MMM-YYYY HH:mm');
// };
export const DateTimeHelper = {
  toUTC(dateString) {
    return moment.utc(dateString);
  },
  toOffsetDate(utcDate, offsetString) {
    let offset = offsetString;
    if (!offset.includes('-') && !offset.includes('+')) {
      offset = `+${offsetString}`;
    }
    return utcDate.utcOffset(offset);
  },
  mappingDateTimeFormat(str) {
    let newStr = str;
    // dddd, ddd : keep
    newStr = newStr.replaceAll('dddd', '{VVVV}');
    newStr = newStr.replaceAll('ddd', '{VVV}');
    // dd: become DD
    newStr = newStr.replaceAll('dd', 'DD');
    // revert dddd, ddd
    newStr = newStr.replaceAll('{VVVV}', 'dddd');
    newStr = newStr.replaceAll('{VVV}', 'ddd');

    // tt: become A => AM/PM
    newStr = newStr.replaceAll('tt', 'A');

    // fffffff: become SSSSSSS
    newStr = newStr.replaceAll('f', 'S');

    // K : become ZZ
    newStr = newStr.replaceAll('K', 'Z');

    // ’,‘ : trim
    newStr = newStr.replaceAll('’', '');
    newStr = newStr.replaceAll('‘', '');
    return newStr;
  },
  formatDateTimeGlobal(datetime, { timezoneValue, dateTimeAppFormat }) {
    const dateObject = DateTimeHelper.toOffsetDate(
      DateTimeHelper.toUTC(datetime),
      timezoneValue || config.timezoneValue,
    );
    const fmtString = DateTimeHelper.mappingDateTimeFormat(dateTimeAppFormat || config.dateTimeAppFormat);
    return dateObject.format(fmtString);
  },
  getTimezoneString() {
    let date = new Date();
    let dateString = date.toTimeString();
    let index = dateString.indexOf('GMT+');
    return dateString.substring(index + 3, index + 6) + ':' + dateString.substring(index + 6, index + 8);
  },
};

export const currentTime = () => {
  return moment().format('HH:mm');
};

export const formatDateWithoutTime = (date) => {
  return moment.utc(date).local().format('DD/MM/yyyy');
};

export const formatDateAndTime = (date) => {
  return moment.utc(date).local().format('DD/MM/yyyy HH:mm:ss');
};

export const formatCurrentDateAndTime = (date) => {
  return moment.utc(date).local().format();
};

export const formatDay = (date) => {
  return moment(date).format();
};

export const currentDay = () => {
  return moment().startOf('day').format();
};

export const startDay = (date) => {
  return moment(date).startOf('day').format();
};

export const endDay = (date) => {
  return moment(date).endOf('day').format();
};

export const setTimeUTCToDatePicker = (date) => {
  return moment.utc(date).local().format('yyyy-MM-DD');
};

export const convertDateTimeToUTCFormat = (datetime = null) => {
  const datetimeInput = datetime || new Date();
  return new Date(moment.utc(datetimeInput).local().format('yyyy-MM-DD HH:mm:ss'));
};
export const convertDateToUTCFormat = (date = null) => {
  const datetimeInput = date || new Date();
  return new Date(moment.utc(datetimeInput).local().format('yyyy-MM-DD'));
};

export const fromISOToCurrentTimezone = (isoString) => {
  const datetimeValues = isoString.split('T');
  return new Date(moment(datetimeValues[0]));
};

export const convertFromDateTimezoneToIsoString = (datetime) => {
  // format YYYY-MM-DDT00:00:00.000Z
  if (datetime instanceof Date) {
    const year = datetime.getFullYear();
    let month = (datetime.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    let date = datetime.getDate().toString();
    if (date.length < 2) {
      date = `0${date}`;
    }
    return `${year}-${month}-${date}T00:00:00.000Z`;
  }
  return datetime;
};

export const getOnlyDateTimeFromIsoString = (datetime) => {
  // format YYYY-MM-DDT00:00:00.000Z
  if (datetime instanceof Date) {
    let hour = datetime.getHours().toString();
    if (hour.length < 2) {
      hour = `0${hour}`;
    }
    let minute = datetime.getMinutes().toString();
    if (minute.length < 2) {
      minute = `0${minute}`;
    }
    let second = datetime.getSeconds().toString();
    if (second.length < 2) {
      second = `0${second}`;
    }
    return `${hour}:${minute}:${second}`;
  }
  return datetime;
};

const COOKIE_PREFIX = 'ldpath';

export const setCookie = (cname, cvalue, exdays) => {
  if (exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();

    document.cookie = `${COOKIE_PREFIX}_${cname}` + '=' + cvalue + ';' + expires + ';path=/';
  } else {
    document.cookie = `${COOKIE_PREFIX}_${cname}` + '=' + cvalue + ';path=/';
  }
};

export const getCookie = (cname) => {
  let name = `${COOKIE_PREFIX}_${cname}` + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (cname) => {
  let name = `${COOKIE_PREFIX}_${cname}` + '=';
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const isRestrictedArea = ({ permissions, userPermissions }) => {
  if (!permissions) return true;
  // TO CHECK: userPermissions must intersect with permissions at least 1
  return permissions.filter((x) => userPermissions.includes(x)).length;
};

export const partiallyHideEmail = (email) => {
  if (email) {
    return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  } else {
    return '';
  }
};

export const partiallyHidePhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    let first3 = phoneNumber.substring(0, 3);
    let last2 = phoneNumber.substring(phoneNumber.length - 2);
    let mark = phoneNumber.substring(3, phoneNumber.length - 2).replace(/\d/g, 'X');

    return first3 + mark + last2;
  } else {
    return '';
  }
};

// There are several data like this
// option list from API : [{id:'',text:''}], [{laboratoryId:''}], [{specimenId:''}]
// select options receive input {label, value}
// select options output for selection {label,value}
// form data submit [id1,id2,id3]

// So we will consolidate those format into 1 format => id
// The options will be used to display the approriate label
// formData.selectedOptions = [{entityId:'', customAttributes}]
// availableOptions = [{value, label}]
// userModel.entityList = [{entityId:'',...}]
// selectOptions = availableOptions - selectedOptions

export const filterSelectedOptions = (availableOptions, selectedOptions, key = 'id') => {
  return availableOptions.filter((item) => !selectedOptions.includes(item[key]));
};

export const convertEntityListToSelectedOptions = (entityList, key = 'id') => {
  return entityList.map((item) => item[key]);
};

export const convertArrayObjectToHashMap = (arr, key = 'fieldItemId', value = 'FieldItemName') => {
  let hashmap = {};
  arr.map((item) => {
    hashmap[item[key]] = item[value];
  });
  return hashmap;
};

export const retrieveOptionByName = (options, keyValue, keyField = 'fieldItemId', keyName = 'fieldItemName') => {
  return options.find((item) => item[keyField] === keyValue)[keyName];
};
/*
        "result": [
            {
                "featureGroupCodeName": "Accounts",
                "featureGroupId": 1,
                "features": [
                    {
                        "featurePermissionId": 1,
                        "featureCodeName": "Accounts_RoleManagement",
                        "permissionTypes": [
                            {
                                "permissionType": 3,
                                "permissionName": "Update", //Retrieve || Create || Delete || Others
                                "hasAccess": true
                            },
                          ]
                      }
                  ]
*/
export const RolePermissionsHelper = {
  buildFeaturePermissions: ({ permissionTypes }) => {
    const obj = {};
    permissionTypes.map(({ permissionType, hasAccess }) => {
      obj[permissionType] = hasAccess;
    });
    return obj;
  },
  buildPermissionsModel: (featurePermissions) => {
    const permissionModel = {};
    featurePermissions.map((group) => {
      const { features } = group;
      features.map(({ featurePermissionId, permissionTypes }) => {
        permissionModel[featurePermissionId] = RolePermissionsHelper.buildFeaturePermissions({ permissionTypes });
      });
    });
    return permissionModel;
  },
  isPermissionTypeExists: ({ feature, permissionType }) => {
    const { permissionTypes } = feature;
    for (let i = 0; i < permissionTypes.length; i++) {
      if (permissionType === permissionTypes[i].permissionType) {
        return true;
      }
    }
    return false;
  },
  mappingDataPermissionByFeature: ({ feature, formDataPermissions }) => {
    return feature.permissionTypes.map((perm) => {
      return {
        ...perm,
        hasAccess: formDataPermissions[feature.featurePermissionId][perm.permissionType],
      };
    });
  },
  mappingDataPermissionForUpdate: ({ featurePermissions, formDataPermissions }) => {
    let dataPermission = cloneDeep(featurePermissions);
    dataPermission = dataPermission.map((ftg) => {
      return {
        featureGroupId: ftg.featureGroupId,
        features: ftg.features.map((feature) => {
          const permissionTypes = RolePermissionsHelper.mappingDataPermissionByFeature({
            feature,
            formDataPermissions,
          });
          return {
            featurePermissionId: feature.featurePermissionId,
            permissionTypes,
          };
        }),
      };
    });
    return dataPermission;
  },
  isTheRoleBeingCreatedHasNoPermission: ({ permissionModel }) => {
    // {
    //  1: { 1: false, 2: false, 3: false, 4: false },
    //  2: { 1: false, 2: false, 3: false, 4: false },
    // };
    const features = Object.keys(permissionModel);
    for (let i = 0; i < features.length; i++) {
      const values = Object.values(permissionModel[features[i]]);
      // one of these features has active permissions
      if (values.filter((val) => val).length > 0) {
        return false;
      }
    }
    return true;
  },
  permissionHierarchyFilter: ({ permissions, labels, featurePermissionId, permissionType, hasAccess }) => {
    const sortedLabels = [];
    for (let i = 0; i < labels.length; i++) {
      sortedLabels.push(labels[i].permissionType.toString());
    }

    const featurePermissions = permissions[featurePermissionId];
    // find all higher permission
    const permissionIndex = sortedLabels.indexOf(permissionType.toString());
    const keys = Object.keys(featurePermissions);

    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      const index = sortedLabels.indexOf(key);
      if (hasAccess) {
        // set all lower permission to true
        if (index < permissionIndex) {
          featurePermissions[key] = hasAccess; // true
        }
      } else {
        // set all higher permission to false
        if (index > permissionIndex) {
          featurePermissions[key] = hasAccess; // false
        }
      }
    }
    permissions[featurePermissionId] = featurePermissions;
    return;
  },
  // retrieveUniquePermissionKeys(permissions) {
  //   //[featurePermissionId][permissionName]
  //   const keys = [];
  //   Object.values(permissions).map((perms) => {
  //     Object.keys(perms).map((perm) => {
  //       if (!keys.includes(perm)) {
  //         keys.push(perm);
  //       }
  //     });
  //   });
  //   return keys.sort();
  // },
};

export const FilterDataOptions = (options, selectedValues = []) => {
  if (selectedValues) {
    return options.filter((option) => {
      return selectedValues.includes((option.value || '').toString());
    });
  }
  return [];
};

export const isPerfectEmpty = (value) => {
  if (isNumber(value)) {
    return false;
  }
  if (isBoolean(value)) {
    return false;
  }
  if (isDate(value)) {
    return false;
  }
  // otherwise use lodash
  return isEmpty(value);
};

export const userTypeIdToComponent = (USER_TYPES, userTypeId) => {
  if (userTypeId === USER_TYPES.Administrator) return 'administrator';
  if (userTypeId === USER_TYPES.ClinicAssociate) return 'clinicAssociate';
  if (userTypeId === USER_TYPES.Clinician) return 'clinician';
  if (userTypeId === USER_TYPES.ClinicianAssociate) return 'clinicianAssociate';
  if (userTypeId === USER_TYPES.LabTechnician) return 'labTechnician';
  if (userTypeId === USER_TYPES.Pathologist) return 'pathologist';
  return false;
};

export const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const saveSession = (key, value) => {
  // use cookie session to shared between tabs
  setCookie(`${key}`, JSON.stringify(value));
  // sessionStorage.setItem(`${SESSION_PREFIX}_${key}`, JSON.stringify(value));
};

export const deleteSession = (key) => {
  // use cookie session to shared between tabs
  deleteCookie(`${key}`);
  // sessionStorage.removeItem(`${SESSION_PREFIX}_${key}`);
};

export const getSession = (key) => {
  // use cookie session to shared between tabs
  const value = getCookie(`${key}`);
  // const value = sessionStorage.getItem(`${SESSION_PREFIX}_${key}`);
  if (value) {
    return JSON.parse(value);
  }
  return false;
};

// internal helpers
const swapOrder = (a, b) => {
  let tmp = a.order;
  a.order = b.order;
  b.order = tmp;
};

const arrageOrderASC = (arr, oldIndex, newIndex) => {
  // swap index for range inside oldIndex,newIndex
  for (let i = newIndex - 1; i > oldIndex; i--) {
    swapOrder(arr[i], arr[i - 1]);
  }
  // final step
  swapOrder(arr[oldIndex], arr[newIndex]);
};
const arrageOrderDESC = (arr, oldIndex, newIndex) => {
  // swap index for range inside oldIndex,newIndex
  for (let i = newIndex + 1; i < oldIndex; i++) {
    swapOrder(arr[i], arr[i + 1]);
  }
  // final step
  swapOrder(arr[oldIndex], arr[newIndex]);
};
export const arrangeOrder = (arr, oldIndex, newIndex) => {
  return oldIndex < newIndex ? arrageOrderASC(arr, oldIndex, newIndex) : arrageOrderDESC(arr, oldIndex, newIndex);
};

export const nextDate = (today) => {
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const isDeepEqual = (objA, objB) => {
  return isEqual(objA, objB);
};

export const markDuplicatedObjects = (objects, keyName) => {
  let transformedObjects = [];

  objects.map((f) => {
    let transformedObject = f;
    Reflect.set(f, 'duplicated', false);
    if (transformedObjects.find((file) => f[keyName] === file[keyName])) {
      transformedObject.duplicated = true;
    }
    transformedObjects.push(transformedObject);
  });
  return transformedObjects;
};

export const StringHelper = {
  /**
   *
   * @param string maxNumber
   * @param string valueType : available values [char,number,both]
   */
  generateBlockNameSections(valueType = 'both', maxNumber = 40) {
    const values = [];
    if (valueType === 'both' || valueType === 'char') {
      for (let i = 0; i < 26; i++) {
        const c = String.fromCharCode(65 + i);
        values.push({ id: c, text: c });
      }
    }
    if (valueType === 'both' || valueType === 'number') {
      for (let i = 1; i <= maxNumber; i++) {
        values.push({ id: `${i}`, text: `${i}` });
      }
    }
    return values;
  },
};

export const extractFileNameFromResponseHeader = (headers) => {
  // Output: Slide_P1000_path.csv
  const header = headers['content-disposition'];
  const pattern = /filename=([^;]+)/i;
  const match = pattern.exec(header);
  if (match) {
    const filename = match[1];
    return filename.replace(/[/"]+/g, '');
  } else {
    return null;
  }
};

export const getSelectedValueInSpecimenList = (specimenList, fieldName) => {
  const selectedValue = [];

  if (specimenList) {
    specimenList.forEach((caseSpecimens) => {
      caseSpecimens.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
        caseSpecimenBlock.blockFieldItems.forEach((blockFieldItem) => {
          if (blockFieldItem.fieldName === fieldName) {
            selectedValue.push(blockFieldItem.fieldItemId);
          }
        });
      });
    });
  }

  return selectedValue;
};

export const filterDropdownListByHiddenField = (dropdownList) => {
  return dropdownList && dropdownList.length > 0 ? dropdownList.filter((item) => item.isHide === false) : [];
};

export const filterDropdownByHiddenFieldListSlide = (dropdownList, specimenList, fieldName) => {
  const selectedValue = getSelectedValueInSpecimenList(specimenList, fieldName);
  return dropdownList.filter((item) => item.isHide === false || selectedValue.includes(item.fieldItemId));
};

export const getSelectedValueInDiagnosisList = (diagnosisList, fieldName) => {
  const selectedValue = [];

  if (diagnosisList) {
    diagnosisList.forEach((caseSpecimens) => {
      caseSpecimens.caseSpecimenDiagnosis.forEach((caseSpecimenDiagnosis) => {
        if (caseSpecimenDiagnosis.fieldName === fieldName) {
          selectedValue.push(caseSpecimenDiagnosis.fieldItemId);
        }
      });
    });
  }

  return selectedValue;
};

export const filterDropdownListByHiddenFieldDiagnosis = (dropdownList, diagnosisList, fieldName) => {
  const selectedValue = getSelectedValueInDiagnosisList(diagnosisList, fieldName);
  return dropdownList.filter((item) => item.isHide === false || selectedValue.includes(item.fieldItemId));
};

export const checkIfArrayIsUnique = (myArray) => {
  return myArray.length === new Set(myArray).size;
};

export function isValidLink(url) {
  return /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(url);
}

/**
 * Validates and sanitizes a URL to ensure it is safe for use in web contexts.
 * This function checks if the URL is well-formed and sanitizes inputs to prevent XSS attacks.
 * Additionally, it returns false if sanitization changes the URL, indicating potential XSS attempts.
 * @param {string} url - The URL to validate and sanitize.
 * @param {Array<string>} allowedSchemes - allowed schemes
 * @return {boolean} - Returns true if the URL is safe, false otherwise.
 */

export const isValidUrl = (url, allowedSchemes = ['http:', 'https:']) => {
  if (!isValidLink(url)) {
    return false;
  }
  // Sanitize the URL with DOMPurify.
  const sanitizedUrl = DOMPurify.sanitize(url, { ALLOWED_URI_REGEXP: /^(https?|ftp|mailto|tel):/i });
  if (url !== sanitizedUrl) {
    return false;
  }

  try {
    const urlObject = new URL(sanitizedUrl);

    // Check the scheme
    if (!allowedSchemes.includes(urlObject.protocol)) {
      return false;
    }

    // Check each query parameter for unsafe content, especially `javascript:` URIs.
    for (const [, value] of urlObject.searchParams) {
      if (value.includes('javascript:')) {
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const advancedSearchQueryMarshalling = (originalPayload) => {
  const payload = cloneDeep(originalPayload);
  // 43671: support to set searchQuery.Filter.AdvanceSearch parameter to be empty/null when posting to Api
  if (
    payload.searchQuery &&
    payload.searchQuery.filters &&
    payload.searchQuery.filters.advanceSearch &&
    Reflect.ownKeys(payload.searchQuery.filters.advanceSearch).length === 0
  ) {
    payload.searchQuery.filters.advanceSearch = null;
  }
  return payload;
};
