import {
  APP_EVENTS,
  CASE_FORM_BLOCK,
  CASE_STATUS,
  newAppEvent,
  PATHOLOGIST_TYPE_ID,
  USER_TYPE,
} from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('caseForm', ['getRowVersionWithCaseId', 'getRowVersionByCaseId', 'fieldsToBeSetNull']),
    ...mapGetters('caseData', ['caseData']),
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val, prev) {
        if (val && prev) {
          this.setBlockChanged(this.blockId);
        }
      },
    },
    'formData.caseSpecimenDiagnosis': {
      deep: true,
      handler: function (val, prev) {
        if (val && prev) {
          this.setBlockChanged(this.blockId);
        }
      },
    },
  },
  data() {
    return {
      blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS,
    };
  },
  methods: {
    ...mapActions('caseForm', [
      'setBlockChanged',
      'setBlockUnchangedBlock',
      'setCaseIdWithRowVersion',
      'setRowVersionByCaseId',
      'resetFieldsToBeSetNull',
      'unMarkFieldToBeSetNull',
    ]),
    ...mapActions('caseData', ['appendCaseData']),
    ...mapActions('app/event', ['addEvent']),
    getLastestRowVersionWithCaseId() {
      return this.getRowVersionWithCaseId;
    },
    $onUpdateRowVersion({ caseId, rowVersion }) {
      this.setRowVersionByCaseId({
        caseId,
        rowVersion,
      });
    },
    $isEntityChangedShowWarning(dataEdit, isWarningBlock = true) {
      const isEditmode = this.formMode === this.EDIT_MODE;
      if (!isEditmode) {
        return false;
      }
      // when
      // is admin
      // caseStatus is not pre-lab or lab
      // edit mode
      // when lab/clinician/clinic/specimentype changed
      const userType = this.$store.getters['auth/userType'];
      const isAdministrator = userType === this.USER_TYPES()?.Administrator;

      const isFromPathStatus = ![CASE_STATUS.LAB, CASE_STATUS.PRE_LAB].includes(dataEdit.status);
      return isAdministrator && isWarningBlock && isFromPathStatus;
    },
    $onAfterSaveHandler({ res, dataEdit }, callback = null, showAlert = true) {
      this.userType = this.$store.getters['auth/userType'];
      if (res.error) {
        if (res.error === 'UpdateConcurrency') {
          return this.$refs.concurrencyIssueModal.open();
        }
        if (res.error === 'InvalidClinicOrLaboratory') {
          return this.$alertError(this.$t('pages/case/CaseManagement/CaseForm/ClinicAndLab/update.error'));
        }
        return this.$alertError(res.error);
      } else {
        this.setRowVersionByCaseId({
          caseId: dataEdit.caseId,
          rowVersion: res.data.rowVersion,
        });
        if (showAlert) {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/CaseForm/update.success', {
              caseReference: dataEdit.caseReference,
            }),
          );
        }
        if (this.userType === USER_TYPE.Pathologist && this.formData && !this.formData.isPauseCountReturnToPull) {
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
              onHideCountDown: true,
            }),
          );
        }
        const unMarkFields = [];
        if (this.blockId === CASE_FORM_BLOCK.BLOCK_BILLING) {
          dataEdit.workStreamId ? unMarkFields.push(FIELDS_WAS_RESET_TO_NULL.WORKSTREAM) : '';
          // dataEdit.entityBillingId ? unMarkFields.push(FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING) : '';
          unMarkFields.push(FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);
        }
        if (this.blockId === CASE_FORM_BLOCK.BLOCK_PATHOLOGIST) {
          dataEdit.pathologistId || dataEdit.pathologistType === PATHOLOGIST_TYPE_ID.Pull
            ? unMarkFields.push(FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST)
            : '';
        }
        if (this.blockId === CASE_FORM_BLOCK.BLOCK_SPECIMEN) {
          !hasAtleastOneBlockEmpty(dataEdit.caseSpecimens) ? unMarkFields.push(FIELDS_WAS_RESET_TO_NULL.SLIDE) : '';
        }
        if (unMarkFields.length > 0) {
          this.unMarkFieldToBeSetNull(unMarkFields);
        }
        const isWarningBlock = [CASE_FORM_BLOCK.BLOCK_CLINIC_LABORATORY, CASE_FORM_BLOCK.BLOCK_SPECIMEN].includes(
          this.blockId,
        );
        if (this.$isEntityChangedShowWarning(dataEdit, isWarningBlock)) {
          // reload alw block
          if (this.blockId === CASE_FORM_BLOCK.BLOCK_CLINIC_LABORATORY) {
            this.addEvent(
              newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED, {
                laboratoryId: dataEdit.laboratoryId,
                saved: true,
              }),
            );
          }
          // validate empty field
          this.$warningUserForIncompatibleValue();
        }
        this.$resetBlockChanged();
        if (callback) {
          callback();
        }
      }
    },
    async $onConcurrencyIssueContinue(mode) {
      this.$refs.concurrencyIssueModal.close();
      if (mode === 'onDiscard') {
        this.$alertSuccess(
          this.$t('pages/case/CaseManagement/CaseForm/update.success', {
            caseReference: this.dataEdit.caseReference,
          }),
        );
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      } else {
        if (mode === 'onOverwrite') {
          this.onSave(true);
        }
      }
    },
    $resetBlockChanged() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.setBlockUnchangedBlock(this.blockId);
        });
      }, 500);
    },
    $setRowVersionByCaseId({ caseId, rowVersion }) {
      this.setRowVersionByCaseId({
        caseId,
        rowVersion,
      });
    },
    $warningUserForIncompatibleValue() {
      let invalidFields = getSwapEntityInvalidFields(this.fieldsToBeSetNull);
      invalidFields = invalidFields.filter((f) => f !== FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);
      // translate
      if (invalidFields.length > 0) {
        this.$alertError(this.$t('pages/case/CaseManagement/CaseForm/SwapEntity/ValueReset/warning'), 5000);
      }
    },
  },
};

export const getSwapEntityInvalidFields = (fieldsToBeSetNull) => {
  const fieldNames = Object.values(FIELDS_WAS_RESET_TO_NULL);
  const invalidFields = [];
  Reflect.ownKeys(fieldsToBeSetNull).forEach((f) => {
    if (fieldsToBeSetNull[f] && fieldNames.includes(f)) {
      invalidFields.push(f);
    }
  });
  return invalidFields;
};

const hasAtleastOneBlockEmpty = (caseSpecimens) => {
  let result = false;
  caseSpecimens.forEach((spec) => {
    if (spec.caseSpecimenBlocks && spec.caseSpecimenBlocks.length) {
      spec.caseSpecimenBlocks.forEach((block) => {
        if (!block.blockFieldItems || block.blockFieldItems.length === 0) {
          result = true;
          return;
        }
      });
    }
  });
  return result;
};

export const FIELDS_WAS_RESET_TO_NULL = {
  SLIDE: 'slide',
  PATHOLOGIST: 'pathologist',
  WORKSTREAM: 'workstream',
  ENTITY_BILLING: 'entity_billing',
};
