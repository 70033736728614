<template>
  <div>
    <anchor-system :hasSplitContent="true"></anchor-system>
    <lims-block :cardText="false" class="list-page">
      <div class="md-layout" slot="blockContent">
        <modal-no-more-case-to-review
          ref="NoMoreCaseToReviewModal"
          :bulk-diagnosis-mode="true"
        ></modal-no-more-case-to-review>
        <div class="md-layout-item" v-if="query">
          <bulk-diagnosis-list @onChangeItem="onChangeSelectedCase" :caseListItems="items" :next-item-id="nextItemId">
            <md-table-toolbar slot="toolbar">
              <div class="toolbar-row">
                <div class="field-rows-per-page">
                  <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
                </div>
                <div class="field-search">
                  <bulk-diagnosis-filter
                    v-model="query.filters"
                    :defaultValues="defaultValues"
                    :fields="fields"
                    :case-ref-list="caseRefList"
                    @onResetFilters="onResetFilters"
                  />
                </div>
              </div>
            </md-table-toolbar>
          </bulk-diagnosis-list>
          <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
        </div>

        <div id="split-wrapper" class="review-case-form-detail bulkDiagnosis-form-detail">
          <div class="range-custom">
            <md-radio v-model="rangesliderVal" :value="0" class="position-25">
              <md-icon>format_align_left</md-icon>
            </md-radio>
            <md-radio v-model="rangesliderVal" :value="50" class="position-50">
              <md-icon>format_align_center</md-icon>
            </md-radio>
            <md-radio v-model="rangesliderVal" :value="100" class="position-75">
              <md-icon>format_align_right</md-icon>
            </md-radio>
          </div>
          <div class="md-layout lims-form-row">
            <vue-split :elements="['#slotOne', '#slotTwo']" direction="horizontal" :min-size="0" :gutter-size="10">
              <div id="slotOne" :style="{ width: rangesliderVal + '%' }">
                <document-view :data-edit="caseFormResource" @openNewWindow="rangesliderVal = 0"></document-view>
              </div>
              <div id="slotTwo" class="case-form-block" :style="{ width: 100 - rangesliderVal + '%' }">
                <div v-if="caseFormResource" class="case-bulk-diagnosis-detail">
                  <template v-if="caseFormResource.status === CASE_STATUS.PATH && !caseFormResource.isCaseSoResponder">
                    <path-status-path-view
                      :caseFormResource="caseFormResource"
                      :formMode="EDIT_MODE"
                      :is-bulk-diagnosis="true"
                      @updatedCaseInDiagnosisView="onUpdatedCaseInDiagnosisView"
                    ></path-status-path-view>
                  </template>
                  <template
                    v-if="
                      caseFormResource.status === CASE_STATUS.PROVISIONALLY_REPORTED &&
                      !caseFormResource.isCaseSoResponder
                    "
                  >
                    <provisional-reported-status-path-view
                      :caseFormResource="caseFormResource"
                      :formMode="EDIT_MODE"
                      :is-bulk-diagnosis="true"
                      @updatedCaseInDiagnosisView="onUpdatedCaseInDiagnosisView"
                    ></provisional-reported-status-path-view>
                  </template>
                  <template v-if="caseFormResource.isCaseSoResponder">
                    <second-opinion-path-view
                      :caseFormResource="caseFormResource"
                      :formMode="EDIT_MODE"
                    ></second-opinion-path-view>
                  </template>
                </div>
              </div>
            </vue-split>
          </div>
        </div>
      </div>
    </lims-block>
  </div>
</template>
<script>
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins, TabMixins } from '@/core/mixins';
import { bulkDiagnosisQuery } from '@/query';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { caseFormService, caseListService } from '@/services';
import { CASE_STATUS, FORM_MODES } from '@/core/constants';
import dropdownService from '@/services/dropdown.service';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { mapActions } from 'vuex';
import BulkDiagnosisFilter from '@/pages/Case/CaseManagement/BulkDiagnosis/BulkDiagnosisFilter';
import BulkDiagnosisList from '@/pages/Case/CaseManagement/BulkDiagnosis/BulkDiagnosisList';
import ModalNoMoreCaseToReview from '@/components/Lims/modals/ModalNoMoreCaseToReview';
import PathStatusPathView from '@/pages/Case/CaseManagement/Edit/PathStatusPathView';
import ProvisionalReportedStatusPathView from '@/pages/Case/CaseManagement/Edit/ProvisionalReportedStatusPathView';
import SecondOpinionPathView from '@/pages/Case/CaseManagement/Edit/SecondOpinionPathView';
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import { isDeepEqual } from '@/core/helpers';
import AnchorSystem from '@/pages/Case/CaseManagement/Components/AnchorSystem';
import entityService from '@/services/entity.service';

const BULK_DIAGNOSIS_SESSION = 'bulkDiagnosisSession';
const BulkDiagnosisSession = {
  getCaseRefs: (whiteListCaseRefs = []) => {
    const res = { caseRefIds: [], e: null };
    try {
      const caseReviewSession = sessionStorage.getItem(BULK_DIAGNOSIS_SESSION);
      if (caseReviewSession) {
        res.caseRefIds = JSON.parse(sessionStorage.getItem(BULK_DIAGNOSIS_SESSION));
        if (whiteListCaseRefs.length > 0) {
          res.caseRefIds = res.caseRefIds.filter((c) => whiteListCaseRefs.includes(c));
        }
      }
    } catch (error) {
      res.e = error;
    }
    return res;
  },
  removeCaseRef: (caseRefId) => {
    const { caseRefIds, error } = BulkDiagnosisSession.getCaseRefs();
    if (error) {
      return error;
    }
    if (caseRefIds.length > 0) {
      const newCaseRefs = caseRefIds.filter((c) => c !== caseRefId);
      // save to session
      sessionStorage.setItem(BULK_DIAGNOSIS_SESSION, JSON.stringify(newCaseRefs));
    }
    return null;
  },
  noCasesForReview: () => {
    const { caseRefIds } = BulkDiagnosisSession.getCaseRefs();
    return caseRefIds.length === 0;
  },
};

export default {
  mixins: [ConfigMixins, ListWithFilterMixins, TabMixins],
  components: {
    AnchorSystem,
    ModalNoMoreCaseToReview,
    BulkDiagnosisList,
    BulkDiagnosisFilter,
    LimsRowPerPage,
    LimsPagination,
    PathStatusPathView,
    ProvisionalReportedStatusPathView,
    SecondOpinionPathView,
    DocumentView,
  },
  data() {
    return {
      query: null,
      items: [],
      totalItem: null,
      caseRefList: [],
      caseFormResource: null,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      selectedCaseId: null,
      selectedCaseRef: null,
      rangesliderVal: 50,
      nextItemId: null,
      selectedId: null,
      selectedCaseIdList: [],
    };
  },
  created() {
    this.initState();
  },
  computed: {
    fields() {
      return bulkDiagnosisQuery.filters.fields;
    },
    defaultValues() {
      return bulkDiagnosisQuery.filters.defaultValues;
    },
    EDIT_MODE() {
      return FORM_MODES.EDIT;
    },
    CASE_STATUS() {
      return CASE_STATUS;
    },
  },

  methods: {
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('caseData', ['setCaseData']),
    ...mapActions('caseForm', ['setBlockNamingRuleSetting', 'loadBlockNamingRules']),
    ...mapActions('app/event', ['clearEvents']),
    async initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(bulkDiagnosisQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(bulkDiagnosisQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const query = { filters, pagination };
      this.query = query;

      const dropdownOptions = await dropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.GENDER_TYPES,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
        DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE,
        DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_M,
      ]);
      this.updateDataset({ ...dropdownOptions });
      this.updateFilterList();

      this.caseFormResource = await this.getDataOfFirstCase(this.caseRefList[0]?.value);
    },
    updateFilterList() {
      const { caseRefIds, e } = BulkDiagnosisSession.getCaseRefs();
      if (e) {
        return this.$alertError(e);
      }
      if (caseRefIds.length > 0) {
        this.caseRefList = caseRefIds.map((c) => {
          return {
            label: c,
            value: c,
          };
        });
      }
    },
    async getCaseIdOfFirstItem(caseRefId) {
      const { data } = await caseListService.getBulkDiagnosisList({
        selectAll: false,
        caseRefs: [caseRefId],
      });

      return data.items.length ? data.items[0].caseId : null;
    },

    async loadBlockNamingRules(laboratoryId) {
      const blockNamingRuleSetting = await entityService.getBlockNamingRuleSetting(laboratoryId);
      this.setBlockNamingRuleSetting({ blockNamingRuleSetting });
    },

    async getDataOfFirstCase(caseRefId) {
      const caseId = await this.getCaseIdOfFirstItem(caseRefId);
      if (caseId) {
        const { data, error } = await caseFormService.findOne(caseId, true);
        if (error) {
          this.$alertError(error);
          return null;
        }
        if (data) {
          await this.loadBlockNamingRules(data.laboratoryId);
        }
        return data;
      }
      return false;
    },

    async search({ filters, pagination }) {
      const { pageNumber, pageSize } = pagination;
      const { caseRefIds, error } = BulkDiagnosisSession.getCaseRefs();
      if (error) {
        this.$alertError(error);
        return;
      }
      const caseRefs = filters?.CaseRefs && filters.CaseRefs.length ? filters.CaseRefs : caseRefIds;
      const payload = {
        selectAll: false,
        searchQuery: {
          pageNumber,
          pageSize,
          filters: {
            search: filters.search || '',
            advancedSearch: {},
          },
        },
        caseRefs,
      };
      const res = await caseListService.getBulkDiagnosisList(payload);
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = res.data.items;
      this.totalItem = res.data.pagination.totalItems;
      if (!this.items.length) {
        this.$refs.NoMoreCaseToReviewModal.open();
      }

      // update router
      const query = {
        ...buildFilterQueryParams(filters),
        ...buildFilterQueryParams(pagination),
      };

      if (!isDeepEqual(query, this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    async onChangeSelectedCase(caseSelectedInfo) {
      this.selectedId = caseSelectedInfo.index;
      this.caseFormResource = null;
      // clear data of old events
      this.clearEvents();
      if (caseSelectedInfo) {
        const { error, data } = await caseFormService.findOne(caseSelectedInfo.caseId, true);
        if (error) {
          this.$alertError(error);
          return;
        }
        if (data) {
          await this.loadBlockNamingRules(data.laboratoryId);
          // change selected cases
          this.caseFormResource = data;
          this.setCaseData(data);
          this.selectedCaseId = caseSelectedInfo.caseId;
          this.selectedCaseRef = caseSelectedInfo.caseReference;
        }
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(bulkDiagnosisQuery.filters.defaultValues);
    },
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    async onUpdatedCaseInDiagnosisView(caseId) {
      this.nextItemId = this.selectedId;
      this.selectedCaseIdList.push(caseId);
      this.items = this.items.filter((i) => !this.selectedCaseIdList.find((x) => x === i.caseId));
      this.totalItem = this.items.length;
      if (!this.items.length) {
        this.caseFormResource = null;
        this.$refs.NoMoreCaseToReviewModal.open();
      }
      this.clearEvents();
      this.caseFormResource = await this.getDataOfFirstCase(this.items[this.nextItemId]?.caseReference);
    },
  },
};
</script>
<style lang="scss">
.BulkDiagnosis-page {
  #freezeTopNav {
    display: none;
  }
  .anchor-system {
    position: fixed;
    right: 147px;
    left: 95px;
    width: auto;
    min-width: unset !important;
    z-index: 9 !important;
    //margin-bottom: 20px;
  }
}
.bulkDiagnosis-form-detail {
  .select-document {
    padding: 10px;
  }
}
.case-bulk-diagnosis-detail {
  width: 100%;
  max-height: 500px;
  .document-viewer {
    display: none;
  }
  .lims-block-wrapper .md-card {
    margin-top: 40px;
  }
  .lims-block-wrapper {
    margin-bottom: 10px;
  }
  .anchor-system {
    display: none;
  }
}
</style>
