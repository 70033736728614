<template>
  <div>
    <template v-if="isAdminView">
      <modal-concurrency-issue
        ref="concurrencyIssueModal"
        :name-of-block="'Patient Data'"
        @onContinue="$onConcurrencyIssueContinue"
      ></modal-concurrency-issue>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <md-checkbox
            v-model="formData.optOut"
            :disabled="viewMode || dataEdit.isDeleted || isPartnerDoubleReport || isPathViewOnly"
            class="lims-checkbox"
            tabenable="yes"
          >
            {{ $t('pages/case/CaseManagement/components/FurtherOptions/optOut') }}
          </md-checkbox>
          <lims-tooltip
            :content="$t('pages/case/CaseManagement/components/FurtherOptions/optOut.helpText')"
          ></lims-tooltip>
        </div>
      </div>
    </template>
    <template v-if="isPathView">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-30 md-small-size-100" v-if="setTrueWhenHandleMDM">
          <md-checkbox
            v-model="formData.isRequestMDM"
            :disabled="viewMode || dataEdit.isDeleted || isPartnerDoubleReport || isPathViewOnly"
            class="lims-checkbox"
            tabenable="yes"
          >
            {{ $t('pages/case/CaseManagement/components/FurtherOptions/isRequestMDM') }}
          </md-checkbox>
        </div>
        <div class="md-layout-item md-size-70 md-small-size-100">
          <md-checkbox
            v-model="formData.isCaseOfInterest"
            :disabled="viewMode || dataEdit.isDeleted || isPartnerDoubleReport || isPathViewOnly"
            class="lims-checkbox"
            tabenable="yes"
          >
            {{ $t('pages/case/CaseManagement/components/FurtherOptions/isCaseOfInterest') }}
          </md-checkbox>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-30 md-small-size-100">
          <md-checkbox
            v-model="formData.isSendSMS"
            :disabled="viewMode || dataEdit.isDeleted || isPartnerDoubleReport || isPathViewOnly"
            class="lims-checkbox"
            tabenable="yes"
          >
            {{ $t('pages/case/CaseManagement/components/FurtherOptions/isSendSMS') }}
          </md-checkbox>
          <lims-tooltip
            :content="$t('pages/case/CaseManagement/components/FurtherOptions/isSendSMS.helpText')"
          ></lims-tooltip>
        </div>
        <div class="md-layout-item md-size-70 md-small-size-100">
          <div class="textarea-field">
            <label>{{ $t('pages/case/CaseManagement/components/FurtherOptions/smsMessage') }}</label>
            <md-field>
              <md-textarea
                :disabled="
                  !formData.isSendSMS || viewMode || dataEdit.isDeleted || isPartnerDoubleReport || isPathViewOnly
                "
                v-model="formData.smsMessage"
                tabenable="yes"
              ></md-textarea>
            </md-field>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="formMode === EDIT_MODE && !dataEdit.isDeleted && !isPartnerDoubleReport && !isPathViewOnly"
      class="md-layout lims-form-row"
    >
      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
        <lims-form-cancel></lims-form-cancel>
        <md-button
          @click="onSave()"
          :disabled="isProcessing"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('global/button/button.save') }}
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import { caseFormService, pathCaseService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, CASE_FORM_BLOCK, CASE_STATUS, newAppEvent } from '@/core/constants';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';

export default {
  components: { ModalConcurrencyIssue },
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    isPartnerDoubleReport: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.dataEdit) {
      this.formData = this.pickFurtherOptionData(this.dataEdit);
    }
    this.$resetBlockChanged();
  },
  data() {
    return {
      formData: {
        optOut: false,
      },
      rowVersion: null,
      blockId: CASE_FORM_BLOCK.BLOCK_FURTHER_OPTIONS,
      setTrueWhenHandleMDM: false,
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]),
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Administrator;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
    caseStatus: function () {
      return CASE_STATUS;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        let furtherOptionsData = { optOut: this.formData.optOut };
        if (this.isPathView) {
          furtherOptionsData = this.dataForm();
        }
        this.appendCaseData({
          caseData: furtherOptionsData,
          blockId: CASE_FORM_BLOCK.BLOCK_FURTHER_OPTIONS,
        });        
        this.$emit('input', val);
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadData();
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    pickFurtherOptionData(dataEdit) {
      return {
        optOut: dataEdit.optOut,
        isRequestMDM: dataEdit.isRequestMDM || false,
        isCaseOfInterest: dataEdit.isCaseOfInterest || false,
        isSendSMS: dataEdit.isSendSMS || false,
        smsMessage: dataEdit.smsMessage || '',
        rowVersion: dataEdit.rowVersion || '',
        caseId: dataEdit.caseId || '',
        statusName: dataEdit.statusName || '',
        status: dataEdit.status,
      };
    },
    async reloadData() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, true);
      if (data) {
        this.formData = this.pickFurtherOptionData(data);
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
    },
    async onSave(overWrite = false) {
      try {
        this.isProcessing = true;
        const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
        const dataForm = this.dataForm();
        const res = this.isPathView
          ? await pathCaseService.updateFurtherOptionsForPathCase(
              this.dataEdit.caseId,
              dataForm,
              overWrite ? null : rowVersion,
            )
          : await caseFormService.updateFurtherOptions(
              this.dataEdit.caseId,
              this.formData.optOut,
              overWrite ? null : rowVersion,
            );
        this.isProcessing = false;
        this.$onAfterSaveHandler({
          res,
          dataEdit: {
            ...this.dataEdit,
            ...dataForm,
          },
        });
      } catch (errors) {
        this.isProcessing = false;
        this.$alertError(errors);
      }
    },

    dataForm() {
      const data = {
        isRequestMDM: this.formData.isRequestMDM ? this.formData.isRequestMDM : false,
        isCaseOfInterest: this.formData.isCaseOfInterest ? this.formData.isCaseOfInterest : false,
        isSendSMS: this.formData.isSendSMS ? this.formData.isSendSMS : false,
        smsMessage: this.formData.smsMessage ? this.formData.smsMessage : '',
      };
      return data;
    },
  },
};
</script>
