/* eslint-disable no-unused-vars */
// import { LOCAL_STORAGE_KEYS } from '@/core/constants';
// import router from '@/router';

import { APP_ROUTES, CUSTOM_USER_TYPE_PERMISSIONS, LOCAL_STORAGE_KEYS } from '@/core/constants';
import { deleteCookie, deleteSession, getCookie, getSession, saveSession, setCookie } from '@/core/helpers';
import Vue from 'vue';
import UserService from '@/services/user.service';
import store from '@/store';
import { AuthService, ConfigService } from '@/services';
import router from '@/router';
import environment from '@/core/environment';
import { Exception } from 'sass';
import appSettingService from '@/services/app-setting.service';

const MIN_SESSION_TIMEOUT = 1; // minute

// initial state
const state = () => ({
  accessToken: '',
  user: null,
  permissions: [],
  isLogged: false,
  isFirstTimeLogin: false,
  // other state used for log in page
  showLogoutModal: false,
  challengeName: null,
  sessionId: null,
  isSetupSms: null,
  isSetupTotp: null,
  email: null,
  phoneNumber: null,
  countDownTime: 0,
  activePolicy: null,
  sessionTimeoutInterval: null,
  sessionTimeout: MIN_SESSION_TIMEOUT, // min and default value is 1
  lastActiveSeconds: 0,
});

// const IDDLE_TIMEOUT_INTERVAL = parseInt(environment.VUE_APP_IDDLE_TIMEOUT_INTERVAL || 5) * 1000; // 5 SECONDS
const IDDLE_TIMEOUT_INTERVAL = 1000; // 1 SECONDS
// getters
const getters = {
  // ...
  permissions: (state) => {
    return state.permissions.map((p) => p);
  },
  isLogged: (state) => {
    return state.isLogged && state.user && state.accessToken;
  },
  userType: (state) => {
    return state.user ? state.user.userTypeId : '';
  },
  userId: (state) => {
    return state.user ? state.user.userId : null;
  },
  user: (state) => {
    return state.user ? state.user : null;
  },
  isSuperAdmin: (state) => {
    return state.user.isSuperAdmin ? state.user.isSuperAdmin : null;
  },
  entityId: (state) => {
    return state.user.entityId || null;
  },
  entityName: (state) => {
    return state.user.entityName || null;
  },
  sessionId: (state) => {
    return state.sessionId ? state.sessionId : null;
  },
  activePolicy: (state) => {
    return state.activePolicy ? state.activePolicy : [];
  },
};

// actions
const actions = {
  showLoading({ commit }) {
    commit('toggleLoading', { isLoading: true });
  },
  hideLoading({ commit }) {
    commit('toggleLoading', { isLoading: false });
  },

  SET_LAST_TIME_CALL_API({ commit }, lastTimeCallApi) {
    commit('setLastTimeCallApi', lastTimeCallApi);
  },

  DO_LOGIN({ commit, dispatch }, { accessTokenData, user }) {
    // show loading
    store.dispatch('app/$showLoading');
    // commit to state changes
    commit('setTokenData', accessTokenData);
    // set cookie to allow other http request executing without 403 error
    if (accessTokenData.accessToken && !getCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
      setCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessTokenData.accessToken);
    }
    if (accessTokenData.refreshToken && !getCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)) {
      setCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, accessTokenData.refreshToken);
    }
    const { firstName, lastName, ...userData } = user;
    commit('setUser', user);
    commit('setIsFirstTimeLogin', user.isFirstTimeLogin);

    dispatch('FETCH_PERMISSION', user);
    dispatch('START_IDLE_INTERVAL');
  },
  DO_LOGOUT({ commit, dispatch }, errorMessage = null) {
    UserService.logout()
      .then(() => {})
      .catch((error) => {
        store.dispatch('app/$showAppError', { errorMessage: error });
      })
      .finally(() => {
        // delete cookie
        deleteCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        deleteCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        deleteCookie(LOCAL_STORAGE_KEYS.USER);
        deleteCookie(LOCAL_STORAGE_KEYS.REMEMBER_ME);
        deleteCookie(LOCAL_STORAGE_KEYS.PERMISSIONS);
        deleteCookie(LOCAL_STORAGE_KEYS.CONFIG);
        deleteCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK);
        deleteCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK);
        deleteCookie(LOCAL_STORAGE_KEYS.LAST_TIME_CALL_API);
        deleteCookie(LOCAL_STORAGE_KEYS.IS_REFRESHING_TOKEN);
        // delete localstorage
        localStorage.clear();
        sessionStorage.clear();
        // commit to state changes
        store.dispatch('app/$disablePasswordCheck');
        commit('setTokenData', '');
        commit('setUser', null);
        commit('clearIntervalTimers', { sessionTimeoutInterval: true });
        dispatch('UPDATE_PERMISSIONS', { permissions: [], isLogged: false });
        if (errorMessage) {
          store.dispatch('app/$showAppError', { errorMessage });
        }

        // redirect to auth page
        router.push(APP_ROUTES.AUTH_LOGIN);
      });
  },
  async FETCH_PERMISSION({ dispatch }, user) {
    const { userId, userTypeName } = user;

    const permissions = getSession(LOCAL_STORAGE_KEYS.PERMISSIONS);

    if (!permissions) {
      const { data, error } = await UserService.fetchPermissions({ userId });
      // save permission in sessions
      if (error) {
        store.dispatch('app/$showAppError', { error });
      } else {
        let permissions = data.result;
        // default user can access private zone if is logged
        permissions.push('AccessPrivateZone');
        // Adding Usertype to permission list to check
        const {
          UserType_Administrator,
          UserType_Pathologist,
          UserType_LabTechnician,
          UserType_Clinician,
          UserType_ClinicianAssociate,
          UserType_ClinicAssociate,
          My_Personalised_Report,
          Personalised_Report_Retrieve,
        } = CUSTOM_USER_TYPE_PERMISSIONS;
        if (userTypeName === 'Administrator') {
          permissions.push(Personalised_Report_Retrieve);
          permissions.push(UserType_Administrator);
        } else if (userTypeName === 'Pathologist') {
          permissions.push(UserType_Pathologist);
          if (permissions.find((p) => p == 'Case_PersonalizedReports_Retrieve')) {
            permissions.push(My_Personalised_Report);
            permissions = permissions.filter((p) => !['Case_PersonalizedReports_Retrieve'].includes(p));
          }
        } else if (userTypeName === 'Lab Technician') {
          permissions.push(UserType_LabTechnician);
          if (!user.entityCanBookCase) {
            permissions = permissions.filter(
              (p) => !['Case_CaseForm_Create', 'Case_SimpleBookingForm_Create'].includes(p),
            );
          }
        } else if (userTypeName === 'Clinician') {
          permissions.push(UserType_Clinician);
        } else if (userTypeName === 'Clinician Associate') {
          permissions.push(UserType_ClinicianAssociate);
        } else if (userTypeName === 'Clinic Associate') {
          if (permissions.includes('MyEntity_DatasetManagement_Retrieve')) {
            permissions.push(UserType_ClinicAssociate);
          }
          if (!user.isManageDataset) {
            // remove DatasetManagement Permission
            permissions = permissions.filter((v) => v !== 'MyEntity_DatasetManagement_Retrieve');
            permissions = permissions.filter((v) => v !== 'MyEntity_DatasetManagement_Create');
            permissions = permissions.filter((v) => v !== 'MyEntity_DatasetManagement_Update');
            permissions = permissions.filter((v) => v !== 'MyEntity_DatasetManagement_Delete');
          }
        }
        dispatch('UPDATE_PERMISSIONS', { permissions: permissions, isLogged: true });
      }
    } else {
      dispatch('UPDATE_PERMISSIONS', { permissions, isLogged: true });
    }
  },
  UPDATE_PERMISSIONS({ commit }, { permissions, isLogged }) {
    commit('setPermissions', permissions);
    if (isLogged) {
      // load app config
      ConfigService.loadAppConfig()
        .then(() => {
          // change logged-in status
          commit('setIsLogged', isLogged);
          store.dispatch('app/$hideLoading');
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      commit('setIsLogged', isLogged);
    }
  },

  UPDATE_COUNT_DOWN_TIME({ commit }, { countDownTime }) {
    commit('setCountDownTime', countDownTime);
  },

  UPDATE_CHALLENGE_NAME({ commit }, { challengeName, sessionId, isSetupSms, isSetupTotp, email, phoneNumber }) {
    commit('setChallengeName', challengeName);
    commit('setSessionId', sessionId);
    commit('setIsSetupSms', isSetupSms);
    commit('setIsSetupTotp', isSetupTotp);
    commit('setEmail', email);
    commit('setPhoneNumber', phoneNumber);
  },

  CHECK_USER_COOKIE({ dispatch }) {
    const accessToken = getCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const refreshToken = getCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    const user = getCookie(LOCAL_STORAGE_KEYS.USER);
    if (accessToken && refreshToken && user) {
      dispatch('DO_LOGIN', {
        accessTokenData: {
          accessToken: accessToken,
        },
        user: JSON.parse(user),
      });
    }
  },

  async START_IDLE_INTERVAL({ commit }) {
    try {
      const { data, error } = await appSettingService.getAppSettings();
      store.dispatch('app/$setAppSettings', data);
      if (error) {
        this.$alertError(error);
        return;
      }
      const sessionTimeout = Math.max(MIN_SESSION_TIMEOUT, parseInt(data.sessionTimeout || MIN_SESSION_TIMEOUT));
      if (data && sessionTimeout) {
        commit('startIdleInterval', {
          sessionTimeout,
        });
        return;
      }
      // otherwise mission sessionTimeout
    } catch (error) {
      // DO NOTHING : WAIT FOR SYSTEM LOGOUT AUTOMATICALLY
      this.$alertError(error);
    }
  },

  async CHECK_SESSION_TIMEOUT({ commit }, { leftTimeForPasswordCheck }) {
    try {
      // call api
      await AuthService.isAccessTokenValid();
      // enablePasswordCheck
      store.dispatch('app/$enablePasswordCheck', { leftTimeForPasswordCheck });
    } catch {
      // DO NOTHING : WAIT FOR SYSTEM LOGOUT AUTOMATICALLY
    }
  },

  SET_ACTIVE_POLICY({ commit }, { sessionId, activePolicy }) {
    commit('setSessionId', sessionId);
    commit('setActivePolicy', activePolicy);
  },
  UPDATE_LAST_ACTIVE_SECONDS({ commit }, { lastActiveSeconds }) {
    commit('setLastActiveSeconds', { lastActiveSeconds });
  },
};

// mutations
const mutations = {
  CLEAR_LOGIN_SESSION: (state) => {},
  startIdleInterval: (state, { sessionTimeout }) => {
    state.sessionTimeout = sessionTimeout;
    // start new interval
    if (state.sessionTimeoutInterval) {
      store.commit('auth/clearIntervalTimers', { sessionTimeoutInterval: true });
    }
    const enablePasswordCheck = parseInt(getCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK)) === 1;
    if (enablePasswordCheck) {
      const leftTimeForPasswordCheck = parseInt(getCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK)) || 60;
      store.dispatch('auth/CHECK_SESSION_TIMEOUT', { leftTimeForPasswordCheck });
      return;
    }
    state.sessionTimeoutInterval = setInterval(() => {
      // last active time
      const lastTimeCallApi = parseInt(getCookie(LOCAL_STORAGE_KEYS.LAST_TIME_CALL_API)) || 0;
      if (!lastTimeCallApi) {
        return;
      }
      const enablePasswordCheck = parseInt(getCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK)) === 1;
      const SESSION_TIMEOUT = Math.max(MIN_SESSION_TIMEOUT, parseInt(state.sessionTimeout)) * 60; // seconds
      const now = new Date();
      const lastActiveSeconds = (now.getTime() - lastTimeCallApi) / 1000; // seconds
      // update
      store.dispatch('auth/UPDATE_LAST_ACTIVE_SECONDS', { lastActiveSeconds });
      if (enablePasswordCheck || (lastActiveSeconds > SESSION_TIMEOUT && lastActiveSeconds - SESSION_TIMEOUT < 60)) {
        // stop interval
        store.commit('auth/clearIntervalTimers', { sessionTimeoutInterval: true });
        let leftTimeForPasswordCheck = 60;
        if (enablePasswordCheck) {
          leftTimeForPasswordCheck = parseInt(getCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK)) || 60;
        }
        store.dispatch('auth/CHECK_SESSION_TIMEOUT', { leftTimeForPasswordCheck: 60 });
      } else if (lastActiveSeconds > SESSION_TIMEOUT) {
        store.dispatch('auth/DO_LOGOUT');
      }
    }, IDDLE_TIMEOUT_INTERVAL);
  },
  clearIntervalTimers: (state, timers) => {
    if (state.sessionTimeoutInterval && timers['sessionTimeoutInterval']) {
      clearInterval(state.sessionTimeoutInterval);
      state.lastActiveSeconds = 0;
      state.sessionTimeoutInterval = null;
    }
  },
  setLastActiveSeconds(state, { lastActiveSeconds }) {
    state.lastActiveSeconds = lastActiveSeconds;
  },
  setUser: (state, user) => {
    if (user && !getCookie(LOCAL_STORAGE_KEYS.USER)) {
      const { accessToken, refreshToken, ...userData } = user;
      setCookie(LOCAL_STORAGE_KEYS.USER, JSON.stringify(userData));
    }
    Vue.set(state, 'user', user);
  },
  setTokenData: (state, accessTokenData) => {
    state.accessToken = accessTokenData.accessToken;
  },
  setIsLogged: (state, isLogged) => {
    state.isLogged = isLogged;
  },
  setIsFirstTimeLogin: (state, isFirstTimeLogin) => {
    state.isFirstTimeLogin = isFirstTimeLogin;
  },
  setPermissions: (state, permissions) => {
    if (permissions.length > 0) {
      saveSession(LOCAL_STORAGE_KEYS.PERMISSIONS, permissions);
    } else {
      deleteSession(LOCAL_STORAGE_KEYS.PERMISSIONS);
    }
    Vue.set(state, 'permissions', permissions);
  },

  setCountDownTime: (state, countDownTime) => Vue.set(state, 'countDownTime', countDownTime),

  setChallengeName: (state, challengeName) => Vue.set(state, 'challengeName', challengeName),

  setSessionId: (state, sessionId) => Vue.set(state, 'sessionId', sessionId),

  setIsSetupSms: (state, isSetupSms) => Vue.set(state, 'isSetupSms', isSetupSms),

  setIsSetupTotp: (state, isSetupTotp) => Vue.set(state, 'isSetupTotp', isSetupTotp),

  setEmail: (state, email) => Vue.set(state, 'email', email),

  setPhoneNumber: (state, phoneNumber) => Vue.set(state, 'phoneNumber', phoneNumber),

  setLastTimeCallApi: (state, lastTimeCallApi) => {
    setCookie(LOCAL_STORAGE_KEYS.LAST_TIME_CALL_API, lastTimeCallApi);
  },

  showLogoutModal: (state) => {
    state.showLogoutModal = true;
  },

  setActivePolicy: (state, activePolicy) => {
    state.activePolicy = activePolicy;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
