<template>
  <form class="md-layout lims-form">
    <lims-block class="patient-data-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/audit-trail/information/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <div slot="md-collapse-pane-1">
          <form class="md-layout lims-form">
            <div v-if="formData" class="form-wrapper">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="case"></lims-field>
                  <div class="static-text">{{ formData.caseReference }}</div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="created"></lims-field>
                  <div class="static-text">
                    {{ auditTrialInforData['createdDate'] ? formatDate(auditTrialInforData['createdDate']) : '' }}
                  </div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="action"></lims-field>
                  <div class="static-text">{{ formData.action }}</div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="actorType"></lims-field>
                  <div class="static-text">{{ formData.userTypeName }}</div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="actorName"></lims-field>
                  <div class="static-text">{{ formData.userName }}</div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="ipAddress"></lims-field>
                  <div class="static-text">{{ formData.ip }}</div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="patient"></lims-field>
                  <div class="static-text">{{ formData.patient }}</div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="clinician"></lims-field>
                  <div class="static-text">{{ formData.clinicianName }}</div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="pathologist"></lims-field>
                  <div class="static-text">{{ formData.pathologistName }}</div>
                </div>
              </div>

              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="auditTrailSchema" field="infoAfterAction"></lims-field>
                  <div class="pd-top-10" v-for="(value, name) in formData.differenceObjectData" :key="name">
                    <div class="object-value">
                      <strong>
                        <template v-if="formData.action == 'Restored' && name == 'Status'">
                          {{ $t('pages/case/audit-trail/information/action.oldStatus') }}
                        </template>
                        <template v-else>{{ name }}</template>
                      </strong>
                      : {{ value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </lims-collapse>
    </lims-block>
  </form>
</template>

<script>
import LimsCollapse from '@/components/Lims/LimsCollapse';
import { getAuditTrailSchema } from '@/schemas/audit-trail.schema';
import { DateTimeHelper } from '@/core/helpers';

export default {
  components: {
    LimsCollapse,
  },
  props: {
    auditTrialInfor: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        objectData: {},
        differenceObjectData: {},
      },
      auditTrialInforData: null,
    };
  },
  created() {
    const val = this.auditTrialInfor;
    this.auditTrialInforData = val;
    this.formData = {
      ...val,
      objectData: val.objectData.length > 0 ? JSON.parse(val.objectData) : '',
      differenceObjectData: val.differenceObjectData?.length > 0 ? JSON.parse(val.differenceObjectData) : '',
    };
  },
  computed: {
    auditTrailSchema() {
      return getAuditTrailSchema();
    },
  },
  watch: {
    auditTrialInfor: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.auditTrialInforData = val;
          this.formData = {
            ...val,
            objectData: val.objectData.length > 0 ? JSON.parse(val.objectData) : '',
            differenceObjectData: val.differenceObjectData?.length > 0 ? JSON.parse(val.differenceObjectData) : '',
          };
        }
      },
    },
  },
  methods: {
    formatDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.object-value{
  word-break: break-word;
}
</style>
