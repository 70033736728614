<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    @md-clicked-outside="close"
    class="lims-modal large-modal"
  >
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head style="width: 200px">
            {{ $t('components/ModalViewSoResponse/table.responder') }}
          </md-table-head>
          <md-table-head class="th-center">
            {{ $t('global/pages/list.status') }}
          </md-table-head>
          <md-table-head>
            {{ $t('components/ModalViewSoResponse/table.respondedComment') }}
          </md-table-head>
          <md-table-head>
            {{ $t('components/ModalViewSoResponse/table.respondedOn') }}
          </md-table-head>
          <md-table-head class="th-center" style="width: 100px">
            {{ $t('global/pages/list.actions') }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="(item, index) in SoResponseList"
          :key="`so-${index}`"
          :class="item.status == STATUSES.CANCELLED ? 'strikethrough' : ''"
        >
          <md-table-cell>{{ item.pathName }}</md-table-cell>
          <md-table-cell class="th-center status-column">
            <md-icon
              :class="[
                { 'status-enabled': item.status == STATUSES.COMPLETED },
                { 'status-disabled': item.status == STATUSES.REQUESTED },
              ]"
              >adjust</md-icon
            >
          </md-table-cell>
          <md-table-cell>{{ item.respondeComment }}</md-table-cell>
          <md-table-cell>{{ item.respondeOn ? formatDate(item.respondeOn) : '' }}</md-table-cell>
          <md-table-cell class="th-center">
            <md-button
              v-if="item.status == STATUSES.REQUESTED && !viewMode"
              class="md-just-icon md-danger md-simple"
              @click="onDeleteRequestCase(item)"
            >
              <md-icon>delete_forever</md-icon></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <md-dialog-actions>
      <md-button @click="onBack" class="lims-form-button">{{ $t('global/button/button.back') }}</md-button>
      <md-button @click="onSave" :disabled="isDisableSavebtn || isProcessing" class="lims-form-button md-primary">{{
        $t('global/button/button.save')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { ALW_SO_STATUS, APP_EVENTS, newAppEvent } from '@/core/constants';
import { pathCaseService } from '@/services';
import { mapActions } from 'vuex';
import { DateTimeHelper } from '@/core/helpers';

export default {
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
    viewMode: {
      type: Boolean,
    },
  },
  computed: {
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
  },
  data() {
    return {
      SoResponseList: [],
      caseSORequestId: '',
      isDisableSavebtn: true,
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    open(data) {
      if (data) {
        this.isVisible = true;
        this.SoResponseList = data.pathologistUsers;
        this.caseSORequestId = data.caseSORequestId;
        this.isDisableSavebtn = true;
      }
    },
    close() {
      this.isVisible = false;
      this.SoResponseList = [];
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_RELOAD_SO, {
          caseId: this.caseId,
        }),
      );
    },

    onBack() {
      this.close();
    },

    formatDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },

    onDeleteRequestCase(item) {
      if (item) {
        const indexRequest = this.SoResponseList.findIndex(
          (soResponse) => soResponse.caseSORequestId === item.caseSORequestId,
        );
        if (indexRequest >= 0) {
          this.SoResponseList[indexRequest].status = this.STATUSES.CANCELLED;
        }
      }
      this.isDisableSavebtn = false;
    },

    async onSave() {
      this.isProcessing = true;
      let caseSOCancelledIds = [];
      const caseSOCancelled = this.SoResponseList.filter((item) => item.status == this.STATUSES.CANCELLED);
      caseSOCancelled.forEach((item) => {
        caseSOCancelledIds.push(item.pathId);
      });
      const res = await pathCaseService.deleteCaseSoRequestsPathUsers(this.caseSORequestId, caseSOCancelledIds);
      this.isProcessing = false;
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.$alertSuccess(this.$t('components/ModalViewSoResponse/cancellRequest.success'));
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_RELOAD_SO, {
            caseId: this.caseId,
          }),
        );
      }
    },
  },
};
</script>
