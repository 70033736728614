/* eslint-disable security/detect-object-injection */
import { FIELDS_WAS_RESET_TO_NULL } from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import Vue from 'vue';

// initial state
const state = () => ({
  saveCaseFormError: null,
  blockChangedStatus: {},
  caseIdWithRowVersion: [],
  isInformedSpecimenTypeChanged: {},
  caseRowVersions: {},
  expandCollapseBlockName: null,
  isChangePathologist: false,
  confictSnomed: {},
  fieldsToBeSetNull: {
    [FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING]: false,
    [FIELDS_WAS_RESET_TO_NULL.WORKSTREAM]: false,
    [FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST]: false,
    [FIELDS_WAS_RESET_TO_NULL.SLIDE]: false,
  },
  blockNamingRuleSetting: null,
});

// getters
const getters = {
  fieldsToBeSetNull(state) {
    return state.fieldsToBeSetNull;
  },
  getSaveCaseFormError(state) {
    return state.saveCaseFormError;
  },
  isUnsavedChanged: (state) => {
    const changes = Object.values(state.blockChangedStatus) || [];
    return changes.includes(true);
  },
  getRowVersionByCaseId: (state) => (key) => {
    return state.caseRowVersions ? state.caseRowVersions[key] : null;
  },
  getRowVersionWithCaseId: (state) => {
    return state.caseIdWithRowVersion;
  },
  isInformedCaseSpecimenTypeChanged: (state) => (key) => {
    return state.isInformedSpecimenTypeChanged ? state.isInformedSpecimenTypeChanged[key] : null;
  },
  caseRowVersions: (state) => {
    return state.caseRowVersions[0];
  },
  getExpandCollapseBlockName: (state) => {
    return state.expandCollapseBlockName;
  },
  isPathologistResetToNull: (state) => {
    return state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST] || false;
  },
  isWorkStreamResetToNull: (state) => {
    return state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.WORKSTREAM] || false;
  },
  isSlideResetToNull: (state) => {
    return state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.SLIDE] || false;
  },
  isChangePathologist: (state) => {
    return state.setIsChangePathologist || false;
  },
  confictSnomed: (state) => {
    return state.confictSnomed;
  },
  $blockNamingRuleSetting: (state) => {
    return state.blockNamingRuleSetting;
  },
};

// actions
const actions = {
  resetBlockState({ commit }) {
    commit('resetBlockState');
  },
  setBlockChanged({ commit }, block) {
    commit('setBlockChanged', block);
  },
  setSaveCaseFormError({ commit }, isSaveCaseFormError) {
    commit('setSaveCaseFormError', isSaveCaseFormError);
  },
  setExpandCollapseBlockName({ commit }, blockName) {
    commit('setExpandCollapseBlockName', blockName);
  },
  clearExpandCollapseBlockName({ commit }) {
    commit('setExpandCollapseBlockName', null);
  },
  setBlockUnchangedBlock({ commit }, block) {
    commit('setBlockUnchangedBlock', block);
  },
  setRowVersionByCaseId({ commit }, { caseId, rowVersion }) {
    commit('setRowVersionByCaseId', { caseId, rowVersion });
  },
  setCaseIdWithRowVersion({ commit }, { caseId, rowVersion }) {
    commit('setCaseIdWithRowVersion', { caseId, rowVersion });
  },
  setIsInformedSpecimenTypeChanged({ commit }, { caseId, isChanged }) {
    commit('setIsInformedSpecimenTypeChanged', { caseId, isChanged });
  },
  setIsChangePathologist({ commit }, isChangePathologist) {
    commit('setIsChangePathologist', isChangePathologist);
  },
  resetFieldsToBeSetNull({ commit }) {
    commit('resetFieldsToBeSetNull');
  },
  markFieldToBeSetNull({ commit }, fieldName) {
    commit('markFieldToBeSetNull', { fieldName });
  },
  unMarkFieldToBeSetNull({ commit }, fieldNames) {
    commit('unMarkFieldToBeSetNull', { fieldNames });
  },
  setConfictSnomed({ commit }, { specimen, isConfictSnomed }) {
    commit('setConfictSnomed', { specimen, isConfictSnomed });
  },
  resetConfictSnomed({ commit }) {
    commit('resetConfictSnomed');
  },
  setBlockNamingRuleSetting({ commit }, { blockNamingRuleSetting }) {
    commit('setBlockNamingRuleSetting', { blockNamingRuleSetting });
  },
};

// mutations
const mutations = {
  resetFieldsToBeSetNull: (state) => {
    state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.WORKSTREAM] = false;
    state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING] = false;
    state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.PATHOLOGIST] = false;
    state.fieldsToBeSetNull[FIELDS_WAS_RESET_TO_NULL.SLIDE] = false;
  },
  markFieldToBeSetNull: (state, { fieldName }) => {
    state.fieldsToBeSetNull[fieldName] = true;
  },
  unMarkFieldToBeSetNull: (state, { fieldNames }) => {
    fieldNames.forEach((f) => {
      state.fieldsToBeSetNull[f] = false;
    });
  },
  setBlockChanged: (state, block) => {
    Vue.set(state.blockChangedStatus, block, true);
  },
  setSaveCaseFormError: (state, isSaveCaseFormError) => {
    Vue.set(state, 'saveCaseFormError', isSaveCaseFormError);
  },
  setBlockUnchangedBlock: (state, block) => {
    Vue.set(state.blockChangedStatus, block, false);
  },
  setExpandCollapseBlockName: (state, blockName) => {
    Vue.set(state, 'expandCollapseBlockName', blockName);
  },
  resetBlockState: (state) => {
    Vue.set(state, 'blockChangedStatus', {});
  },
  setRowVersionByCaseId: (state, { caseId, rowVersion }) => {
    const caseRowVersions = state.caseRowVersions;
    Reflect.set(caseRowVersions, caseId, rowVersion);
    Vue.set(state, 'caseRowVersions', caseRowVersions);
  },
  setCaseIdWithRowVersion: (state, { caseId, rowVersion }) => {
    const caseIdWithRowVersion = state.caseIdWithRowVersion;
    Reflect.set(caseIdWithRowVersion, caseId, rowVersion);
    Vue.set(state, 'caseIdWithRowVersion', caseIdWithRowVersion);
  },
  setIsInformedSpecimenTypeChanged: (state, { caseId, isChanged }) => {
    const isInformedSpecimenTypeChanged = state.isInformedSpecimenTypeChanged;
    Reflect.set(isInformedSpecimenTypeChanged, caseId, isChanged);
    Vue.set(state, 'isInformedSpecimenTypeChanged', isInformedSpecimenTypeChanged);
  },
  setIsChangePathologist: (state, isChangePathologist) => {
    Vue.set(state, 'setIsChangePathologist', isChangePathologist);
  },
  setConfictSnomed: (state, { specimen, isConfictSnomed }) => {
    const confictSnomed = state.confictSnomed;
    Reflect.set(confictSnomed, specimen, isConfictSnomed);
    Vue.set(state, 'confictSnomed', confictSnomed);
  },
  resetConfictSnomed: (state) => {
    Vue.set(state, 'confictSnomed', {});
  },
  setBlockNamingRuleSetting: (state, { blockNamingRuleSetting }) => {
    Vue.set(state, 'blockNamingRuleSetting', blockNamingRuleSetting);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
