<template>
  <div>
    <modal-create-so-request
      ref="createSoRequest"
      :caseId="caseId"
      :caseBrief="caseBrief"
      :specimenTypeId="dataEdit.caseSpecimens[0].specimenTypeId"
      :is-pause-count-return-to-pull="dataEdit.isPauseCountReturnToPull"
    ></modal-create-so-request>
    <modal-concurrency-issue
      ref="concurrencyIssueModal"
      :name-of-block="'Patient Data'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-view-so-response :caseId="caseId" ref="viewSoResponseModal" :viewMode="viewMode"></modal-view-so-response>
    <template>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-25 md-small-size-100">
          <label>{{ $t('pages/case/CaseManagement/components/SO/requested') }}</label>
          <div class="md-static-field">
            <md-field>
              <md-input disabled :value="caseSORequestSummary.countRequested" type="text" tabenable="yes"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <label>{{ $t('pages/case/CaseManagement/components/SO/completed') }}</label>
          <div class="md-static-field">
            <md-field>
              <md-input disabled :value="caseSORequestSummary.countCompleted" type="text" tabenable="yes"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <label>{{ $t('pages/case/CaseManagement/components/SO/cancelled') }}</label>
          <div class="md-static-field">
            <md-field>
              <md-input disabled :value="caseSORequestSummary.countCancelled" type="text" tabenable="yes"></md-input>
            </md-field>
          </div>
        </div>
      </div>
      <div
        v-if="!isReportedStatus && caseStatus !== CASE_STATUS.LAB && !isPathViewOnly"
        class="md-layout lims-form-row"
      >
        <md-button :disabled="viewMode || dataEdit.isDeleted" class="lims-form-button" @click="onAddNewRequest">
          {{ $t('pages/case/CaseManagement/components/SO/addNewRequest') }}
        </md-button>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head>
              {{ $t('pages/case/CaseManagement/components/SO/table.requester') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/case/CaseManagement/components/SO/table.caseBrief') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/case/CaseManagement/components/SO/table.responder') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/case/CaseManagement/components/SO/table.requestedOn') }}
            </md-table-head>
            <md-table-head class="th-center">
              {{ $t('pages/case/CaseManagement/components/SO/table.status') }}
            </md-table-head>
            <md-table-head class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row
            v-for="item in caseSORequests"
            :key="`so${item.caseSORequestId}`"
            :class="item.status == STATUSES.CANCELLED ? 'strikethrough' : ''"
          >
            <md-table-cell>{{ item.requesterName }}</md-table-cell>
            <md-table-cell>{{ item.caseBrief }}</md-table-cell>
            <md-table-cell>{{ getResponderList(item.pathologistUsers) }}</md-table-cell>
            <md-table-cell>{{ formatDate(item.createdDate) }}</md-table-cell>
            <md-table-cell class="th-center status-column">
              <md-icon
                :class="[
                  { 'status-enabled': item.status == STATUSES.COMPLETED },
                  { 'status-disabled': item.status == STATUSES.REQUESTED },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="th-center">
              <md-button v-if="item.status == STATUSES.REQUESTED && !isPathViewOnly" class="md-just-icon md-simple">
                <md-icon>info</md-icon>
                <md-tooltip
                  md-direction="bottom"
                  class="tooltip-align-left"
                  v-html="inforTooltip(item.pathologistUsers)"
                >
                </md-tooltip>
              </md-button>
              <md-button class="md-just-icon md-info md-simple" @click="onViewSO(item)">
                <md-icon>visibility</md-icon>
              </md-button>
              <md-button
                v-if="isShowDeleteIcon(item) && !isPathViewOnly && !viewMode"
                class="md-just-icon md-danger md-simple"
                @click="viewMode ? '' : onDeleteRequestCase(item)"
              >
                <md-icon>delete_forever</md-icon></md-button
              >
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div
        v-if="!isReportedStatus && caseStatus !== CASE_STATUS.LAB && !dataEdit.isDeleted && !isPathViewOnly"
        class="md-layout lims-form-row"
      >
        <div v-if="!viewMode" class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
          <lims-form-cancel></lims-form-cancel>
          <md-button
            @click="onSave()"
            :disabled="isProcessing"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ModalCreateSoRequest } from '@/components/Lims/modals';
import { DateTimeHelper } from '@/core/helpers';
import { pathCaseService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import { ALW_SO_STATUS, APP_EVENTS, FORM_MODES, CASE_FORM_BLOCK, newAppEvent, CASE_STATUS } from '@/core/constants';
import { caseFormService } from '@/services';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import ModalViewSoResponse from '@/components/Lims/modals/ModalViewSoResponse';
import { cloneDeep } from 'lodash';

export default {
  mixins: [CaseMixins, CaseBlockMixins],
  components: {
    ModalConcurrencyIssue,
    ModalCreateSoRequest,
    ModalViewSoResponse,
  },

  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM, APP_EVENTS.EVT_ON_RELOAD_SO]),
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
    caseStatus() {
      return this.dataEdit.status;
    },
    CASE_STATUS() {
      return CASE_STATUS;
    },
    isReportedStatus() {
      return this.dataEdit.status === CASE_STATUS.REPORTED;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
  },
  created() {
    if (this.dataEdit) {
      this.bindingSOData(this.dataEdit);
    }
  },
  data() {
    return {
      caseId: '',
      caseBrief: '',
      caseSORequests: [],
      caseSORequestSummary: {
        countRequested: 0,
        countCompleted: 0,
        countCancelled: 0,
      },
      blockId: CASE_FORM_BLOCK.BLOCK_SECOND_OPINION,
      isProcessing: false,
    };
  },
  watch: {
    caseSORequests: {
      deep: true,
      handler: function (val) {
        if (val) {
          const deletedCaseSORequests = this.caseSORequests
            .filter((i) => i.status == this.STATUSES.CANCELLED)
            .map((i) => i.caseSORequestId);
          this.appendCaseData({
            caseData: { deletedCaseSORequests: cloneDeep(deletedCaseSORequests) },
            blockId: CASE_FORM_BLOCK.BLOCK_SECOND_OPINION,
          });
          this.$emit('totalNumberOfRequests', val.length);
        }
      },
    },
    [APP_EVENTS.EVT_ON_RELOAD_SO]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadSOData();
        }
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // remove event
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_RELOAD_SO));
          // reload data
          this.reloadSOData();
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    onAddNewRequest() {
      this.$refs.createSoRequest.open();
    },
    bindingSOData(originalData) {
      const data = cloneDeep(originalData);
      this.caseId = data.caseId;
      this.caseBrief = data.caseBrief;
      this.caseSORequests = cloneDeep(data.caseSORequests);
      if (data.caseSORequestSummary) {
        this.caseSORequestSummary.countRequested = data.caseSORequestSummary.countRequested;
        this.caseSORequestSummary.countCompleted = data.caseSORequestSummary.countCompleted;
        this.caseSORequestSummary.countCancelled = data.caseSORequestSummary.countCancelled;
      }
    },
    async reloadSOData() {
      const { data } = await caseFormService.findOne(this.caseId, true);
      if (data) {
        this.bindingSOData(data);
        this.$onUpdateRowVersion({
          caseId: data.caseId,
          rowVersion: data.rowVersion,
        });
      }
    },
    formatDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    onDeleteRequestCase(item) {
      if (item) {
        const indexRequest = this.caseSORequests.findIndex(
          (caseSORequest) => caseSORequest.caseSORequestId === item.caseSORequestId,
        );
        if (indexRequest >= 0) {
          this.caseSORequests[indexRequest].status = this.STATUSES.CANCELLED;
        }
      }
    },
    caseSORequestData() {
      if (this.caseSORequests && this.caseSORequests.length > 0) {
        return this.caseSORequests.filter((i) => i.status == this.STATUSES.CANCELLED).map((i) => i.caseSORequestId);
      }
      return [];
    },
    async onSave(overWrite = false) {
      const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
      const dataForm = this.caseSORequestData();
      if (dataForm.length > 0) {
        this.isProcessing = true;
        const res = await pathCaseService.deletecaseSORequests(
          this.dataEdit.caseId,
          dataForm,
          overWrite ? null : rowVersion,
        );
        this.isProcessing = false;
        this.$onAfterSaveHandler(
          {
            res,
            dataEdit: this.dataEdit,
          },
          () => {
            this.reloadSOData();
          },
        );
      }
    },

    onViewSO(item) {
      this.$refs.viewSoResponseModal.open(item);
    },
    getResponderList(list) {
      return list.map((i) => i.pathName).join();
    },
    inforTooltip(list) {
      const responderRequestedList = list.filter((e) => e.status == this.STATUSES.REQUESTED);
      const responderRequested = responderRequestedList.map((i) => i.pathName).join();

      const responderCompletedList = list.filter((e) => e.status == this.STATUSES.COMPLETED);
      const responderCompleted = responderCompletedList.map((i) => i.pathName).join();

      const responderCancelledList = list.filter((e) => e.status == this.STATUSES.CANCELLED);
      const responderCancelled = responderCancelledList.map((i) => i.pathName).join();

      let requested = responderRequested ? `<b>Requested:</b> ${responderRequested}<br>` : '';
      let completed = responderCompleted ? `<b>Completed:</b> ${responderCompleted}<br>` : '';
      let cancelled = responderCancelled ? `<b>Cancelled:</b> ${responderCancelled}<br>` : '';

      return requested + completed + cancelled;
    },

    isShowDeleteIcon(item) {
      //number of all SO request to pathothologistUser (all status Requsted, Cancel, Completed)
      const numOfUserRequest = item.pathologistUsers.length;
      //number of SO has status Requested in this list
      const requestedList = item.pathologistUsers.filter((item) => item.status == this.STATUSES.REQUESTED);
      return numOfUserRequest == requestedList.length;
    },
  },
};
</script>
<style lang="scss">
.strikethrough .md-table-cell {
  text-decoration: line-through;
  &.status-column {
    text-decoration: unset;
  }
}
</style>
