<template>
  <ValidationObserver ref="clinicAndLaboratoryForm">
    <modal-concurrency-issue
      ref="concurrencyIssueModal"
      :name-of-block="'Clinician & Laboratory'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-change-clinic-or-laboratory-warning
      ref="changeLaboratoryWarningModal"
      :description="$t('components/lims/modals/ModalChangeClinicOrLaboratoryWarning/description.laboratory')"
    ></modal-change-clinic-or-laboratory-warning>
    <modal-change-clinic-or-laboratory-warning
      ref="changeClinicWarningModal"
      :description="$t('components/lims/modals/ModalChangeClinicOrLaboratoryWarning/description.clinic')"
    ></modal-change-clinic-or-laboratory-warning>
    <collapse :wrapperClass="'case-collapse'" :collapse="collapseName" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <div v-if="formData" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="clinicianId">
              <lims-single-select
                slot="field"
                v-model="formData.clinicianId"
                :options="clinicianList"
                reduceKey="id"
                labelKey="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                :placeholder="$t('entities/case/form/clinicianId.placeholder')"
              ></lims-single-select>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="clinicId">
              <lims-single-select
                slot="field"
                :options="clinicList"
                v-model="formData.clinicId"
                reduceKey="id"
                labelKey="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                @input="onClinicIdChanged"
                :placeholder="$t('entities/case/form/clinicId.placeholder')"
              ></lims-single-select>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="laboratory">
              <lims-single-select
                slot="field"
                :options="laboratoryList"
                v-model="formData.laboratoryId"
                reduceKey="id"
                labelKey="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isLabTechnicianView"
                :placeholder="$t('entities/case/form/laboratoryId.placeholder')"
                @input="onLaboratoryIdChanged"
              ></lims-single-select>
            </lims-field>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="technician1Id">
              <lims-single-select
                slot="field"
                :options="technician1List"
                v-model="formData.technician1Id"
                reduceKey="id"
                labelKey="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isLabTechnicianView"
              ></lims-single-select>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="technician2Id">
              <lims-single-select
                slot="field"
                :options="technician2List"
                v-model="formData.technician2Id"
                reduceKey="id"
                labelKey="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
              ></lims-single-select>
            </lims-field>
          </div>
          <div v-if="isShowQualityControlField" class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="reviewerId">
              <lims-single-select
                slot="field"
                :options="qualityControlList"
                v-model="formData.reviewerId"
                reduceKey="userId"
                labelKey="fullName"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
              ></lims-single-select>
            </lims-field>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100 courierCompany-field">
            <lims-field :model="formData" :schema="caseFormSchema" field="courierCompany">
              <md-input
                slot="field"
                v-model="formData.courierCompany"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                maxlength="250"
                tabenable="yes"
              >
              </md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100 pickupDateAndTime-field">
            <lims-field :model="formData" :schema="caseFormSchema" field="pickupDateAndTime">
              <date-picker
                slot="field"
                v-model="formData.pickupDate"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                :lang="{
                  formatLocale: {
                    firstDayOfWeek: 1,
                  },
                  monthBeforeYear: false,
                }"
                type="datetime"
                format="DD/MM/YYYY HH:mm:ss"
                :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                tabenable="yes"
              ></date-picker>
            </lims-field>
          </div>
        </div>
        <div v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </template>
    </collapse>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { Collapse } from '@/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { convertDateTimeToUTCFormat, sortDropDown } from '@/core/helpers';
import {
  APP_EVENTS,
  CASE_FORM_BLOCK,
  CASE_LIST_DROPDOWN,
  CASE_STATUS,
  ENTITY_TYPES,
  FORM_MODES,
  newAppEvent,
} from '@/core/constants';
import { caseFormService, caseListService } from '@/services';
import myEntityService from '@/services/myEntity.service';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import ModalChangeClinicOrLaboratoryWarning from '@/components/Lims/modals/ModalChangeClinicOrLaboratoryWarning';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import entityService from '@/services/entity.service';
import { uniqBy } from 'lodash';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  components: {
    ModalConcurrencyIssue,
    ModalChangeClinicOrLaboratoryWarning,
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        const dataForm = this.billingClinicAndLaboratoryData();        
        this.appendCaseData({
            caseData: dataForm,
            blockId: CASE_FORM_BLOCK.BLOCK_CLINIC_LABORATORY,
        });                
        if (this.formMode === this.ADD_MODE) {
          this.$emit('input', {
            ...val,
          });
        }
      },
    },
    'formData.clinicId': {
      deep: true,
      handler: async function (clinicId) {
        // emit clinic id
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED, {
            clinicId,
          }),
        );
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.reloadData();
        }
      },
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]),
    ...mapGetters('caseForm', ['isChangePathologist']),
    ...mapGetters('caseData', ['caseData']),
    isPreLabStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, null, null, null, {
        isPreLabStatus: this.isPreLabStatus,
        isReviewCaseScreen: this.isReviewCaseScreen,
      });
    },
    technician1Name() {
      return this.formData.technician1Name || this.getFullNameTechnician(this.user);
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/components/ClinicAndLaboratory/collapseTitle';
      return [this.$translate(name)];
    },
    isLabTechnicianView() {
      return this.isLabTechnician();
    },
    isShowQualityControlField() {
      const userType = this.$store.getters['auth/userType'];
      return (
        this.dataEdit?.status !== CASE_STATUS.PRE_LAB &&
        this.dataEdit?.status !== CASE_STATUS.LAB &&
        this.formMode != this.ADD_MODE &&
        userType === this.USER_TYPES().Administrator
      );
    },
  },
  data() {
    return {
      myLaboratory: null,
      formData: {},
      clinicianList: [],
      clinicList: [],
      laboratoryList: [],
      technician2List: [],
      technician1List: [],
      rowVersion: null,
      blockId: CASE_FORM_BLOCK.BLOCK_CLINIC_LABORATORY,
      technicianOneName: null,
      clinicianListAll: [],
      clinicListAll: [],
      laboratoryListAll: [],
      qualityControlList: [],
      laboratoryOnLoad: '',
      isFirstTimeChange: true,
      dependencyField: '',
      isProcessing: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    formatPickupDate(value) {
      return value ? convertDateTimeToUTCFormat(value) : null;
    },
    isLabTechnician() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.LabTechnician;
    },
    resetFieldValueIfNotExistedInList(listOptions, fieldName) {
      const fieldValue = Reflect.get(this.formData, fieldName);

      const isExisted =
        fieldName === 'reviewerId'
          ? listOptions.find((option) => option.userId === fieldValue)
          : listOptions.find((option) => option.id === fieldValue);
      if (!isExisted && fieldValue) {
        Reflect.set(this.formData, fieldName, null);
      }
    },
    async loadLaboratoryList(clinicId = null) {
      const laboratoryList = await this.loadEntityType({
        entityTypes: [ENTITY_TYPES.Laboratory, ENTITY_TYPES.ClinicLaboratory],
        dependencyEntityId: clinicId,
      });
      this.laboratoryList = uniqBy(laboratoryList, (item) => item.id);
      this.resetFieldValueIfNotExistedInList(this.laboratoryList, 'laboratoryId');
    },
    async loadClinicList(laboratoryId = null) {
      const clinicList = await this.loadEntityType({
        entityTypes: [ENTITY_TYPES.Clinic, ENTITY_TYPES.ClinicLaboratory],
        dependencyEntityId: laboratoryId,
      });

      this.clinicList = uniqBy(clinicList, (item) => item.id);
      this.resetFieldValueIfNotExistedInList(this.clinicList, 'clinicId');
    },
    async loadClinicianList(laboratoryId = null) {
      if (laboratoryId) {
        this.clinicianList = await this.loadUsersByUserType({
          userTypeId: this.USER_TYPES().Clinician,
          entityId: laboratoryId,
        });
      } else {
        // otherwise
        const dropdownOptionsList = await caseListService.getDropdown([CASE_LIST_DROPDOWN.CLINICIAN]);
        this.clinicianList = sortDropDown(dropdownOptionsList[CASE_LIST_DROPDOWN.CLINICIAN], 'text');
      }
      this.resetFieldValueIfNotExistedInList(this.clinicianList, 'clinicianId');
    },
    async loadQualityControlList(laboratoryId = null, clinicId = null) {
      const params = {
        laboratoryId,
        clinicId,
        caseId: this.dataEdit.caseId,
      };
      const { data, error } = await caseFormService.getCaseQualityControl(params);
      if (error) {
        this.$alertError(error);
      }
      this.qualityControlList = data;
      this.resetFieldValueIfNotExistedInList(this.qualityControlList, 'reviewerId');
    },
    async loadTechnicianOneAndTwo(laboratoryId) {
      // without laboratoryId will catch error 400
      if (laboratoryId) {
        this.technician1List = await this.loadUsersByUserType({
          userTypeId: this.USER_TYPES().LabTechnician,
          entityId: laboratoryId,
        });
      } else {
        this.technician1List = [];
      }
      this.technician2List = this.technician1List;
      this.resetFieldValueIfNotExistedInList(this.technician1List, 'technician1Id');
      this.resetFieldValueIfNotExistedInList(this.technician2List, 'technician2Id');
    },
    async loadDependencyDataForLabTechnicianView() {
      this.laboratoryList.push(this.myLaboratory);
      await this.loadClinicList(this.myLaboratory.id);
      await this.loadClinicianList(this.myLaboratory.id);
      this.technician1List = [
        {
          id: this.user.userId,
          text: this.user.firstName + ' ' + this.user.lastName,
        },
      ];
      this.technician2List = await this.loadUsersByUserType({
        userTypeId: this.USER_TYPES().LabTechnician,
        entityId: this.myLaboratory.id,
      });
    },
    setInitialFormDataForLabTechView(dataEdit) {
      const myUserId = this.user.userId;
      if (dataEdit) {
        this.setInitialFormData(dataEdit);
      } else {
        this.formData = {
          technician1Id: myUserId,
          technician2Id: myUserId,
          laboratoryId: this.myLaboratory.id,
        };
      }
    },
    setInitialFormData(dataEdit) {
      this.formData = {
        technician1Id: null,
        technician2Id: null,
        ...dataEdit,
        pickupDate: this.formatPickupDate(dataEdit.pickupDate),
      };
    },
    isDependencyFieldsReset() {
      if (!this.dataEdit) {
        return false;
      }
      ['clinicId', 'laboratoryId', 'clinicianId', 'technician1Id', 'technician2Id'].every((fieldName) => {
        if (Reflect.get(this.dataEdit, fieldName) !== Reflect.get(this.formData, fieldName)) {
          return true;
        }
      });
      return false;
    },

    async loadDependencyDataForOthersView(laboratoryId, clinicId = null) {
      /* Excerpt lab view
       * we always passed the existing laboratoryId as dependency for:
       * The fist time load laboratory list
       * If user remove laboratoryId and clinicId is empty and of course,
       * we have to pass the editData.clinicId as dependency
       */
      await this.loadClinicianList(laboratoryId);
      await this.loadTechnicianOneAndTwo(laboratoryId);
      await this.loadQualityControlList(laboratoryId, clinicId);
    },
    async fetchData() {
      if (this.isLabTechnician()) {
        this.myLaboratory = await myEntityService.privateGetMyEntityName();
        this.setInitialFormDataForLabTechView(this.dataEdit);
        await this.loadDependencyDataForLabTechnicianView();
        if (!this.isDependencyFieldsReset()) {
          this.$resetBlockChanged();
        }
        return;
      }
      // otherwise is edit mode
      this.setInitialFormData(this.dataEdit);
      /*
       * We have an entitype named lab&clinic, which actions both of clinic and lab
       * and when retrieve list lab or list clinic, by default it is not existed in
       * the response. So we decided always pass laboratoryId as dependency if this value
       * is not null when retrieve list lab, because it never restrict itself, so that
       * we always have in the list
       */
      await this.onLoadAllClinicAndLab();
      await this.loadDependencyDataForOthersView(this.dataEdit.laboratoryId, this.dataEdit.clinicId);
      if (!this.isDependencyFieldsReset()) {
        this.$resetBlockChanged();
      }
    },

    async reloadData() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, true);
      if (data) {
        this.formData = {
          ...data,
          pickupDate: this.formatPickupDate(data.pickupDate),
        };
        this.laboratoryOnLoad = data.laboratoryId;
        this.$onUpdateRowVersion({
          caseId: data.caseId,
          rowVersion: data.rowVersion,
        });
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
      this.$resetBlockChanged();
    },

    async loadEntityType({ entityTypes, dependencyEntityId }) {
      try {
        const { data } = await caseFormService.getEntitiesByEntityTypeAndUser({
          search: '',
          entityTypeIds: entityTypes.join(','),
          dependencyEntityId: dependencyEntityId,
        });
        return sortDropDown(data, 'text');
      } catch (error) {
        this.$alertError(error);
        return null;
      }
    },

    async loadUsersByUserType({ userTypeId, entityId }) {
      const { data } = await caseFormService.getUsersByUserType({
        search: '',
        userTypeId: userTypeId,
        entityId: entityId,
      });
      return sortDropDown(data, 'text');
    },

    async isClinicHasCustomSnomed(clinicId) {
      if (!clinicId) return false;
      const { data } = await entityService.checkHasCustomSnomed(clinicId);
      return data ? data : false;
    },

    getFullNameTechnician(user) {
      return `${user.firstName} ${user.lastName}`;
    },

    async onLaboratoryIdChanged(laboratoryId) {
      if (laboratoryId) {
        if (this.isFirstTimeChange) {
          await this.loadClinicList(laboratoryId);
          await this.loadLaboratoryList(null);
          this.isFirstTimeChange = false;
          this.dependencyField = 'Lab';
        } else {
          if (this.dependencyField == 'Lab') {
            await this.loadClinicList(laboratoryId);
          }
        }
        const findClinicInList = this.clinicList.find((item) => item.id === this.formData.clinicId);
        this.formData.clinicId = findClinicInList ? this.formData.clinicId : null;
        await this.loadDependencyDataForOthersView(laboratoryId, this.formData.clinicId);
      } else {
        await this.loadDependencyDataForOthersView(null, this.formData.clinicId);
      }

      if (!laboratoryId && !this.formData.clinicId) {
        await this.onLoadAllClinicAndLab();
      }

      if (laboratoryId && this.dataEdit.laboratoryId != laboratoryId && this.formData.status !== CASE_STATUS.LAB) {
        this.$refs.changeLaboratoryWarningModal.open();
      }
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED, {
          laboratoryId,
          saved: false,
        }),
      );
    },
    async onClinicIdChanged(clinicId) {
      if (this.isLabTechnicianView) return;
      if (clinicId) {
        if (this.isFirstTimeChange) {
          await this.loadClinicList(null);
          await this.loadLaboratoryList(clinicId);
          this.isFirstTimeChange = false;
          this.dependencyField = 'Clinic';
        } else {
          if (this.dependencyField == 'Clinic') {
            await this.loadLaboratoryList(clinicId);
          }
        }
        const findLaboratoryInList = this.laboratoryList.find((item) => item.id === this.formData.laboratoryId);
        this.formData.laboratoryId = findLaboratoryInList ? this.formData.laboratoryId : null;
        await this.loadQualityControlList(this.formData.laboratoryId, clinicId);

        const isHasCustomSnomed = await this.isClinicHasCustomSnomed(clinicId);

        // Modal - Clinic Change → expected: only display in case status = Provisionally Reported/Reported
        if (
          this.dataEdit.clinicId != clinicId &&
          isHasCustomSnomed &&
          [CASE_STATUS.PROVISIONALLY_REPORTED, CASE_STATUS.REPORTED].includes(this.dataEdit?.status)
        ) {
          this.$refs.changeClinicWarningModal.open();
        }
      }
      if (!clinicId && !this.formData.laboratoryId) {
        await this.onLoadAllClinicAndLab();
      }
    },

    async onLoadAllClinicAndLab() {
      await this.loadClinicList(null);
      await this.loadLaboratoryList(null);
      this.isFirstTimeChange = true;
      this.dependencyField = '';
    },

    billingClinicAndLaboratoryData(overrideMode) {
      const data = {
        insuranceCompanyId: this.formData.insuranceCompanyId,
        insuranceNumber: this.formData.insuranceNumber,
        insuranceAuthorisationNumber: this.formData.insuranceAuthorisationNumber,
        insuranceNote: this.formData.insuranceNote,
        clinicianId: this.formData.clinicianId,
        clinicId: this.formData.clinicId,
        technician1Id: this.formData.technician1Id,
        technician2Id: this.formData.technician2Id,
        courierCompany: this.formData.courierCompany,
        pickupDate: this.formData.pickupDate,
        laboratoryId: this.formData.laboratoryId,
        reviewerId: this.formData.reviewerId,
      };
      if (overrideMode) {
        data.rowVersion = null;
      }
      return data;
    },

    async onSave(overWrite = false) {
      this.$refs.clinicAndLaboratoryForm.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
            const dataForm = this.billingClinicAndLaboratoryData();
            const res = await caseFormService.updateBillingClinicAndLab(this.dataEdit.caseId, {
              ...dataForm,
              rowVersion: overWrite ? null : rowVersion,
            });
            this.isProcessing = false;
            this.$onAfterSaveHandler({
              res,
              dataEdit: {
                ...this.dataEdit,
                ...dataForm,
              },
            });
            if (this.isChangePathologist && !this.caseData.pathologistId) {
              this.$alertError(this.$t(`pages/case/CaseManagement/component/specimen/pathologistEmpty`));
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss">
.self-payers-information {
  border: 1px solid #ddd;
  padding: 30px 20px 40px 20px;
  margin-bottom: 15px;
}
.if-billing-entity {
  font-weight: 500;
}
</style>
