<template>
  <ValidationObserver ref="DiagnosisViewData" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper" v-if="formData">
        <lims-collapse-with-information
          :wrapperClass="'case-collapse diagnosis-collapse'"
          :collapse="collapseReport"
          :information="getInformationByEntityId(formData.caseSpecimens[0].caseReportId)"
          icon="keyboard_arrow_down"
        >
          <template slot="md-collapse-pane-1">
            <div v-if="!isOneMicroscopyForAllSpecimens">
              <div v-for="(specimen, index) in formData.caseSpecimens" :key="index">
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <label>{{ specimenName(index) }}</label>
                    <div class="static-text">
                      {{ specimen.specimentTypeName }}
                    </div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedT"> </lims-field>
                    <div v-if="specimen.snomedT" class="static-text">{{ specimen.snomedT.fieldItemName }}</div>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedP"> </lims-field>
                    <div v-if="specimen.snomedP" class="static-text">{{ specimen.snomedP.fieldItemName }}</div>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                    <div v-if="specimen.snomedM" class="static-text">{{ specimen.snomedM.fieldItemName }}</div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-100">
                    <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
                  </div>
                </div>
                <div class="caseDataset" v-if="specimen.caseDataset">
                  <div
                    v-for="(control, controlIndex) in specimen.caseDataset.controls"
                    :key="controlIndex"
                    class="md-layout"
                  >
                    <div class="md-layout-item md-size-25">
                      <label>{{ control.label }}</label>
                    </div>
                    <div class="md-layout-item md-size-75">
                      <div class="static-text" style="padding: 0">{{ control.selectValue }}</div>
                    </div>
                  </div>
                </div>
                <div class="md-layout lims-form-row" v-if="!specimen.caseDataset">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <div class="static-text" style="padding: 0" v-lims-text-area="specimen.microscopy"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <label>{{ getSpecimenHeader(formData.caseSpecimens) }}</label>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedT"> </lims-field>
                  <div v-if="formData.caseSpecimens[0].snomedT" class="static-text">
                    {{ formData.caseSpecimens[0].snomedT.fieldItemName }}
                  </div>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedP"> </lims-field>
                  <div v-if="formData.caseSpecimens[0].snomedP" class="static-text">
                    {{ formData.caseSpecimens[0].snomedP.fieldItemName }}
                  </div>
                </div>
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                  <div v-if="formData.caseSpecimens[0].snomedM" class="static-text">
                    {{ formData.caseSpecimens[0].snomedM.fieldItemName }}
                  </div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100">
                  <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
                </div>
              </div>
              <div class="caseDataset" v-if="formData.caseSpecimens[0].caseDataset">
                <div
                  v-for="(control, controlIndex) in formData.caseSpecimens[0].caseDataset.controls"
                  :key="controlIndex"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-25">
                    <label>{{ control.label }}</label>
                  </div>
                  <div class="md-layout-item md-size-75">
                    <div class="static-text" style="padding: 0">{{ control.selectValue }}</div>
                  </div>
                </div>
              </div>
              <div class="md-layout lims-form-row" v-if="!formData.caseSpecimens[0].caseDataset">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <div
                    class="static-text"
                    style="padding: 0"
                    v-lims-text-area="formData.caseSpecimens[0].microscopy"
                  ></div>
                </div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <label>{{ $t('entities/case/form/diagnosis/caseCommentOrDiagnosisSummary') }}</label>
                <div class="static-text" v-lims-text-area="formData.caseCommentOrDiagnosisSummary"></div>
              </div>
            </div>
          </template>
        </lims-collapse-with-information>
        <div>
          <div
            v-for="(caseSupplementaryReport, index) in caseSupplementaryReports"
            :key="index"
            :id="'caseSupplementaryReport-' + `${index + 1}`"
          >
            <lims-collapse-with-information
              v-if="caseSupplementaryReport.isTemporary === false"
              :wrapperClass="'case-collapse diagnosis-collapse'"
              :collapse="collapseSupplementaryReport(index + 1)"
              :information="getInformationByEntityId(caseSupplementaryReport.caseReportId)"
              icon="keyboard_arrow_down"
              :expand-collapse-block-name="'caseSupplementaryReport-' + `${index + 1}`"
            >
              <template slot="md-collapse-pane-1">
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                    <div class="static-text">{{ caseSupplementaryReport.snomedM }}</div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
                    <div class="static-text" v-lims-text-area="caseSupplementaryReport.microscopy"></div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <label>{{ $t('entities/case/form/diagnosis/caseCommentOrDiagnosisSummary') }}</label>
                    <div
                      class="static-text"
                      v-lims-text-area="caseSupplementaryReport.caseCommentOrDiagnosisSummarySupplementary"
                    ></div>
                  </div>
                </div>
              </template>
            </lims-collapse-with-information>
          </div>
          <lims-collapse-with-information
            v-if="isProvisionallyReportedStatusAndPathView"
            :wrapperClass="'case-collapse diagnosis-collapse'"
            :collapse="collapseSupplementaryReport('')"
            icon="keyboard_arrow_down"
            :id="'caseSupplementaryReport-input'"
          >
            <template slot="md-collapse-pane-1">
              <div class="md-layout lims-form-row" v-if="!isPathViewOnly">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM">
                    <v-select
                      slot="field"
                      :options="snomedMList"
                      v-model="formData.snomedMId"
                      :placeholder="$t('entities/case/form/diagnosis/placeHolder/selectAValue')"
                      label="fieldItemName"
                      :reduce="(option) => option.fieldItemId"
                      :disabled="dataEdit.isDeleted || viewMode"
                    >
                      <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                      <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                    </v-select>
                  </lims-field>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field
                    class="textarea-field"
                    :model="formData"
                    :schema="caseFormSchema.diagnosis"
                    field="microscopy"
                  >
                    <md-textarea
                      slot="field"
                      v-model="formData.microscopy"
                      :disabled="dataEdit.isDeleted || isPathViewOnly || viewMode"
                    ></md-textarea>
                  </lims-field>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field
                    class="textarea-field"
                    :model="formData"
                    :schema="caseFormSchema.diagnosis"
                    field="caseCommentOrDiagnosisSummary"
                  >
                    <md-textarea
                      slot="field"
                      v-model="formData.caseCommentOrDiagnosisSummarySupplementaryReport"
                      :disabled="dataEdit.isDeleted || isPathViewOnly || viewMode"
                    ></md-textarea>
                  </lims-field>
                </div>
              </div>
            </template>
          </lims-collapse-with-information>
        </div>
        <div>
          <div class="md-layout lims-form-row" v-if="!isAdminView">
            <div class="md-layout-item md-size-33 md-small-size-100 same-specimen-type">
              <md-checkbox
                v-model="formData.markCaseAsUrgent"
                :disabled="dataEdit.isDeleted || isPathViewOnly || viewMode || isReportedStatusAndPathView"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/markCaseAsUrgent') }}
              </md-checkbox>
              <lims-tooltip :content="$t('entities/case/form/diagnosis/markCaseAsUrgentHelpText')"></lims-tooltip>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100 same-specimen-type">
              <md-checkbox
                v-model="formData.isUnexpectedFindings"
                :disabled="dataEdit.isDeleted || isPathViewOnly || viewMode || isReportedStatusAndPathView"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/isUnexpectedFindings') }}
              </md-checkbox>
              <lims-tooltip :content="$t('entities/case/form/diagnosis/isUnexpectedFindingsHelpText')"></lims-tooltip>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <md-checkbox
                :key="'sendMdmNotification'"
                v-model="formData.isSentMdmNotification"
                :disabled="viewMode || dataEdit.isDeleted || isPathViewOnly || isReportedStatusAndPathView"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/sendMdmNotification') }}
              </md-checkbox>
            </div>
          </div>
        </div>
        <div v-if="!isPathViewOnly && !viewMode">
          <div v-if="isProvisionallyReportedStatusAndPathView || isAdminView" class="md-layout lims-form-row">
            <div
              v-if="!dataEdit.isDeleted"
              class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action"
            >
              <lims-form-cancel></lims-form-cancel>
              <md-button
                @click="onSave()"
                :disabled="isProcessing"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.save') }}
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { caseFormService, pathCaseService } from '@/services';
import { dropdownOptionMapping } from '@/services/dropdown.service';
import { APP_EVENTS, CASE_FORM_BLOCK, DROPDOWN_SHORT_NAME, newAppEvent, SNOMED_NAME } from '@/core/constants';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { mapActions, mapGetters } from 'vuex';
import LimsCollapseWithInformation from '@/components/Lims/LimsCollapseWithInformation';
import cloneDeep from 'lodash/cloneDeep';
import CaseBlockMixins from '../CaseBlock.mixins';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';
import { filterDropdownListByHiddenField } from '@/core/helpers';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins, CaseSpecimenMixins],
  components: { LimsCollapseWithInformation },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
    },
    isProvisionallyReportedStatusAndPathView: {
      type: Boolean,
      require: false,
    },
    isReportedStatusAndPathView: {
      type: Boolean,
      require: false,
    },
    onAuthorise: {
      type: Boolean,
      require: false,
    },
  },
  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]),
    ...mapGetters('auth', ['entityId']),
    collapseReport() {
      const name = 'pages/case/CaseManagement/components/DiagnosisView/collapseReportTitle';
      return [this.$translate(name)];
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
    isOneMicroscopyForAllSpecimens() {
      return this.formData.isOneMicroscopyForAllSpecimens;
    },
  },
  async created() {
    await this.fetchData();
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        // supplementaryReportedData
        const caseSupplementaryReport = cloneDeep(this.supplementaryReportedData());
        this.appendCaseData({
          caseData: {
            caseSupplementaryReport,
            isUnexpectedFindings: caseSupplementaryReport.isUnexpectedFindings,
            isSentMdmNotification: caseSupplementaryReport.isSentMdmNotification,
          },
          blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS_VIEW,
        });
        this.$emit('input', {
          ...val,
        });
        // set null for value snomedM selected not in snomedMList (value slected is hidden in editMode)
        const findSnomedMInSnomedList = this.snomedMList.find(
          (snomedItem) => snomedItem.fieldItemId == this.formData.snomedMId,
        );
        if (!findSnomedMInSnomedList) {
          this.formData.snomedMId = null;
        }
      },
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadData();
        }
      },
    },
  },
  data() {
    return {
      formData: null,
      snomedTList: [],
      snomedPList: [],
      snomedMList: [],
      caseSupplementaryReports: [],
      snomedData: null,
      blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS_VIEW,
      blockNamingRuleSetting: { enable1ANamingConvention: false, skippedIO: false },
      isProcessing: false,
    };
  },
  methods: {
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    async fetchData() {
      this.snomedData = await this.getSnomedCodeList(this.dataEdit.clinicId);
      const { formData, caseSupplementaryReports } = this.buildData(this.dataEdit);
      this.$set(this, 'formData', formData);
      this.$set(this, 'caseSupplementaryReports', caseSupplementaryReports);
      if (this.dataEdit) {
        this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.dataEdit.laboratoryId);
      } else {
        this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.entityId);
      }
      this.$resetBlockChanged();
    },
    getInformationByEntityId(entityId) {
      if (this.dataEdit.casePathologistAuthoriseTrackings.length > 0) {
        let casePathologistAuthoriseTracking = this.dataEdit.casePathologistAuthoriseTrackings.find(
          (casePathologistAuthoriseTracking) => casePathologistAuthoriseTracking.entityId === entityId,
        );
        return this.$t('entities/case/form/diagnosis/reportedInformation', {
          authoriseDate: casePathologistAuthoriseTracking?.authoriseDate
            ? this.formatDateTimeGlobal(casePathologistAuthoriseTracking?.authoriseDate)
            : '',
          pathologistFullName: casePathologistAuthoriseTracking?.pathologistFullName,
        });
      } else {
        return null;
      }
    },
    collapseSupplementaryReport(index) {
      const name = 'pages/case/CaseManagement/components/DiagnosisView/collapseSupplementaryReportTitle';
      if (index) {
        return [this.$translate(name) + ' ' + generateSpecimenName(index - 1, this.blockNamingRuleSetting)];
      } else {
        return [this.$translate(name)];
      }
    },
    findSnomed(caseSpecimen) {
      const snomedTFieldName = SNOMED_NAME.SNOMED_T;
      const snomedPFieldName = SNOMED_NAME.SNOMED_P;
      const snomedMFieldName = SNOMED_NAME.SNOMED_M;
      const snomed = {
        snomedT: null,
        snomedM: null,
        snomedP: null,
      };
      caseSpecimen.caseSpecimenDiagnosis.map((item) => {
        const { fieldName } = item;
        if (fieldName === snomedTFieldName) {
          snomed.snomedT = item;
        }
        if (fieldName === snomedPFieldName) {
          snomed.snomedP = item;
        }
        if (fieldName === snomedMFieldName) {
          snomed.snomedM = item;
        }
      });
      return snomed;
    },
    buildData(dataEdit) {
      this.snomedTList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SNOMED_T);
      this.snomedPList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SNOMED_P);
      this.snomedMList =
        this.formMode == this.VIEW_MODE
          ? this.snomedData[DROPDOWN_SHORT_NAME.SNOMED_M]
          : filterDropdownListByHiddenField(this.snomedData[DROPDOWN_SHORT_NAME.SNOMED_M]);
      let formData = {
        isUnexpectedFindings: dataEdit.isUnexpectedFindings ? dataEdit.isUnexpectedFindings : false,
        isSentMdmNotification: dataEdit.isSentMdmNotification ? dataEdit.isSentMdmNotification : false,
        caseSpecimens: [],
        caseCommentOrDiagnosisSummary: '',
        authorizedDate: null,
        caseCommentOrDiagnosisSummarySupplementaryReport: '',
        microscopy: '',
        markCaseAsUrgent: dataEdit.urgent,
        snomedMId: '',
        isOneMicroscopyForAllSpecimens: dataEdit.isOneMicroscopyForAllSpecimens,
      };
      let caseSupplementaryReports = [];
      // field names changed from diagnosisSummary to
      formData.caseCommentOrDiagnosisSummary = dataEdit.diagnosisSummary;
      formData.caseSpecimens = dataEdit.caseSpecimens.map((caseSpecimen) => {
        const snomed = this.findSnomed(caseSpecimen);
        let caseDataset = caseSpecimen.caseDataset || null;

        return {
          ...caseSpecimen,
          ...snomed,
          caseDataset,
        };
      });

      if (dataEdit.caseSupplementaryReports.length > 0) {
        caseSupplementaryReports = dataEdit.caseSupplementaryReports.map((caseSupplementaryReport) => {
          return {
            snomedMId: caseSupplementaryReport.fieldItems[0]?.fieldItemId,
            snomedM: caseSupplementaryReport.fieldItems[0]?.fieldItemName,
            ...caseSupplementaryReport,
            createdDate: this.formatDateTimeGlobal(caseSupplementaryReport.createdDate),
            microscopy: caseSupplementaryReport.microscopy,
            caseCommentOrDiagnosisSummarySupplementary: caseSupplementaryReport.caseComment,
            isTemporary: caseSupplementaryReport.isTemporary,
            markCaseAsUrgent: caseSupplementaryReport.markCaseAsUrgent,
            isUnexpectedFindings: caseSupplementaryReport.isUnexpectedFindings,
            isSentMdmNotification: caseSupplementaryReport.isSentMdmNotification,
            caseSupplementaryReportId: caseSupplementaryReport.caseSupplementaryReportId,
            caseReportId: caseSupplementaryReport.caseReportId,
          };
        });
        // find temporary report to show as suplementary report
        const temporaryReport = caseSupplementaryReports.find((e) => e.isTemporary);
        if (temporaryReport) {
          formData = {
            ...formData,
            snomedMId: temporaryReport.snomedMId,
            microscopy: temporaryReport.microscopy,
            caseCommentOrDiagnosisSummarySupplementaryReport:
              temporaryReport.caseCommentOrDiagnosisSummarySupplementary,
            markCaseAsUrgent: dataEdit.urgent,
            isUnexpectedFindings: dataEdit.isUnexpectedFindings,
            isSentMdmNotification: dataEdit.isSentMdmNotification,
          };
        }
      }

      return { formData, caseSupplementaryReports };
    },
    async reloadData() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, false);
      if (data) {
        const { formData, caseSupplementaryReports } = this.buildData(data);
        this.$set(this, 'formData', formData);
        this.$set(this, 'caseSupplementaryReports', caseSupplementaryReports);
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
    },
    async getSnomedCodeList(entityId) {
      const data = await caseFormService.getStainByEntityId(entityId, [DROPDOWN_SHORT_NAME.SNOMED]);

      return dropdownOptionMapping(data, [
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.SNOMED_M,
      ]);
    },
    supplementaryReportedData() {
      return {
        microscopy: this.formData.microscopy,
        caseComment: this.formData.caseCommentOrDiagnosisSummarySupplementaryReport,
        fieldItems: this.formData.snomedMId
          ? [
              {
                fieldId: this.snomedMList[0].fieldId,
                fieldItemId: this.formData.snomedMId,
              },
            ]
          : [],
        urgent: this.formData.markCaseAsUrgent,
        isUnexpectedFindings: this.formData.isUnexpectedFindings,
        isSentMdmNotification: this.formData.isSentMdmNotification,
      };
    },
    async onSave(autoSave = false) {
      this.$refs.DiagnosisViewData.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            if (this.isAdminView) {
              const dataForm = this.diagnosisInfo(this.formData.caseSpecimens);
              const res = await pathCaseService.updateCaseDiagnosis(this.dataEdit.caseId, dataForm);
              this.isProcessing = false;
              if (res.err) {
                return this.$alertError(res.err);
              } else {
                this.$alertSuccess(
                  this.$t('pages/case/CaseManagement/CaseForm/update.success', {
                    caseReference: this.dataEdit.caseReference,
                  }),
                );
              }
              this.$resetBlockChanged();
            } else {
              const dataForm = this.supplementaryReportedData();
              const res = await pathCaseService.updateCaseSupplementaryReport(this.dataEdit.caseId, dataForm);
              this.isProcessing = false;
              if (res.err && !autoSave) {
                return this.$alertErrorServerSide(res.err);
              }
              // otherwise
              if (!autoSave) {
                this.$alertSuccess(
                  this.$t('pages/case/CaseManagement/CaseForm/update.success', {
                    caseReference: this.dataEdit.caseReference,
                  }),
                );
                this.$resetBlockChanged();
                // reload page : should changed later
              }
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    diagnosisInfo(caseSpecimens) {
      const data = {
        isOneMicroscopyForAllSpecimens: this.formData.isOneMicroscopyForAllSpecimens,
        diagnosisSummary: this.formData.caseCommentOrDiagnosisSummary,
        urgent: this.formData.markCaseAsUrgent,
        isUnexpectedFindings: this.formData.isUnexpectedFindings,
        isSentMdmNotification: this.formData.isSentMdmNotification,
        caseSpecimens: cloneDeep(caseSpecimens).map((caseSpecimen) => {
          if (this.formData.isOneMicroscopyForAllSpecimens) {
            caseSpecimen.caseSpecimenDiagnosis = caseSpecimens[0].caseSpecimenDiagnosis;
            caseSpecimen.microscopy = caseSpecimens[0].microscopy;
            caseSpecimen.caseDataset = caseSpecimens[0].caseDataset;
            caseSpecimen.caseDatasetValues = caseSpecimens[0].caseDatasetValues;
          }
          if (caseSpecimen.caseDataset && caseSpecimen.caseDatasetValues) {
            // transform
            caseSpecimen.caseDataset.controls = caseSpecimen.caseDataset.controls.map((c) => {
              return {
                ...c,
                selectValue: caseSpecimen.caseDatasetValues[`field_${c.controlId}`],
              };
            });
          }
          return {
            // API naming issue: caseSpecimenId vs caseSpecimentId
            caseSpecimentId: caseSpecimen.caseSpecimentId, // keep the field for other API issue - save all
            caseSpecimenId: caseSpecimen.caseSpecimentId,
            microscopy: caseSpecimen.microscopy,
            caseSpecimenDiagnosis: caseSpecimen.caseSpecimenDiagnosis,
            caseDataset: caseSpecimen.caseDataset,
          };
        }),
      };
      return data;
    },
    getSpecimenHeader(specimens) {
      if (specimens && specimens.length > 0) {
        let specimenHeader = '';
        for (let i = 0; i < specimens.length; i++) {
          if (specimenHeader !== '') {
            specimenHeader += ', ';
          }
          specimenHeader +=
            this.$t('entities/case/form/diagnosis/specimen') +
            ' ' +
            generateSpecimenName(i, this.blockNamingRuleSetting);
        }
        return specimenHeader;
      }
    },
    specimenName(index) {
      const name = 'entities/case/form/diagnosis/specimen';
      return this.$translate(name) + ' ' + generateSpecimenName(index, this.blockNamingRuleSetting);
    },
  },
};
</script>
<style lang="scss"></style>
