<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <modal-preview-pictures
      ref="previewPicturesModal"
      @onHidePreviewPictures="onHidePreviewPictures"
    ></modal-preview-pictures>
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal document-modal"
      :style="onShowPreviewPictures ? { 'z-index': 1 } : {}"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <div class="mg-top-24">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-30 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="documentType">
                <v-select
                  class="not-item-in-v-select-error"
                  slot="field"
                  :options="documentTypeListTemp"
                  :disabled="editMode"
                  v-model="formData.documentType"
                  label="fieldItemName"
                  @input="removeItemSelect"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-60 md-small-size-100">
              <lims-field
                v-if="formData.documentType && formData.documentType.attachType === 'Link'"
                :model="formData"
                :schema="schema"
                field="titleLink"
              >
                <md-input slot="field" v-model="formData.titleLink" maxlength="100"></md-input>
              </lims-field>
              <lims-field v-else :model="formData" :schema="schema" field="titleFile">
                <md-input slot="field" :disabled="true" v-model="formData.titleFile" maxlength="100"></md-input>
              </lims-field>
            </div>
          </div>
          <div v-if="formData.documentType">
            <div v-if="formData.documentType.attachType === 'Link'" class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="link">
                  <md-input slot="field" v-model="formData.link"> </md-input>
                </lims-field>
                ({{ helperText }})
              </div>
            </div>

            <div
              v-if="formData.documentType.attachType !== 'Link' && dropzoneOptions.maxFiles"
              class="md-layout lims-form-row"
            >
              <div class="dropzone-wrapper">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  @vdropzone-file-added="onAddFile"
                  :class="
                    (editMode && fileAttachments.length > 0) || (isRequestForm && fileAttachments.length > 0)
                      ? 'disabled'
                      : ''
                  "
                >
                  <div class="dropzone-container">
                    <h4>
                      {{ $t('global/vueDropzone.txt') }}<br />
                      ({{ helperText }})
                    </h4>
                    <md-button class="md-button md-primary lims-form-button md-theme-default">
                      {{ $t('global/button/button.chooseFile') }}
                    </md-button>
                  </div>
                </vue-dropzone>
              </div>
              <!-- <div>files:{{ fileAttachments.length }}</div>
              <div>images{{ imageUrls.length }}</div> -->
              <div v-for="(item, index) in fileAttachments" :key="index" class="dropzone-file-add">
                <div class="file-item">
                  <div class="icon">
                    <span @click="onRemoveFile(item, index)"><md-icon>close</md-icon></span>
                  </div>
                  <img
                    v-if="isPicture && imageUrls[index]"
                    class="pictire"
                    :src="imageUrls[index]"
                    @click="onPreviewPictures(index)"
                  />
                  <img v-else class="img" :src="require(`@/assets/images/pdf-file.png`)" />
                  <div>{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                <md-textarea slot="field" v-model="formData.comment"></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions class="add-document-action">
          <md-button @click="close" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button
            @click="onAddDocument"
            :disabled="hasUploadFile ? invalid : invalid || !(fileAttachments.length > 0) || isProcessing"
            class="md-primary lims-form-button"
          >
            <template v-if="!editMode">{{ $t('components/lims/modals/ModalAddDocument/button/addDocument') }}</template>
            <template v-else>{{ $t('components/lims/modals/ModalAddDocument/button/editDocument') }}</template>
          </md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import {
  DOCUMENT_TYPE_NAME,
  FORM_MODES,
  DOCUMENT_MIME_TYPES,
  DOCUMENT_ATTACH_TYPES,
  DOCUMENT_MAX_FILES,
} from '@/core/constants';
import { caseFormService } from '@/services';
import modalMixins from './modal.mixins';
import ModalPreviewPictures from '@/components/Lims/modals/ModalPreviewPictures';

export default {
  name: 'ModalAddDocument',
  components: { ModalPreviewPictures },
  mixins: [modalMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    documentTypeList: {
      type: Array,
      require: false,
    },
    caseDocumentsList: {
      type: Array,
    },
    isVirtualSlideAddingAllowed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    schema() {
      return {
        entity: 'modals/ModalAddDocument',
        fields: {
          documentType: 'required',
          titleLink: 'required',
          titleFile: '',
          comment: '',
          link: this.linkValidation,
          fileAdd: '',
        },
      };
    },
    isPicture() {
      const documentType = this.formData.documentType ? this.formData.documentType.fieldItemName ?? '' : '';
      return documentType === DOCUMENT_TYPE_NAME.PICTURES;
    },
  },

  data() {
    return {
      formData: {},
      editMode: false,
      documentTypeListTemp: [],
      linkValidation: 'required',

      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        uploadMultiple: false,
        previewsContainer: false,
        init: function () {
          this.on('error', function (file) {
            if (!file.accepted) this.removeFile(file);
          });
        },
      },
      fileAttachments: [],
      imageUrls: [],
      hasUploadFile: true,
      isRequestForm: false,
      onShowPreviewPictures: false,
      isProcessing: false,
      helperText: '',
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.documentType) {
          if (val.documentType.attachType !== 'Link') {
            this.hasUploadFile = false;
          } else {
            this.hasUploadFile = true;
          }
        } else {
          this.hasUploadFile = true;
        }
      },
    },
    'formData.documentType': {
      deep: true,
      handler: function (val) {
        if (val) {
          const documentType = val.fieldItemName ?? '';
          const attachType = val.attachType;
          let acceptedFiles = '';
          let linkValidation = '';
          let helperText = '';
          const isRequestForm = documentType == DOCUMENT_TYPE_NAME.REQUEST_FORM;

          switch (attachType) {
            case DOCUMENT_ATTACH_TYPES.DOCUMENT:
              acceptedFiles = DOCUMENT_MIME_TYPES.PDF;
              helperText = this.$t(
                `components/lims/modals/ModalAddDocument/warning/attachType/helperText/${attachType}`,
                {
                  extensions: 'PDF',
                },
              );
              break;
            case DOCUMENT_ATTACH_TYPES.PICTURE:
              acceptedFiles = DOCUMENT_MIME_TYPES.PICTURES;
              helperText = this.$t(
                `components/lims/modals/ModalAddDocument/warning/attachType/helperText/${attachType}`,
                {
                  extensions: DOCUMENT_MIME_TYPES.PICTURES,
                },
              );
              break;
            case DOCUMENT_ATTACH_TYPES.LINK:
              linkValidation = 'required|securedLink';
              helperText = this.$t(
                `components/lims/modals/ModalAddDocument/warning/attachType/helperText/${attachType}`,
                {
                  schemes: 'https',
                },
              );
              break;
            default:
              helperText = `${attachType} is unsupported now!!!`;
          }
          this.helperText = helperText;
          this.isRequestForm = isRequestForm;
          this.linkValidation = linkValidation;
          // reset max files
          this.dropzoneOptions.maxFiles = 0;

          this.$nextTick(() => {
            this.dropzoneOptions.maxFiles = isRequestForm ? 1 : DOCUMENT_MAX_FILES;
            this.dropzoneOptions.acceptedFiles = acceptedFiles;
          });
        }
      },
    },
  },
  methods: {
    open(val) {
      this.isVisible = true;
      if (val) {
        this.formData = {
          ...val,
          documentType: this.documentTypeList.find((x) => x.fieldItemId == val.documentTypeId),
          titleLink: val.title,
          titleFile: val.title,
        };
        let dataURL = val.dataURL;
        this.fileAttachments.push({ name: val.fileName, dataURL });
        if (dataURL) {
          this.imageUrls.push(dataURL);
        }

        this.editMode = true;
      } else {
        this.editMode = false;
      }

      this.documentTypeListTemp = this.documentTypeList;
      if (
        this.caseDocumentsList &&
        this.caseDocumentsList.length > 0 &&
        this.caseDocumentsList.some((item) => item.documentTypeName == DOCUMENT_TYPE_NAME.REQUEST_FORM)
      ) {
        this.documentTypeListTemp = this.documentTypeList.filter(
          (x) => x.fieldItemName !== DOCUMENT_TYPE_NAME.REQUEST_FORM,
        );
      }

      if (!this.isVirtualSlideAddingAllowed && this.formMode !== FORM_MODES.ADD) {
        this.documentTypeListTemp = this.documentTypeListTemp.filter(
          (x) => x.fieldItemName !== DOCUMENT_TYPE_NAME.VIRTUAL_SLIDE,
        );
      }
    },
    close() {
      this.formData = {
        documentType: '',
        documentTypeId: '',
        documentTypeName: '',
        title: '',
        comment: '',
        link: '',
        fileAdd: null,
      };
      this.fileAttachments = [];
      this.imageUrls = [];
      this.isVisible = false;
    },
    removeItemSelect(val) {
      if (!val) {
        this.documentTypeListTemp = this.documentTypeList;
      }
      this.fileAttachments = [];
    },
    onAddDocument() {
      this.$emit('mode', this.editMode);
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            if (this.formData.fileAdd && (this.formData.fileAdd !== undefined || this.formData.fileAdd.length > 0)) {
              let formData = new FormData();
              this.formData.fileAdd.forEach((item) => {
                formData.append('files', item);
              });
              const res = await caseFormService.uploadAttachfiles(formData);
              this.isProcessing = false;
              if (res.err) {
                return this.$alertError(res.err + ' error');
              } else {
                // eslint-disable-next-line no-unused-vars
                const { fileAdd, ...documentData } = this.formData;
                const dataFile = res.data;
                dataFile.forEach((item, index) => {
                  this.$emit('documentData', {
                    ...documentData,
                    documentTypeId: this.formData.documentType.fieldItemId,
                    documentTypeName: this.formData.documentType.fieldItemName,
                    attachFile: item,
                    fileName: item.fileName,
                    format: item.fileName.split('.').pop(),
                    title: item.fileName.split('.').slice(0, -1).join('.'),
                    report: item.fileName.split('.').slice(0, -1).join('.'),
                    inReport: this.formData.documentType.attachType == 'Picture' ? true : false,
                    tempId: documentData.tempId ?? `${Date.now()}${index}`,
                  });
                });
              }
            } else {
              this.$emit('documentData', {
                ...this.formData,
                documentTypeId: this.formData.documentType.fieldItemId,
                documentTypeName: this.formData.documentType.fieldItemName,
                title: this.formData.titleLink,
                inReport: this.formData.documentType.attachType == 'Picture' ? true : false,
              });
              this.isProcessing = false;
            }
            this.close();
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onAddFile(file) {
      const ele = this.$refs.myVueDropzone;
      // ['getAcceptedFiles', 'getActiveFiles', 'getAddedFiles', 'getFilesWithStatus', 'getQueuedFiles'].map((method) => {
      //   console.log(method, ele[method]());
      // });
      const fileReader = new FileReader();
      const addedFiles = ele.getAddedFiles();
      fileReader.addEventListener(
        'load',
        () => {
          const acceptedFiles = addedFiles.filter((f) => f.accepted);
          this.fileAttachments.push(...acceptedFiles);
          this.formData.fileAdd = this.fileAttachments;
          this.imageUrls.push(fileReader.result);
        },
        false,
      );
      // fileReader.onloadstart = () => {
      //   this.imageUrls.push(URL.createObjectURL(file));
      // };

      fileReader.readAsDataURL(file);
    },
    onRemoveFile(item, index) {
      // const idx = this.fileAttachments.findIndex((fileAttachment) => fileAttachment.name === item.name);
      this.fileAttachments.splice(index, 1);
      this.imageUrls.splice(index, 1);
      this.formData.fileAdd = this.fileAttachments;
      this.$refs.myVueDropzone.removeFile(item);
      this.pdfReviewLink = '';
    },
    onPreviewPictures(index) {
      this.onShowPreviewPictures = true;
      const imageUrls = [];
      this.fileAttachments.forEach((f, index) => {
        imageUrls.push({ src: this.imageUrls[index], title: f.name });
      });
      this.$refs.previewPicturesModal.setPictures(imageUrls, index + 1);
      this.$refs.previewPicturesModal.open();
    },
    onHidePreviewPictures() {
      this.onShowPreviewPictures = false;
    },
  },
};
</script>

<style lang="scss">
.lims-modal {
  &.document-modal {
    .md-dialog-container {
      width: 60% !important;
    }
    .dropzone-file-add {
      width: auto;
      margin: 15px 5px 0 5px;
      display: inline-flex;
      .file-item {
        width: 120px;
        font-size: 12px;
      }
    }
  }
}
.hide-modal {
  z-index: 1 !important;
}
</style>
