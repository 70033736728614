<template>
  <div class="edit-case-page">
    <div v-if="ready && caseFormResource && isAllowAccess">
      <modal-confirm-generic
        ref="unSaveChangesModal"
        :description="$t('components/lims/modals/ModalFormCancel.text')"
        @onConfirm="onUnSaveChanges"
      />
      <pre-lab-status-and-admin-view
        v-if="caseStatus === CASE_STATUS.PRE_LAB"
        :caseFormResource="caseFormResource"
        :formMode="formMode"
      ></pre-lab-status-and-admin-view>
      <lab-status-lab-tech-and-admin-view
        v-if="caseStatus === CASE_STATUS.LAB || caseStatus === CASE_STATUS.EXT_CANCELLED"
        :caseFormResource="caseFormResource"
        :formMode="formMode"
      ></lab-status-lab-tech-and-admin-view>
      <path-status-path-view
        v-if="caseStatus === CASE_STATUS.PATH && !isSoPathView && isPathView"
        :caseFormResource="caseFormResource"
        :formMode="formMode"
      >
      </path-status-path-view>
      <provisional-reported-status-path-view
        v-if="caseStatus === CASE_STATUS.PROVISIONALLY_REPORTED && !isSoPathView && isPathView"
        :caseFormResource="caseFormResource"
        :formMode="formMode"
      ></provisional-reported-status-path-view>
      <div v-if="isAdminView && !isSoPathView">
        <path-and-provisional-reported-status-admin-view
          v-if="caseStatus === CASE_STATUS.PATH || caseStatus === CASE_STATUS.PROVISIONALLY_REPORTED"
          :case-status="caseStatus"
          :caseFormResource="caseFormResource"
          :formMode="formMode"
        ></path-and-provisional-reported-status-admin-view>
      </div>
      <second-opinion-path-view
        v-if="isSoPathView"
        :caseFormResource="caseFormResource"
        :formMode="formMode"
      ></second-opinion-path-view>
      <reported-status-lab-tech-view
        v-if="caseStatus === CASE_STATUS.REPORTED && isLabTechView"
        :caseFormResource="caseFormResource"
      ></reported-status-lab-tech-view>
      <reported-status-path-view
        v-if="caseStatus === CASE_STATUS.REPORTED && !isSoPathView && isPathView"
        :caseFormResource="caseFormResource"
        :form-mode="formMode"
      ></reported-status-path-view>
      <reported-status-admin-view
        v-if="caseStatus === CASE_STATUS.REPORTED && isAdminView"
        :caseFormResource="caseFormResource"
        :form-mode="formMode"
      ></reported-status-admin-view>
    </div>
    <div v-else>
      <error-forbidden v-if="ready"></error-forbidden>
    </div>
  </div>
</template>

<script>
import { FormMixins, ResetHeadingTitleMixins, UnSaveChangesMixins } from '@/core/mixins';
import { caseFormService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import PreLabStatusAndAdminView from '@/pages/Case/CaseManagement/Edit/PreLabStatusAdminView';
import LabStatusLabTechAndAdminView from '@/pages/Case/CaseManagement/Edit/LabStatusLabTechAndAdminView';
import PathStatusPathView from '@/pages/Case/CaseManagement/Edit/PathStatusPathView';
import ProvisionalReportedStatusPathView from '@/pages/Case/CaseManagement/Edit/ProvisionalReportedStatusPathView';
import SecondOpinionPathView from '@/pages/Case/CaseManagement/Edit/SecondOpinionPathView';
import { CASE_STATUS, DROPDOWN_SHORT_NAME, APP_EVENTS, newAppEvent } from '@/core/constants';
import PathAndProvisionalReportedStatusAdminView from '@/pages/Case/CaseManagement/Edit/PathAndProvisionalReportedStatusAdminView';
import ReportedStatusLabTechView from '@/pages/Case/CaseManagement/Edit/ReportedStatusLabTechView';
import ReportedStatusPathView from '@/pages/Case/CaseManagement/Edit/ReportedStatusPathView';
import ReportedStatusAdminView from '@/pages/Case/CaseManagement/Edit/ReportedStatusAdminView';
import ErrorForbidden from '@/pages/Error/ErrorForbidden.vue';
import entityService from '@/services/entity.service';

export default {
  mixins: [UnSaveChangesMixins, FormMixins, ResetHeadingTitleMixins],
  components: {
    PreLabStatusAndAdminView,
    ReportedStatusAdminView,
    ReportedStatusPathView,
    ReportedStatusLabTechView,
    PathAndProvisionalReportedStatusAdminView,
    LabStatusLabTechAndAdminView,
    PathStatusPathView,
    ProvisionalReportedStatusPathView,
    SecondOpinionPathView,
    ErrorForbidden,
  },
  created() {
    this.generateAnonymizedToken();
    this.getDoubleReportResponseId();
    this.caseFormResolver(this.caseId);
    sessionStorage.removeItem('isShowDocumentViewer');
  },
  props: {
    caseId: {
      require: false,
      default: null,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      caseFormResource: null,
      ready: false,
      tabId: null,
      errorMessage: null,
      doubleReportResponseId: null,
    };
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CASE_RELOAD, APP_EVENTS.EVT_ON_RELOAD_ENTITY_SLIDE]),
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Administrator;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    isLabTechView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().LabTechnician;
    },
    isClinicView() {
      const userType = this.$store.getters['auth/userType'];
      const { Clinician, ClinicAssociate, ClinicianAssociate } = this.USER_TYPES();
      return [Clinician, ClinicAssociate, ClinicianAssociate].includes(userType);
    },
    isSoPathView() {
      return this.caseFormResource.isCaseSoResponder;
    },
    caseStatus() {
      return this.caseFormResource.status;
    },
    CASE_STATUS() {
      return CASE_STATUS;
    },
    isAllowAccess() {
      if (!this.caseFormResource || this.errorMessage) {
        return false;
      }
      const userType = this.$store.getters['auth/userType'];
      const { Clinician, ClinicAssociate, ClinicianAssociate, Pathologist, Administrator, LabTechnician } =
        this.USER_TYPES();
      // clinic users can not access this page
      if ([ClinicAssociate, Clinician, ClinicianAssociate].includes(userType)) {
        return false;
      }
      // admin all status
      if (userType === Administrator) {
        return true;
      }
      // labtech: lab, reported
      if (
        userType === LabTechnician &&
        [CASE_STATUS.LAB, CASE_STATUS.REPORTED].includes(this.caseFormResource.status)
      ) {
        return true;
      }
      // pathologist: path,prov.reported, reported
      if (
        userType === Pathologist &&
        [CASE_STATUS.PATH, CASE_STATUS.PROVISIONALLY_REPORTED, CASE_STATUS.REPORTED].includes(
          this.caseFormResource.status,
        )
      ) {
        return true;
      }
      // default deny all access
      return false;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_CASE_RELOAD]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          // reload data
          await this.caseFormResolver(this.caseId);
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CASE_RELOAD));
        }
      },
    },
    [APP_EVENTS.EVT_ON_RELOAD_ENTITY_SLIDE]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          await this.loadEntitySlide(this.caseFormResource.laboratoryId, this.caseFormResource.caseId);
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_RELOAD_ENTITY_SLIDE));
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN, {
              val,
            }),
          );
        }
      },
    },
  },
  methods: {
    ...mapActions('app/data', ['updateDataset', 'setDataset']),
    ...mapActions('app/event', ['removeEvent', 'addEvent', 'clearEvents']),
    ...mapActions('caseData', ['setCaseData']),
    ...mapActions('caseForm', ['setRowVersionByCaseId', 'setBlockNamingRuleSetting']),
    async loadEntitySlide(laboratoryId, caseId) {
      const dropdownOptions = await caseFormService.loadDropDownDataSource();
      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(
        laboratoryId,
        [
          DROPDOWN_SHORT_NAME.H_AND_E,
          DROPDOWN_SHORT_NAME.IMMUNOS,
          DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
          DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
          'Snomed',
        ],
        caseId,
      );
      this.updateDataset({
        ...dropdownOptions,
        ...dropdownStainOptions,
      });
    },
    async caseFormResolver(caseId) {
      // clean old data before access case
      this.clearEvents();
      if (caseId) {
        const { data, error } = await caseFormService.findOne(caseId, true, this.tabId, this.doubleReportResponseId);

        if (data) {
          await this.loadEntitySlide(data.laboratoryId, caseId);
          const blockNamingRuleSetting = await entityService.getBlockNamingRuleSetting(data.laboratoryId);
          this.setBlockNamingRuleSetting({ blockNamingRuleSetting });
          this.setCaseData(data);
          this.setRowVersionByCaseId({
            caseId: data.caseId,
            rowVersion: data.rowVersion,
          });
          this.caseFormResource = data;
          this.ready = true;

          if (
            data.status === CASE_STATUS.PATH &&
            this.isPathView &&
            !this.isSoPathView &&
            data.hasNumberOfSpecimenChanged
          ) {
            this.$alertSuccess(this.$t('pages/cases/form/alert/hasNumberOfSpecimenChanged'));
            await caseFormService.updateNumberOfSpecimenChangedState(this.caseId);
          }

          if (data.isShowVirtualSlideRestoredMsg) {
            this.$alertSuccess(this.$t('pages/cases/form/alert/virtualSlideRestoredSuccess'));
            await caseFormService.removeRequestDigitalSlideFlag(this.caseId);
          }

          // EDIT CASE
          this.$nextTick(function () {
            setTimeout(() => {
              this.$resetChangeDetection();
            }, 1000);
          });
        }
        if (error) {
          this.errorMessage = error;
        }
        this.ready = true;
      }
    },

    generateAnonymizedToken() {
      const tabId = sessionStorage.getItem('tabId');
      if (tabId) {
        this.tabId = tabId;
      } else {
        const id = Math.random();
        sessionStorage.setItem('tabId', id);
        this.tabId = id;
      }
    },
    getDoubleReportResponseId() {
      const doubleReportResponseId = this.$route.query.doubleReportResponseId;
      if (doubleReportResponseId) {
        this.doubleReportResponseId = doubleReportResponseId;
      }
    },
    onBack() {
      this.$router.back();
    },
  },

  beforeRouteLeave(_to, _from, next) {
    // clear events
    this.clearEvents();
    // remove tabId
    sessionStorage.removeItem('tabId');
    next(true);
  },
};
</script>

<style lang="scss" scoped>
.edit-case-page {
  overflow-anchor: none;
}
</style>
