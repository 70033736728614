<template>
  <div id="specimenDetailsBlock">
    <collapse
      :wrapperClass="'case-collapse specimen-detail-collapse'"
      :collapse="collapseName"
      icon="keyboard_arrow_down"
    >
      <modal-concurrency-issue
        ref="concurrencyIssueModal"
        :name-of-block="'Patient Data'"
        @onContinue="$onConcurrencyIssueContinue"
      ></modal-concurrency-issue>
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/noOfSpicemen') }}</label>
            <div class="static-text">{{ formData.noOfSpicemen }}</div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="specimenDetails">
            </lims-field>
            <div v-if="formData.specimenDetails" class="static-text" v-lims-text-area="formData.specimenDetails"></div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="clinicalDetails">
            </lims-field>
            <div v-if="formData.clinicalDetails" class="static-text" v-lims-text-area="formData.clinicalDetails"></div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="countOfOriginalSlides">
            </lims-field>
            <div class="static-text" v-if="formData.caseSpecimens">{{ formData.countOfOriginalSlides }}</div>
          </div>
        </div>
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenNo') }}</md-table-head>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenType') }}</md-table-head>
            <md-table-head>{{
              $t('pages/case/CaseManagement/component/specimen/table/specimenDetailsOnPot')
            }}</md-table-head>
            <md-table-head>{{
              $t('pages/case/CaseManagement/component/specimen/table/macroDescriptionFromLab')
            }}</md-table-head>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/block') }}</md-table-head>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/slide') }}</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in formData.caseSpecimenBlocks" :key="index">
            <md-table-cell>{{ convertSpecimenName(item.specimenNumber - 1) }}</md-table-cell>
            <md-table-cell>{{ item.specimentTypeName }}</md-table-cell>
            <md-table-cell>
              <div v-lims-text-area="item.specimenDetails"></div>
            </md-table-cell>
            <md-table-cell>
              <div v-lims-text-area="item.macroDescription"></div>
            </md-table-cell>
            <md-table-cell>{{ convertBlockName(item.specimenIndex, item.blockIndex) }}</md-table-cell>
            <md-table-cell>{{ item.slide }}</md-table-cell>
          </md-table-row>
        </md-table>
        <p>{{ $t('pages/case/CaseManagement/component/specimen/table/tissueRemaining') }}</p>
        <md-table class="custom-tbl tissue-remaining-table" md-card>
          <md-table-row>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenNo') }}</md-table-head>
            <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenType') }}</md-table-head>
            <md-table-head>{{
              $t('pages/case/CaseManagement/component/specimen/table/tissueRemaining')
            }}</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in formData.caseSpecimens" :key="index">
            <md-table-cell>{{ convertSpecimenName(index) }}</md-table-cell>
            <md-table-cell>{{ item.specimentTypeName }}</md-table-cell>
            <md-table-cell class="cell-action">
              <md-checkbox
                :disabled="soPathView || !isAdminCanEdit || dataEdit.isDeleted || viewMode"
                v-model="formData.caseSpecimens[index].tissueRemaining"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="md-layout lims-form-row" v-if="isReportedStatus && isHaveAtLeastOneSpecimenHaveTissueRemaing">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <md-checkbox
              v-model="formData.tissueDiscarded"
              :disabled="soPathView || isPathView || dataEdit.isDeleted || viewMode"
              class="lims-checkbox mg-top-40"
            >
              {{ $t('pages/case/CaseManagement/component/specimen/table/tissueDiscarded') }}
            </md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="tissueDiscardedDate">
              <date-picker
                :lang="{
                  formatLocale: {
                    firstDayOfWeek: 1,
                  },
                  monthBeforeYear: false,
                }"
                slot="field"
                v-model="formData.tissueDiscardedDate"
                format="DD/MM/YYYY"
                :disabled="soPathView || isPathView || !formData.tissueDiscarded || dataEdit.isDeleted || viewMode"
              ></date-picker>
            </lims-field>
          </div>
        </div>
        <div v-if="isAdminCanEdit" class="md-layout lims-form-row">
          <div
            v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)"
            class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action"
          >
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </template>
    </collapse>
  </div>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { CASE_STATUS, FORM_MODES, CASE_FORM_BLOCK } from '@/core/constants';
import { caseFormService } from '@/services';
import { fromISOToCurrentTimezone, convertFromDateTimezoneToIsoString } from '@/core/helpers';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins, CaseSpecimenMixins],
  components: {
    ModalConcurrencyIssue,
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    soPathView: {
      type: Boolean,
      require: false,
    },
  },
  computed: {
    isAdminCanEdit() {
      return (
        this.userType === this.USER_TYPES().Administrator && (this.isPathStatus || this.isProvisionallyReportedStatus)
      );
    },
    isPathView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    isPathStatus() {
      return this.dataEdit.status === CASE_STATUS.PATH;
    },
    isProvisionallyReportedStatus() {
      return this.dataEdit.status === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    isReportedStatus() {
      return this.dataEdit.status === CASE_STATUS.REPORTED;
    },
    isHaveAtLeastOneSpecimenHaveTissueRemaing() {
      return this.dataEdit.caseSpecimens.find((caseSpecimen) => caseSpecimen.tissueRemaining === true);
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/SpecimenDetail/blockTitle';
      return [this.$translate(name)];
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        const specimenDetailData = this.isReportedStatus
          ? {
              tissueDiscarded: this.formData.tissueDiscarded,
              tissueDiscardedDate: convertFromDateTimezoneToIsoString(this.formData.tissueDiscardedDate),
            }
          : this.specimenDetailData();
        this.appendCaseData({
          caseData: specimenDetailData,
          blockId: CASE_FORM_BLOCK.BLOCK_SPECIMEN_DETAIL,
        });
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      formData: {},
      caseSpecimens: [],
      blockId: CASE_FORM_BLOCK.BLOCK_SPECIMEN_DETAIL,
      isProcessing: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.userType = this.$store.getters['auth/userType'];
      let caseSpecimenBlocks = [];
      let caseSpecimenItem = {};
      this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.dataEdit.laboratoryId);
      this.dataEdit.caseSpecimens.forEach((caseSpecimen, specimenIndex) => {
        if (caseSpecimen.caseSpecimenBlocks.length > 0) {
          caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock, blockIndex) => {
            let slide = [];
            caseSpecimenBlock.blockFieldItems.forEach((blockFieldItem) => {
              slide.push(blockFieldItem.fieldItemName);
            });

            caseSpecimenItem = {
              ...caseSpecimenBlock,
              blockIndex,
              specimenIndex,
              specimenNumber: specimenIndex + 1,
              specimentTypeName: caseSpecimen.specimentTypeName,
              specimenDetails: caseSpecimen.specimenDetails,
              macroDescription: caseSpecimen.macroDescription,
              slide: slide.join(', '),
            };
            caseSpecimenBlocks.push(caseSpecimenItem);
          });
        } else {
          caseSpecimenItem = {
            specimenNumber: specimenIndex + 1,
            specimentTypeName: caseSpecimen.specimentTypeName,
            specimenDetails: caseSpecimen.specimenDetails,
            macroDescription: caseSpecimen.macroDescription,
            slide: '',
          };
          caseSpecimenBlocks.push(caseSpecimenItem);
        }
      });
      let countOfOriginalSlides = 0;
      this.dataEdit.caseSpecimens.forEach((caseSpecimen) => {
        caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
          countOfOriginalSlides += caseSpecimenBlock.blockFieldItems.length;
        });
      });
      this.formData = {
        caseSpecimens: this.dataEdit.caseSpecimens,
        specimenDetails: this.dataEdit.specimenDetails,
        clinicalDetails: this.dataEdit.clinicalDetails,
        noOfSpicemen: this.dataEdit.caseSpecimens.length,
        countOfOriginalSlides: countOfOriginalSlides,
        caseSpecimenBlocks: caseSpecimenBlocks,
        tissueDiscarded: this.dataEdit.tissueDiscarded,
        tissueDiscardedDate:
          this.dataEdit.tissueDiscarded && this.dataEdit.tissueDiscardedDate
            ? fromISOToCurrentTimezone(this.dataEdit.tissueDiscardedDate)
            : null,
      };
      this.$resetBlockChanged();
    },
    async onSave(overWrite = false) {
      this.isProcessing = true;
      const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
      if (this.isReportedStatus && this.isHaveAtLeastOneSpecimenHaveTissueRemaing) {
        const res = await caseFormService.discardTissueCase(this.dataEdit.caseId, {
          discardTissue: this.formData.tissueDiscarded,
          date:
            this.formData.tissueDiscarded && this.formData.tissueDiscardedDate
              ? convertFromDateTimezoneToIsoString(this.formData.tissueDiscardedDate)
              : null,
          rowVersion: overWrite ? null : rowVersion,
        });
        this.isProcessing = false;
        this.$onAfterSaveHandler({
          res,
          dataEdit: this.dataEdit,
        });
      } else {
        const dataForm = this.specimenDetailData();
        const res = await caseFormService.updateSpecimenDetail(this.dataEdit.caseId, {
          ...dataForm,
          rowVersion: overWrite ? null : rowVersion,
        });
        this.isProcessing = false;
        this.$onAfterSaveHandler({
          res,
          dataEdit: this.dataEdit,
        });
      }
    },

    buildCaseSpecimenModel(caseSpecimens) {
      return caseSpecimens.map((caseSpecimen) => {
        return {
          specimenDetails: caseSpecimen.specimenDetails,
          macroDescription: caseSpecimen.macroDescription,
          tissueRemaining: caseSpecimen.tissueRemaining,
          specimenTypeId: caseSpecimen.specimenTypeId,
          caseSpecimentId: caseSpecimen.caseSpecimentId,
          caseSpecimenBlocks: caseSpecimen.caseSpecimenBlocks,
        };
      });
    },
    specimenDetailData() {
      const caseSpecimenUpdateModels = this.buildCaseSpecimenModel(this.dataEdit.caseSpecimens);

      return {
        specimenDetails: this.formData.specimenDetails,
        clinicalDetails: this.formData.clinicalDetails,
        caseSpecimenUpdateModels: caseSpecimenUpdateModels,
      };
    },
    convertBlockName(specimenIndex, blockIndex) {
      return this.$convertBlockName({ specimenIndex, blockIndex, blockNamingRuleSetting: this.blockNamingRuleSetting });
    },
    convertSpecimenName(index) {
      return generateSpecimenName(index, this.blockNamingRuleSetting);
    },
  },
};
</script>
<style lang="scss">
.tissue-remaining-table {
  margin-top: 0;
}
</style>
